import _get from 'lodash/get';
import { passwordsPatternCheck } from '../utils/passwordPatternUtil';

import {
  TOGGLE_RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_CONFIRMATION_CODE_SENT,
  SET_USERNAME_FIELD,
  CONVERT_USERNAME_FIELD_TO_LOWERCASE,
  SET_NEW_PASSWORD_VALUE,
  SET_CONFIRM_PASSWORD_VALUE,
  CHECK_PASSWORD_VALIDATION,
  TOGGLE_RESET_PASSWORDS_TYPE,
} from '../constants';

const initialState = {
  username: '',
  isLoading: false,
  message: null,
  error: false,
  errorMessage: '',
  confirmationCode: false,
  newPassword: '',
  confirmPassword: '',
  resetPassword: false,
  passwordMismatch: false,
  passwordNotLongEnough: true,
  passwordNoSpecChar: true,
  passwordNoUpperLowerCase: true,
  passwordNoNumbers: true,
  formValid: false,
  showPasswords: false,
};

const actionMappings = {
  [TOGGLE_RESET_PASSWORD_LOADING]: '_toggleLoading',
  [RESET_PASSWORD_FAIL]: '_setFailureState',
  [RESET_PASSWORD_CONFIRMATION_CODE_SENT]: '_confirmationSent',
  [RESET_PASSWORD_SUCCESS]: '_setSuccessState',
  [SET_USERNAME_FIELD]: '_setUsernameField',
  [CONVERT_USERNAME_FIELD_TO_LOWERCASE]: '_convertUsernameFieldToLowercase',
  [SET_NEW_PASSWORD_VALUE]: '_setNewPasswordValue',
  [SET_CONFIRM_PASSWORD_VALUE]: '_setConfirmPasswordValue',
  [CHECK_PASSWORD_VALIDATION]: '_checkPasswordValidation',
  [TOGGLE_RESET_PASSWORDS_TYPE]: '_toggleResetPasswordsType',
};

const reducer = {
  _toggleLoading(state) {
    return {
      ...state,
      isLoading: !state.isLoading,
    };
  },
  _setFailureState(state, { err: { message, passwordMismatch } }) {
    return {
      ...state,
      error: true,
      errorMessage: message,
      passwordMismatch,
    };
  },
  _setSuccessState(state) {
    return {
      ...state,
      resetPassword: true,
      error: false,
      errorMessage: '',
      passwordMismatch: false,
    };
  },
  _confirmationSent(state, { message }) {
    return {
      ...state,
      confirmationCode: true,
      error: false,
      errorMessage: '',
      passwordMismatch: false,
      message,
    };
  },
  _setUsernameField(state, { username }) {
    return {
      ...state,
      username,
    };
  },
  _convertUsernameFieldToLowercase(state) {
    const username = _get(state, 'username', '');
    const convertedUsername = username.toLowerCase();

    return {
      ...state,
      username: convertedUsername,
    };
  },
  _setNewPasswordValue(state, { value }) {
    return {
      ...state,
      newPassword: value,
    };
  },
  _setConfirmPasswordValue(state, { value }) {
    return {
      ...state,
      confirmPassword: value,
    };
  },
  _checkPasswordValidation(state) {
    const passwordMismatch = state.confirmPassword !== state.newPassword;
    const passwordNotLongEnough = !(
      state.confirmPassword.length > 7 && state.newPassword.length > 7
    );

    const passwordSpecCharacterPattern = /[-!$%^&*()_+|~=`'{}"/\\[\]:;<>?,.@#]/;
    const passwordLowerUpperCasePattern = /^(?=.*?[A-Z])(?=.*?[a-z])/;
    const passwordNumberPattern = /(?=.*?[0-9])/;

    const passwordNoSpecChar = !passwordsPatternCheck(
      passwordSpecCharacterPattern,
      state.confirmPassword,
      state.newPassword,
    );

    const passwordNoUpperLowerCase = !passwordsPatternCheck(
      passwordLowerUpperCasePattern,
      state.confirmPassword,
      state.newPassword,
    );

    const passwordNoNumbers = !passwordsPatternCheck(
      passwordNumberPattern,
      state.confirmPassword,
      state.newPassword,
    );

    const formValid =
      !passwordNoSpecChar &&
      !passwordNoUpperLowerCase &&
      !passwordNoNumbers &&
      !passwordMismatch &&
      !passwordNotLongEnough;

    if (formValid) {
      return {
        ...state,
        passwordNoSpecChar,
        passwordNoUpperLowerCase,
        passwordNoNumbers,
        passwordMismatch,
        passwordNotLongEnough,
        formValid: true,
      };
    }
    return {
      ...state,
      passwordNoSpecChar,
      passwordNoUpperLowerCase,
      passwordNoNumbers,
      passwordMismatch,
      passwordNotLongEnough,
      formValid: false,
    };
  },
  _toggleResetPasswordsType(state) {
    return {
      ...state,
      showPasswords: !state.showPasswords,
    };
  },
};

const userReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default userReducer;
