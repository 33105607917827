import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import './ConfirmationModal.css';
import { Warning } from '@material-ui/icons';
import Button from '../Button/Button';

const ConfirmationModal = ({ open, title, content, onClose, onConfirm }) => {
  return (
    <Dialog open={open} maxWidth="md">
      <div className="alert-dialog">
        <Warning className="modal-icon" />
        <div className="title">{title}</div>
        <div className="content">{content}</div>
        <div className="actions">
          <Button onClick={onClose} buttonText="No" size="medium" outlineButton />
          <Button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            buttonText="Yes"
            size="medium"
          />
        </div>
      </div>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {
  open: false,
};

export default ConfirmationModal;
