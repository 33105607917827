const notificationFrequencies = {
  DAILY: {
    id: 1,
    displayName: 'Daily',
    queryFieldName: 'daily',
    key: 'DAILY',
    description: 'Notification every day of the week',
  },
  WEEKLY: {
    id: 2,
    displayName: 'Weekly',
    queryFieldName: 'weekly',
    key: 'WEEKLY',
    description: 'Notification on a specific day of the week',
  },
  SEMI_MONTHLY: {
    id: 3,
    displayName: 'Semi-monthly',
    queryFieldName: 'semi-monthly',
    key: 'SEMI_MONTHLY',
    description: 'Notifications twice a month, on the 15th and last day of the month',
  },
  MONTHLY: {
    id: 4,
    displayName: 'Monthly',
    queryFieldName: 'monthly',
    key: 'MONTHLY',
    description: 'Notification on the last day of the month',
  },
};

export const findNotificationFrequencyByQueryField = queryFieldName => Object.values(notificationFrequencies).find(it => it.queryFieldName === queryFieldName);

export default notificationFrequencies;
