/* eslint-disable */

import {
  TOGGLE_DASHBOARD_FILTERS,
  SELECT_ABS_NO_FAULTS_FILTER,
  SELECT_ABS_FAULTS_FILTER,
  SELECT_ABS_ALERTS_FILTER,
  SELECT_TIS_NO_FAULTS_FILTER,
  SELECT_TIS_FAULTS_FILTER,
  SELECT_TIS_ALERTS_FILTER,
  SELECT_BATTERY_NO_FAULTS_FILTER,
  SELECT_BATTERY_FAULTS_FILTER,
  SELECT_BATTERY_ALERTS_FILTER,
  SELECT_LIGHT_NO_FAULTS_FILTER,
  SELECT_LIGHT_FAULTS_FILTER,
  SELECT_LIGHT_ALERTS_FILTER,
  SELECT_TETHER_STATUS,
  SELECT_LOAD_STATUS,
  SELECT_PRODUCT_TYPE_STATUSES,
  SELECT_ITEM_TYPE_STATUSES,
  SELECT_CONNECTIVITY_STATUS,
  CLEAR_ALL_FILTERS,
  SET_FAULT_TOTALS,
  SET_SELECTED_FILTERS,
  SET_SELECTED_CUSTOMER,
  SET_SELECTED_GROUPS,
  SET_DASHBOARD_FILTERS_STATUS,
  GET_FILTER_COOKIE,
  COOKIE_EXPIRATION_DAYS,
} from '../constants';
import Cookies from 'js-cookie';

import { filterTrucks, getFaultTotals } from './tracking';
import { getTrailersBasedOnCustomerAndGroups } from '../utils/mapFilteringUtils';

export const setFleetHealthTrailersCount = (selectedCustomer, selectedVins, allTrucks, dispatch) => {
  let activeTrucks = allTrucks;
  activeTrucks = getTrailersBasedOnCustomerAndGroups({ filteredTrailers: allTrucks, selectedCustomer, selectedVins });
  const faultTotals = getFaultTotals(activeTrucks);
  dispatch(setFaultTotals(faultTotals));
};

export const toggleDashboardFilters = () => ({ type: TOGGLE_DASHBOARD_FILTERS });

export const setDashboardFiltersStatus = status => ({ type: SET_DASHBOARD_FILTERS_STATUS, status });

export const selectTetherStatus = (selectedTetherStatus, selectedFiltersTotalCount) => ({
  type: SELECT_TETHER_STATUS,
  selectedTetherStatus,
  selectedFiltersTotalCount,
});

export const selectLoadStatus = (selectedLoadStatus, selectedFiltersTotalCount) => ({
  type: SELECT_LOAD_STATUS,
  selectedLoadStatus,
  selectedFiltersTotalCount,
});

export const selectProductTypeStatuses = (selectedProductTypes, selectedFiltersTotalCount) => ({
  type: SELECT_PRODUCT_TYPE_STATUSES,
  selectedProductTypes,
  selectedFiltersTotalCount,
});

export const selectItemTypeStatuses = (itemTypes, selectedFiltersTotalCount) => ({
  type: SELECT_ITEM_TYPE_STATUSES,
  itemTypes,
  selectedFiltersTotalCount,
});

export const selectConnectivityStatus = (connectivityStatus, selectedFiltersTotalCount) => ({
  type: SELECT_CONNECTIVITY_STATUS,
  connectivityStatus,
  selectedFiltersTotalCount,
});

export const selectAbsNoFaultsFilter = selectedFiltersTotalCount => ({
  type: SELECT_ABS_NO_FAULTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectAbsFaultsFilter = selectedFiltersTotalCount => ({
  type: SELECT_ABS_FAULTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectAbsAlertsFilter = selectedFiltersTotalCount => ({
  type: SELECT_ABS_ALERTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectTisNoFaultsFilter = selectedFiltersTotalCount => ({
  type: SELECT_TIS_NO_FAULTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectTisFaultsFilter = selectedFiltersTotalCount => ({
  type: SELECT_TIS_FAULTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectTisAlertsFilter = selectedFiltersTotalCount => ({
  type: SELECT_TIS_ALERTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectBatteryNoFaultsFilter = selectedFiltersTotalCount => ({
  type: SELECT_BATTERY_NO_FAULTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectBatteryFaultsFilter = selectedFiltersTotalCount => ({
  type: SELECT_BATTERY_FAULTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectBatteryAlertsFilter = selectedFiltersTotalCount => ({
  type: SELECT_BATTERY_ALERTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectLightNoFaultsFilter = selectedFiltersTotalCount => ({
  type: SELECT_LIGHT_NO_FAULTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectLightFaultsFilter = selectedFiltersTotalCount => ({
  type: SELECT_LIGHT_FAULTS_FILTER,
  selectedFiltersTotalCount,
});

export const selectLightAlertsFilter = selectedFiltersTotalCount => ({
  type: SELECT_LIGHT_ALERTS_FILTER,
  selectedFiltersTotalCount,
});

export const clearAllFilters = (selectedCustomer, selectedVins) => async (dispatch, getState) => {
  const allTrucks = getState()?.tracking?.allTrucks;
  window.localStorage.removeItem(`trackingFilters-${window.localStorage.getItem('userName')}`)
  dispatch(filterTrucks([], selectedCustomer, selectedVins));
  dispatch({
    type: CLEAR_ALL_FILTERS,
  });
  setFleetHealthTrailersCount(selectedCustomer, selectedVins, allTrucks, dispatch);
};

export const setFaultTotals = faultTotals => ({
  type: SET_FAULT_TOTALS,
  faultTotals,
});

export const setSelectedFilters = (initialSelectedFilters, newFilter, isFilterSelected, selectedCustomer, selectedVins) => async (dispatch, getState) => {
  let newSelectedFilters = [];
  if (isFilterSelected) {
    newSelectedFilters = initialSelectedFilters.concat(newFilter);
  } else {
    newSelectedFilters = initialSelectedFilters.filter(filterItem => filterItem !== newFilter);
  }

  dispatch(filterTrucks(newSelectedFilters, selectedCustomer, selectedVins));

  dispatch({
    type: SET_SELECTED_FILTERS,
    newSelectedFilters,
  });
  const allTrucks = getState()?.tracking?.data;
  const cookieState = {
    selectedFilters: getState().dashboardFilters.selectedFilters,
    selectedTetherStatus: getState().dashboardFilters.selectedTetherStatus,
    selectedLoadStatus: getState().dashboardFilters.selectedLoadStatus,
    selectedProductTypes: getState().dashboardFilters.selectedProductTypes,
    selectedItemTypes: getState().dashboardFilters.selectedItemTypes,
    selectedConnectivityStatus: getState().dashboardFilters.selectedConnectivityStatus,
    selectedCustomer: getState().dashboardFilters.selectedCustomer,
    selectedGroups: getState().dashboardFilters.selectedGroups,
    selectedVins: getState().dashboardFilters.selectedVins,
  };
  window.localStorage.setItem(`trackingFilters-${window.localStorage.getItem('userName')}`, JSON.stringify(cookieState));
  setFleetHealthTrailersCount(selectedCustomer, selectedVins, allTrucks, dispatch);
};

export const setSelectedCustomer = selectedCustomer => async dispatch => {
  dispatch({
    type: SET_SELECTED_CUSTOMER,
    selectedCustomer,
  });
};

export const setSelectedGroups = (selectedGroups, selectedVins) => async dispatch => {
  dispatch({
    type: SET_SELECTED_GROUPS,
    selectedGroups,
    selectedVins,
  });
};

export const getFilterCookies = filterCookie => async (dispatch, getState) => {
  const selectedCustomer = filterCookie?.selectedCustomer;
  const selectedVins = filterCookie?.selectedVins;
  dispatch(filterTrucks(filterCookie?.selectedFilters, selectedCustomer, selectedVins));
  const allTrucks = getState()?.tracking?.data;
  setFleetHealthTrailersCount(selectedCustomer, selectedVins, allTrucks, dispatch);
  dispatch({
    type: GET_FILTER_COOKIE,
    filterCookie,
  });
};
