import { DEVICE_MAIN_APP_ERROR, DEVICE_MAIN_APP_LOADING, DEVICE_MAIN_APP_SUCCESS } from '../constants';

const initialState = {
  deviceScripts: null,
  mainAppLoading: false,
  deviceScriptsError: false,
};

const actionMappings = {
  [DEVICE_MAIN_APP_LOADING]: '_deviceMainAppLoading',
  [DEVICE_MAIN_APP_ERROR]: '_deviceMainAppError',
  [DEVICE_MAIN_APP_SUCCESS]: '_deviceMainAppSuccess',
};

const reducer = {
  _deviceMainAppLoading(state, { payload: { mainAppLoading } }) {
    return {
      ...state,
      mainAppLoading,
    };
  },
  _deviceMainAppError(state) {
    return {
      ...state,
      error: true,
    };
  },
  _deviceMainAppSuccess(state, { payload: { mainApp } }) {
    return {
      ...state,
      mainApp: mainApp,
      error: false,
    };
  },
};

const deviceMainAppManagement = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default deviceMainAppManagement;
