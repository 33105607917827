import { DEVICE_SCRIPTS_ERROR, DEVICE_SCRIPTS_LOADING, DEVICE_SCRIPTS_SUCCESS } from '../constants';

const initialState = {
  deviceScripts: null,
  deviceScriptsLoading: false,
  deviceScriptsError: false,
};

const actionMappings = {
  [DEVICE_SCRIPTS_LOADING]: '_deviceScriptsLoading',
  [DEVICE_SCRIPTS_ERROR]: '_deviceScriptsError',
  [DEVICE_SCRIPTS_SUCCESS]: '_deviceScriptsSuccess',
};

const reducer = {
  _deviceScriptsLoading(state, { payload: { deviceScriptsLoading } }) {
    return {
      ...state,
      deviceScriptsLoading,
    };
  },
  _deviceScriptsError(state) {
    return {
      ...state,
      error: true,
    };
  },
  _deviceScriptsSuccess(state, { payload: { deviceScripts } }) {
    return {
      ...state,
      deviceScripts: deviceScripts,
      error: false,
    };
  },
};

const deviceScriptsManagement = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default deviceScriptsManagement;
