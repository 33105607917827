import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { classes } from '../styles/muiComponentsStyle';
import AddNewUser from 'mdi-react/PlusCircleOutlineIcon';
import Refresh from 'mdi-react/RefreshIcon';
import ResendEmail from 'mdi-react/RepeatIcon';
import { englishListFormatter } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { resendEmail } from '../../../actions/userManagement';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import { UserResendEmailTypes } from '../../../constants/enums/userResendEmailTypes';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { isAdminOrCustomerAdmin } from '../../../security/authorization';
import { fleetpulseAuthFetch } from '../../../utils/fetch';
import { resendEmailConfirmationMsg, resendEmailDescription } from '../../../utils/userManagementUtils';
import Button from '../../Button/Button';
import Input from '../../Input/Input';

function UserManagementHeader({
  isButtonDisabled,
  features = {},
  resendEmailDialogOpen,
  openCreateAccountModal,
  searchAllUsers,
  onResetPasswordClick,
  onResendVerificationClick,
  setResendEmailDialogClose,
  groupButtonsDisabled,
  selectedUsers,
}) {
  const dispatch = useDispatch();

  const [userEmailAddresses, setUserEmailAddresses] = useState({ toEmailAddresses: '', emailAddresses: '', userIds: '' });

  const userManagementProps = useSelector(state => state.userManagement);
  const formatUserEmailAddresses = () => {
    const emailAddresses = selectedUsers?.map(it => it.email);
    const userIds = selectedUsers?.map(it => it.id);
    const toEmailAddresses = englishListFormatter.format(emailAddresses);
    setUserEmailAddresses({
      toEmailAddresses,
      emailAddresses,
      userIds,
    });
  };

  const handleResendEmail = useCallback(
    (fetch, emailType, emails, userIds, isUserAdminOrCustomerAdmin) => {
      dispatch(resendEmail(fetch, emailType, emails, userIds, isUserAdminOrCustomerAdmin));
    },
    [dispatch],
  );

  const onClickSend = () => {
    let isUserAdminOrCustomerAdmin = isAdminOrCustomerAdmin();
    let userIds = [];
    if (userManagementProps.resendEmailType === UserResendEmailTypes.RESEND_VERIFICATION.key) {
      isUserAdminOrCustomerAdmin = false;
      userIds = userEmailAddresses.userIds;
    }
    handleResendEmail(fleetpulseAuthFetch, userManagementProps.resendEmailType, userEmailAddresses.emailAddresses, userIds, isUserAdminOrCustomerAdmin);
  };
  const emailType = UserResendEmailTypes[userManagementProps.resendEmailType];
  const title = emailType ? emailType.title : '';
  const successModalTitle =
    emailType?.key === UserResendEmailTypes.RESET_PASSWORD.key ? UserResendEmailTypes.RESET_PASSWORD.title : UserResendEmailTypes.RESEND_VERIFICATION.title;
  return (
    <Grid style={classes.gridContainer}>
      <Grid item xs={4} style={classes.leftHeaderGrid}>
        <AlertDialog
          open={userManagementProps.resendEmailSuccess}
          onConfirm={setResendEmailDialogClose}
          title={successModalTitle}
          content={resendEmailConfirmationMsg(emailType, userManagementProps?.resendEmailAdressess)}
        />
        <ConfirmationModal
          open={resendEmailDialogOpen}
          title={title}
          content={resendEmailDescription(emailType, userEmailAddresses)}
          onConfirm={onClickSend}
          onClose={setResendEmailDialogClose}
        />
        <Button onClick={openCreateAccountModal} leftIcon={<AddNewUser color="white" size="17" />} buttonText="Create New User" />
        <Input placeholder="Search Username" onChange={e => searchAllUsers(e.target.value)} />
      </Grid>
      <Grid item xs={8} style={classes.rightHeaderGrid}>
        <Button
          buttonText="Reset Password"
          leftIcon={<Refresh size="17" />}
          onClick={() => {
            formatUserEmailAddresses();
            onResetPasswordClick();
          }}
          disabled={isButtonDisabled}
          outlineButton
        />
        <Button
          buttonText="Resend Verification"
          leftIcon={<ResendEmail size="17" />}
          disabled={isButtonDisabled}
          onClick={() => {
            formatUserEmailAddresses();
            onResendVerificationClick();
          }}
          outlineButton
        />
      </Grid>
    </Grid>
  );
}

UserManagementHeader.propTypes = {
  isButtonDisabled: PropTypes.bool,
  features: PropTypes.shape({}),
  showResendEmailModal: PropTypes.bool,
  openCreateAccountModal: PropTypes.func.isRequired,
  searchAllUsers: PropTypes.func.isRequired,
  onResetPasswordClick: PropTypes.func.isRequired,
  onResendVerificationClick: PropTypes.func.isRequired,
  groupButtonsDisabled: PropTypes.bool.isRequired,
};

UserManagementHeader.defaultProps = {
  isButtonDisabled: true,
  features: {},
  showResendEmailModal: false,
};

export default UserManagementHeader;
