import dryVanBlackIcon from '../../assets/public/dryVanBlack.svg';
import flatBlackcon from '../../assets/public/flatBlack.svg';
import reeferBlackIcon from '../../assets/public/reeferBlack.svg';
import _findKey from 'lodash/findKey';

const vehicleTypes = {
  reeferTrailer: {
    id: 1,
    shortName: 'Reefer Trailer',
    longName: 'Reefer Trailer',
    icon: reeferBlackIcon,
    dropdownDisplayName: 'Reefer Trailer',
  },
  reeferTruck: {
    id: 2,
    shortName: 'Reefer Truck',
    longName: 'Reefer Truck Body',
  },
  dryVan: {
    id: 3,
    shortName: 'Dry Van',
    longName: 'Dry Van',
    icon: dryVanBlackIcon,
  },
  dryTrailer: {
    id: 4,
    shortName: 'Dry Trailer',
    longName: 'Dry Trailer',
    icon: dryVanBlackIcon,
    dropdownDisplayName: 'Dry Trailer',
  },
  flat: {
    id: 5,
    shortName: 'Flat',
    longName: 'Flat',
    icon: flatBlackcon,
    dropdownDisplayName: 'Flat Trailer',
  },
  dolly: {
    id: 6,
    shortName: 'Dolly',
    longName: 'Dolly',
  },
  na: {
    id: 7,
    shortName: 'N/A',
    longName: 'N/A',
  },
};
export default vehicleTypes;

export const getVehicleTypeDropdownDisplayName = shortName => {
  const vehicleType = findVehicleTypeByShortName(shortName);
  return vehicleType?.dropdownDisplayName ?? null;
};

export const findVehicleTypeByShortName = shortName => {
  const foundedVehicleType = _findKey(vehicleTypes, { shortName: shortName });
  return foundedVehicleType ? vehicleTypes[foundedVehicleType] : null;
};

export const getDropdownVehicleTypeOptions = () =>
  Object.values(vehicleTypes)
    .filter(vt => vt.dropdownDisplayName)
    .map(it => it.dropdownDisplayName);

export const findVehicleTypeByVehicleTypeId = vehicleTypeId => {
  const foundedVehicleType = _findKey(vehicleTypes, { id: vehicleTypeId });
  return foundedVehicleType ? vehicleTypes[foundedVehicleType] : null;
};

export const vehiclesById = {
  '1': {
    id: 1,
    shortName: 'Reefer Trailer',
    longName: 'Reefer Trailer',
  },
  '2': {
    id: 2,
    shortName: 'Reefer Truck',
    longName: 'Reefer Truck Body',
  },
  '3': {
    id: 3,
    shortName: 'Dry Van',
    longName: 'Dry Van',
  },
  '4': {
    id: 4,
    shortName: 'Dry Trailer',
    longName: 'Dry Trailer',
  },
  '5': {
    id: 5,
    shortName: 'Flat',
    longName: 'Flat',
  },
  '6': {
    id: 6,
    shortName: 'Dolly',
    longName: 'Dolly',
  },
  '7': {
    id: 7,
    shortName: 'N/A',
    longName: 'N/A',
  }
}
