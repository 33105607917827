import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Chip.css';

export const ChipComponent = ({ size = 'xsmall', data, handleRemoveValue, isInputFocused }) => {
  return (
    <div className={cx('chip-value', `chip-value-${size}`, { [`chip-value-ellipsis`]: !isInputFocused })} key={data.value}>
      {data.label}
      {handleRemoveValue && (
        <div className="chip-button" name={data.value} onClick={() => handleRemoveValue(data.value)}>
          ✕
        </div>
      )}
    </div>
  );
};

function Chip({ handleRemoveValue, handleRemoveAll, values, size = 'xsmall' }) {
  return (
    <div className="chip-container">
      {values?.map(val => (
        <ChipComponent data={val} handleRemoveValue={handleRemoveValue} size={size} key={val.value} />
      ))}
      {values?.length > 1 && (
        <div onClick={handleRemoveAll} className="chip-button-clear-all">
          X{`  `}Clear All
        </div>
      )}
    </div>
  );
}

Chip.propTypes = {
  handleRemoveValue: PropTypes.func,
  values: PropTypes.array,
  size: PropTypes.string,
};

export default Chip;
