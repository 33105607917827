import { UserTypes } from '../constants/enums/userTypes';
import { graphqlFormatArray } from '../utils/customStringUtils';

export const createAccountApi = async (fetch, email, roleNames) => {
  const query = `
    mutation {
      createUser(input: {
        email: "${email}"
        roleNames: ${graphqlFormatArray(roleNames)}
      }) {
        email
        roleNames
        emailVerified
        enabled
      }
    }
  `;

  return fetch('/auth/graphql', {
    method: 'POST',
    body: JSON.stringify({ query }),
  });
};

export const createTechnicianAccountApi = async (fetch, email) => {
  const data = {
    email,
    roleNames: [UserTypes.TECHNICIAN.typeName],
  };

  return fetch(`/auth/v1/register-user-anonymously`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Content-Type: application/json`,
    },
  });
};
