import { TOGGLE_FAQ_NAVBAR_ITEM } from '../constants';

const initialState = {
  faqNavbarActiveTitle: 'FAQ',
};

const actionMappings = {
  [TOGGLE_FAQ_NAVBAR_ITEM]: '_toggleFaqNavbarItem',
};

const reducer = {
  _toggleFaqNavbarItem(state, { title }) {
    if(title !== state.faqNavbarActiveTitle) {
      return {
        ...state,
        faqNavbarActiveTitle: title
      };
    }
    return {
      ...state
    }
  },
};

const faqReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default faqReducer;
