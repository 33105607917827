import { is401Response } from './errorUtils';
import { navigateToLogin } from './navigationUtils';

export const checkGraphqlQueryResponse = async ({ response }) => {
  let hasError = false;
  if (is401Response(response.status)) {
    localStorage.clear();
    return navigateToLogin();
  }
  if (!response.ok) {
    hasError = true;
  }
  const json = await response?.json();
  if (json?.errors?.length > 0) {
    hasError = true;
  }
  return hasError;
};
