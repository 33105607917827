import { OPEN_TRAILER_TRANSFERED_ALERT_DIALOG, OPEN_CREATE_CUSTOMER_ALERT_DIALOG } from '../constants';

const initialState = {
  isTransferTrailerModalOpen: false,
  isCreateCustomerModalOpen: false,
  customerName: '',
  vinNumber: '',
};

const actionMappings = {
  [OPEN_TRAILER_TRANSFERED_ALERT_DIALOG]: '_openTrailerTransferedAlertDialog',
  [OPEN_CREATE_CUSTOMER_ALERT_DIALOG]: '_openCreateCustomerAlertDialog',
};

const reducer = {
  _openTrailerTransferedAlertDialog(state, { payload: { vinNumber, customerName } }) {
    return {
      ...state,
      isTransferTrailerModalOpen: !state.isTransferTrailerModalOpen,
      vinNumber: vinNumber ?? '',
      customerName: customerName ?? '',
    };
  },

  _openCreateCustomerAlertDialog(state, { payload: { customerName } }) {
    return {
      ...state,
      isCreateCustomerModalOpen: !state.isCreateCustomerModalOpen,
      customerName: customerName ?? '',
    };
  },
};

const customerManagement = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default customerManagement;
