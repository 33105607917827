export default {
  CRITICAL: {
    id: 1,
    key: 'CRITICAL',
    name: 'Critical',
    color: 'red',
  },
  CAUTION: {
    id: 2,
    key: 'CAUTION',
    name: 'Caution',
    color: 'yellow',
  },
  OK: {
    id: 3,
    key: 'OK',
    name: 'Good',
    color: 'green',
  },
};
