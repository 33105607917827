import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Header.css';

function CustomHeaderButton({ activeScreen, title, pathName, url, icon, target }) {
  
  const isActive = link => (activeScreen.includes(link) ? true: false);

  function showActiveLinkIcon(link) {
    return (
      <div className="header-headerhr-container">
        <div className={isActive(link) ? 'header-link-active-icon' : 'header-link-normal-icon'} />
      </div>
    );
  }

  function showActiveLink(link) {
    return (
      <div className="header-headerhr-container">
        <div className={isActive(link) ? 'header-link-active' : 'header-link-normal'} />
      </div>
    );
  }

  function isLinkActive(link) {
    return activeScreen.includes(link) ? { position: 'relative', top: '2px', fontWeight: 600, color: 'white' } : { position: 'relative', top: '2px' };
  }

  return (
    <NavLink to={url} target={target ? target : '_self'} className={`header-route-link ${title?.toLowerCase()}-width`}  activeClassName="activeLink">
      <div>
        {icon && React.cloneElement(icon, { className: `${isActive(pathName) ? 'active-link-icon-color': 'link-svg-icon'}`})}
        {showActiveLinkIcon(pathName)}
      </div>
      <div className="header-link-navigation">
        <div className="header-link-text" style={isLinkActive(pathName)}>
          {title}
        </div>
        {showActiveLink(pathName)}
      </div>
    </NavLink>
  );
}

export default CustomHeaderButton;
