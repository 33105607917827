import _pickBy from 'lodash/pickBy';
import { SENSOR_BOX_TYPES } from '../constants/enums/deviceTypes';

export const findByName = name => _pickBy(SENSOR_BOX_TYPES, mfgr => mfgr.shortName === name);
export const findSensorBoxById = id => Object.values(SENSOR_BOX_TYPES).find(mfgr => mfgr?.id === id);
export const isXirgoDevice = deviceTypeName => deviceTypeName === SENSOR_BOX_TYPES.GO.shortName;
export const isMoreyDevice = deviceTypeName => deviceTypeName === SENSOR_BOX_TYPES.PRO.shortName;
export const isProTrailer = deviceTypeName => deviceTypeName === SENSOR_BOX_TYPES.PRO.deviceType;
export const isBaseTrailer = deviceTypeName => deviceTypeName === SENSOR_BOX_TYPES.BASE.deviceType;
export const isGoTrailer = deviceTypeName => deviceTypeName === SENSOR_BOX_TYPES.GO.deviceType;
export const isCargoCameraTrailer = deviceTypeName => deviceTypeName === SENSOR_BOX_TYPES.CARGO_CAMERA.deviceType;
export const isXT5300Trailer = deviceTypename => deviceTypename === SENSOR_BOX_TYPES.XT5300.deviceType;
export const isSlapNTrackTrailer = deviceTypename => deviceTypename === SENSOR_BOX_TYPES.SLAP_N_TRACK.deviceType;
