import { API_URL } from '../config/url';

export const sendLoginV2Credentials = async (username, password) => {
  return fetch(`${API_URL}/auth/v1/loginV2`, {
    headers: {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
  }).then(x => x.json());
};

export const refreshToken = async () => {
  const refreshTokenJson = JSON.stringify({ refreshToken: `${window.localStorage.getItem('refresh_token')}` });
  const idToken = window.localStorage.getItem('id_token');

  return fetch(`${API_URL}/auth/v1/refreshToken`, {
    method: 'POST',
    body: refreshTokenJson,
    headers: {
      'Authorization': `Bearer ${idToken}`,
      'Content-Type': 'application/json',
    },
  }).then(x => x.json());
};

export const acceptTermsOfServicesApi = async (username, password) => {
  return fetch(`${API_URL}/auth/v1/acceptTermsOfService`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${username}:${password}`)}`,
    },
  });
};
