import _some from 'lodash/some';
import _slice from 'lodash/slice';
import _every from 'lodash/every';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import { isUserTechnician } from '../security/authorization';
import {
  APPLY_FILTER,
  CHANGE_FILTER,
  CHANGE_WEIGHT_FILTER,
  CREATE_NEW_SHAPE,
  FETCH_GD_SERVICE_FAIL,
  FETCH_TRACKING_DASHBOARD_DATA_FAIL,
  HIDE_MODAL_COLLAPSES,
  RECEIVE_GD_SERVICE_DATA,
  SET_GD_SERVICE_DATA_VISIBILITY,
  RECEIVE_TRACKING_DASHBOARD_DATA_REFRESH,
  SEARCH_TRUCKS,
  SET_INITIAL_VIN_VALUE,
  SHOW_COLLAPSE,
  SHOW_PREVENTATIVE,
  SHOW_PREVENTATIVE_COLLAPSE,
  SHOW_SPECS_MENU,
  TOGGLE_ALERTS_STATE,
  TOGGLE_GD_SERVICE_CENTERS,
  TOGGLE_GD_SERVICE_LOADING,
  TOGGLE_GEOFENCE,
  TOGGLE_LIST_VIEW,
  TOGGLE_SHOW_SHAPES_VIEW,
  TOGGLE_TRACKING_DASHBOARD_DETAILS_MODAL,
  TOGGLE_TRACKING_DASHBOARD_FILTER_VIEW,
  TOGGLE_TRACKING_DASHBOARD_LOADING,
  TOGGLE_TRAFFIC_OVERLAY,
  TOGGLE_USER_PANEL,
  TOGGLE_WEATHER_CONDITIONS,
  UNLOADED_BOGIE_WEIGHT,
  TOGGLE_SENSOR_DETAILS_SCREEN,
  OPEN_NAVIGATION_DRAWER,
  CLOSE_NAVIGATION_DRAWER,
  FILTER_TRUCKS,
  FETCH_ALL_FLEET_TRAILERS_BEGIN,
  FETCH_ALL_FLEET_TRAILERS_SUCCESS,
  FETCH_ALL_FLEET_TRAILERS_FAIL,
  SET_INPUT_VALUE,
} from '../constants';
import { modalHeaderData, recentInspections, specificationsData } from '../config/properties';
import { handleMapFiltering } from '../utils/mapFilteringUtils';
import navigationDrawerSensors from '../constants/enums/navigationDrawerSensors';

const initialState = {
  allTrucks: [],
  filteredData: [],
  data: [],
  error: false,
  isLoading: false,
  filterExpanded: false,
  alertsActive: false,
  showDetailsModal: false,
  mapSearchInput: '',
  filterCriteria: [],
  showGDServiceCenters: false,
  showWeatherConditions: false,
  serviceCentersData: [],
  shapes: [],
  isInGeofenceMode: false,
  showTrafficOverlay: false,
  specsSheetExpanded: false,
  showCollapse: false,
  showPreventativeCollapse: false,
  showPreventative: false,
  modalHeaderData: {},
  activeVinNumber: '',
  specificationsData: {},
  recentInspections: {},
  showListView: false,
  filters: [
    {
      shortName: 'dry',
      displayName: 'Dry Trailer',
      active: false,
    },
    {
      shortName: 'reefer',
      displayName: 'Reefer Trailer',
      active: false,
    },
    {
      shortName: 'flat',
      displayName: 'Flat',
      active: false,
    },
    {
      shortName: 'unTethered',
      displayName: 'Untethered',
      active: false,
    },
    {
      shortName: 'tethered',
      displayName: 'Tethered',
      active: false,
    },
    {
      shortName: 'brakeRed',
      displayName: 'brakeRedToggle',
      active: false,
    },
    {
      shortName: 'tireRed',
      displayName: 'tireRedToggle',
      active: false,
    },
    {
      shortName: 'wheelTempRed',
      displayName: 'wheelTempRedToggle',
      active: false,
    },
    {
      shortName: 'boxBattRed',
      displayName: 'boxBattRedToggle ',
      active: false,
    },
    {
      shortName: 'N/A',
      displayName: 'N/A ',
      active: false,
    },
    {
      shortName: 'unloaded',
      displayName: 'Unloaded',
      active: false,
    },
    {
      shortName: 'loaded',
      displayName: 'Loaded',
      active: false,
    },
  ],
  weightFilters: [],
  showUserPanel: false,
  selectedVinValue: '',
  selectedUnitIdValue: '',
  sensorDetailsScreenActive: false,
  sensorActiveTab: '',
  isNavigationDrawerActive: false,

  allFleetTrailers: [],
  fleetTrailersLoading: false,
  fleetTrailersError: null,
  filteredFleetTrailers: [],
};

const actionMappings = {
  [RECEIVE_TRACKING_DASHBOARD_DATA_REFRESH]: '_receiveDataRefresh',
  [TOGGLE_TRACKING_DASHBOARD_LOADING]: '_toggleLoading',
  [FETCH_TRACKING_DASHBOARD_DATA_FAIL]: '_setFailureState',
  [TOGGLE_TRACKING_DASHBOARD_DETAILS_MODAL]: '_toggleDetailsModal',
  [TOGGLE_TRACKING_DASHBOARD_FILTER_VIEW]: '_toggleFilterView',
  [TOGGLE_SHOW_SHAPES_VIEW]: '_toggleShowShapesView',
  [CREATE_NEW_SHAPE]: '_createNewShape',
  [TOGGLE_LIST_VIEW]: '_toggleListView',
  [TOGGLE_ALERTS_STATE]: '_toggleAlertsState',
  [TOGGLE_USER_PANEL]: '_toggleUserPanel',
  [APPLY_FILTER]: '_applyFilter',
  [CHANGE_FILTER]: '_changeFilter',
  [CHANGE_WEIGHT_FILTER]: '_changeWeightFilter',
  [SEARCH_TRUCKS]: '_searchTrucks',
  [TOGGLE_GD_SERVICE_CENTERS]: '_toggleGdServiceCenters',
  [RECEIVE_GD_SERVICE_DATA]: '_receiveGdServiceData',
  [TOGGLE_GD_SERVICE_LOADING]: '_togleGdServiceCentresLoading',
  [FETCH_GD_SERVICE_FAIL]: '_setGdServiceCentresFailure',
  [SET_GD_SERVICE_DATA_VISIBILITY]: '_setGDServiceDataVisibility',
  [TOGGLE_GEOFENCE]: '_toggleGeofence',
  [TOGGLE_TRAFFIC_OVERLAY]: '_toggleTrafficOverlay',
  [TOGGLE_WEATHER_CONDITIONS]: '_toggleWeatherConditions',
  [SHOW_SPECS_MENU]: '_showSpecsMenu',
  [SHOW_COLLAPSE]: '_openCollapse',
  [SHOW_PREVENTATIVE_COLLAPSE]: '_openPreventativeCollapse',
  [SHOW_PREVENTATIVE]: '_showPreventative',
  [HIDE_MODAL_COLLAPSES]: '_hideModalCollapses',
  [SET_INITIAL_VIN_VALUE]: '_setInitialVinValue',
  [TOGGLE_SENSOR_DETAILS_SCREEN]: '_toggleSensorDetailsScreen',
  [OPEN_NAVIGATION_DRAWER]: '_openNavigationDrawer',
  [CLOSE_NAVIGATION_DRAWER]: '_closeNavigationDrawer',
  [FILTER_TRUCKS]: '_filterTrucks',
  [FETCH_ALL_FLEET_TRAILERS_BEGIN]: '_fetchAllFleetTrailerBegin',
  [FETCH_ALL_FLEET_TRAILERS_SUCCESS]: '_fetchAllFleetTrailerSuccess',
  [FETCH_ALL_FLEET_TRAILERS_FAIL]: '_fetchAllFleetTrailerFail',
  [SET_INPUT_VALUE]: '_setInputValue',
};

const someActiveFilters = (filters, start, endExclusive) => _some(_slice(filters, start, endExclusive), filter => filter.active); // takes only those arrays, who's active is TRUE

export const getFilteredTrucks = (allTrucks, selectedFilters, selectedCustomer, selectedVins) => {
  const mapFiltersContext = { selectedFilters, allTrucks, selectedCustomer, selectedVins };
  let filteredTrucks = allTrucks;

  filteredTrucks = handleMapFiltering(mapFiltersContext);
  return _isEmpty(selectedFilters) && !selectedCustomer ? allTrucks : filteredTrucks;
};

const reducer = {
  _receiveDataRefresh(state, { payload: { allTrucks, selectedFilters, selectedCustomer, selectedVins } }) {
    const { mapSearchInput } = state;

    let filteredTrucks = allTrucks;
    if (selectedFilters.length !== 0) {
      filteredTrucks = getFilteredTrucks(filteredTrucks, selectedFilters, selectedCustomer, selectedVins);
    }

    // Search
    if (mapSearchInput) {
      const keywordUppercase = mapSearchInput.toUpperCase();
      filteredTrucks = filteredTrucks.filter(trailer => trailerUnitIdOrVinMatches(trailer, keywordUppercase));
    }

    return {
      ...state,
      data: filteredTrucks,
      filteredData: filteredTrucks,
      allTrucks,
    };
  },
  _toggleLoading(state) {
    return {
      ...state,
      isLoading: !state.isLoading,
    };
  },

  _setFailureState(state) {
    return {
      ...state,
      error: true,
    };
  },

  _toggleDetailsModal(state, { trailer }) {
    return {
      ...state,
      activeVinNumber: trailer.vinNumber === null ? '' : trailer.vinNumber,
      activeUnitID: trailer.unitID === null ? '' : trailer.unitID,
      modalHeaderData: modalHeaderData[0],
      recentInspections: recentInspections[0],
      showDetailsModal: !state.showDetailsModal,
      specsSheetExpanded: false,
      specificationsData: specificationsData[0],
      timestamp: moment.utc('2017-09-03 14:21'),
    };
  },

  _toggleFilterView(state) {
    return {
      ...state,
      filterExpanded: !state.filterExpanded,
    };
  },

  _toggleAlertsState(state) {
    return {
      ...state,
      alertsActive: !state.alertsActive,
    };
  },

  _showSpecsMenu(state) {
    return {
      ...state,
      specsSheetExpanded: !state.specsSheetExpanded,
    };
  },

  _openCollapse(state) {
    return {
      ...state,
      showCollapse: !state.showCollapse,
    };
  },

  _setInputValue(state, { keyword }) {
    return {
      ...state,
      mapSearchInput: keyword,
    };
  },

  _openPreventativeCollapse(state) {
    return {
      ...state,
      showPreventativeCollapse: !state.showPreventativeCollapse,
    };
  },

  _showPreventative(state) {
    return {
      ...state,
      showPreventative: !state.showPreventative,
    };
  },

  _hideModalCollapses(state) {
    return {
      ...state,
      showPreventativeCollapse: false,
      showCollapse: false,
    };
  },

  // _toggleGdServiceCenters(state) {
  //   return {
  //     ...state,
  //     serviceCentersData,
  //     showGDServiceCenters: !state.showGDServiceCenters,
  //   };
  // },

  _togleGdServiceCentresLoading(state) {
    return {
      ...state,
      serviceCentersDataLoading: !state.serviceCentersData,
      // isInGeofenceMode: false,
    };
  },
  _setGdServiceCentresFailure(state) {
    return {
      ...state,
      error: true,
    };
  },
  _setGDServiceDataVisibility(state, { showGDServiceCenters }) {
    return {
      ...state,
      showGDServiceCenters,
    };
  },
  _receiveGdServiceData(state, { payload }) {
    return {
      ...state,
      serviceCentersData: payload.aftermarketDealers,
      showGDServiceCenters: !state.showGDServiceCenters,
    };
  },
  _toggleGeofence(state) {
    return {
      ...state,
      isInGeofenceMode: !state.isInGeofenceMode,
    };
  },
  _toggleWeatherConditions(state) {
    return {
      ...state,
      showWeatherConditions: !state.showWeatherConditions,
    };
  },

  _toggleTrafficOverlay(state) {
    return {
      ...state,
      showTrafficOverlay: !state.showTrafficOverlay,
    };
  },

  _toggleShowShapesView(state) {
    return {
      ...state,
      shapes: [...state.shapes.concat(state.shape)],
    };
  },

  _createNewShape(state, { payload }) {
    return {
      ...state,
      shapes: [...state.shapes.concat(payload)],
    };
  },

  _toggleListView(state) {
    return {
      ...state,
      showListView: !state.showListView,
    };
  },

  _toggleUserPanel(state) {
    return {
      ...state,
      showUserPanel: !state.showUserPanel,
    };
  },

  _applyFilter(state, { filters }) {
    const FGs = [
      [state.filters[0], state.filters[1], state.filters[2]],
      [state.filters[3], state.filters[4]],
      [state.filters[5], state.filters[6], state.filters[7], state.filters[8], state.filters[9]],
      [state.filters[11], state.filters[12]],
    ];
    const activeFGs = FGs.filter(fg => someActiveFilters(fg, 0)); // complete array filters[], updated with TRUE filters
    for (let i = 0; i < activeFGs.length; i += 1) {
      activeFGs[i] = activeFGs[i].filter(f => f.active);
    }

    let filtered = state.allTrucks;

    if (_some(_slice(state.filters, 0), filter => filter.active) && state.alertsActive === false) {
      filtered = state.allTrucks.filter(t =>
        _every(activeFGs, afg =>
          _some(
            afg,
            f =>
              f.displayName === t.truckType ||
              f.displayName === t.status ||
              f.displayName === t.lightFault ||
              (f.displayName === t.brakeFault && t.hasActiveAbsFaults) ||
              f.displayName === t.tireFault ||
              (f.displayName === 'Unloaded' && parseFloat(t.weight.replace(/,/g, '')) <= UNLOADED_BOGIE_WEIGHT) ||
              (f.displayName === 'Loaded' && parseFloat(t.weight.replace(/,/g, '')) > UNLOADED_BOGIE_WEIGHT),
          ),
        ),
      );
    }

    const unloadedFilterActive = activeFGs.some(item => item.shortName === 'unloaded');

    // Filter By Weight
    if (state.weightFilters.length === 2) {
      filtered = filtered.filter(truck => {
        if (unloadedFilterActive && !truck.cargoWeightOnBogie) {
          return true;
        } else if (truck.cargoWeightOnBogie && truck.cargoWeightOnBogie !== 'N/A') {
          return truck.cargoWeightOnBogie >= state.weightFilters[0] && truck.cargoWeightOnBogie <= state.weightFilters[1];
        } else {
          return false;
        }
      });
    }
    if (state.alertsActive === true) {
      filtered = filtered.filter(t => _every(activeFGs, afg => _some(afg, f => f.displayName === t.truckType || f.displayName === t.status)));

      const faults = ['N/A'];
      filtered = filtered.filter(truck => faults.includes(truck.lightFault) && faults.includes(truck.brakeFault) && faults.includes(truck.tireFault));
    }

    return {
      ...state,
      filterCriteria: activeFGs,
      filteredData: filtered,
      data: filtered,
      mapSearchInput: '',
      filterExpanded: !state.filterExpanded,
      filters,
    };
  },

  _changeFilter(state, { filters }) {
    const activeFilters = filters.filter(filterKey => filterKey.active);
    return {
      ...state,
      filterCriteria: activeFilters,
      filters,
    };
  },

  _changeWeightFilter(state, { data }) {
    return {
      ...state,
      weightFilters: data,
    };
  },

  _searchTrucks(state, { keyword, selectedFilters, selectedCustomer, selectedVins }) {
    let { allTrucks: filteredTrailerList } = state;
    filteredTrailerList = getFilteredTrucks(filteredTrailerList, selectedFilters, selectedCustomer, selectedVins);

    if (keyword && keyword.length > 0) {
      const keywordUppercase = keyword.toUpperCase();
      filteredTrailerList = _filter(filteredTrailerList, trailer => trailerUnitIdOrVinMatches(trailer, keywordUppercase));
    }

    return {
      ...state,
      data: filteredTrailerList,
      filteredData: filteredTrailerList,
      filteredFleetTrailers: filteredTrailerList,
      mapSearchInput: keyword,
    };
  },

  _setInitialVinValue(state, { vin }) {
    const newObject = {
      label: vin,
      value: vin,
    };

    if (isUserTechnician()) {
      return {
        ...state,
        selectedVinValue: newObject,
      };
    }

    if (!_isEmpty(state.allTrucks)) {
      const vinValid = _get(state, 'allTrucks').find(truck => _get(truck, 'vinNumber') === _get(newObject, 'value'), '[0]');
      if (vinValid) {
        const selectedUnitId = _get(state, 'allTrucks').filter(truck => _get(truck, 'vinNumber') === _get(newObject, 'value'), '[0]');

        const selectedUnitIdValue = {
          label: _get(selectedUnitId, '[0].unitID', ''),
          value: _get(selectedUnitId, '[0].unitID', ''),
        };

        return {
          ...state,
          selectedVinValue: newObject,
          selectedUnitIdValue,
        };
      }
    }

    return {
      ...state,
    };
  },

  _toggleSensorDetailsScreen(state, { activeTab, goBack }) {
    if (activeTab === navigationDrawerSensors.drawerHeaderSensorTypes.TRACKING && state.sensorDetailsScreenActive) {
      return {
        ...state,
        sensorActiveTab: activeTab,
      };
    }

    if (activeTab?.groupingSensors && goBack) {
      return {
        ...state,
        sensorActiveTab: activeTab,
      };
    }
    return {
      ...state,
      sensorDetailsScreenActive: !state.sensorDetailsScreenActive,
      sensorActiveTab: activeTab,
    };
  },
  _openNavigationDrawer(state, { trailer }) {
    const { filteredData } = state;
    const keywordUppercase = trailer?.vin?.toUpperCase();
    const filteredTrucks = filteredData?.filter(trailer => trailerUnitIdOrVinMatches(trailer, keywordUppercase));
    return {
      ...state,
      data: filteredTrucks,
      filteredData: filteredTrucks,
      activeVinNumber: trailer?.vinNumber ?? '',
      activeUnitID: trailer?.unitID ?? '',
      isNavigationDrawerActive: true,
      showListView: false,
      sensorDetailsScreenActive: false,
    };
  },
  _closeNavigationDrawer(state) {
    return {
      ...state,
      isNavigationDrawerActive: false,
      sensorDetailsScreenActive: false,
      activeVinNumber: '',
    };
  },
  _filterTrucks(state, { selectedFilters, selectedCustomer, selectedVins }) {
    const allTrucks = _get(state, 'allTrucks', []);
    const { mapSearchInput } = state;

    let filteredTrucks = allTrucks;

    filteredTrucks = getFilteredTrucks(allTrucks, selectedFilters, selectedCustomer, selectedVins);

    // Search
    if (mapSearchInput) {
      const keywordUppercase = mapSearchInput.toUpperCase();
      filteredTrucks = filteredTrucks.filter(trailer => trailerUnitIdOrVinMatches(trailer, keywordUppercase));
    }

    return {
      ...state,
      filteredData: filteredTrucks,
      data: filteredTrucks,
      filteredFleetTrailers: filteredTrucks,
    };
  },
  _fetchAllFleetTrailerBegin(state) {
    return {
      ...state,
      fleetTrailersLoading: true,
      fleetTrailersError: null,
      isLoading: true,
      mapSearchInput: '',
    };
  },
  _fetchAllFleetTrailerSuccess(state, { allFleetTrailers }) {
    return {
      ...state,
      allFleetTrailers,
      filteredFleetTrailers: allFleetTrailers,
      allTrucks: allFleetTrailers,
      data: allFleetTrailers,
      filteredData: allFleetTrailers,
      fleetTrailersLoading: false,
      fleetTrailersError: null,
      isLoading: false,
      error: false,
    };
  },
  _fetchAllFleetTrailerFail(state, { error }) {
    return {
      ...state,
      fleetTrailersLoading: false,
      fleetTrailersError: error,
      isLoading: false,
      error: false,
    };
  },
};

function trailerUnitIdOrVinMatches(trailer, keyword) {
  if (!trailer || !keyword) {
    return false;
  }
  return trailer?.unitID?.toUpperCase()?.includes(keyword) || trailer?.vinNumber?.toUpperCase()?.includes(keyword);
}

const trackingReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default trackingReducer;
