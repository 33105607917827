import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Input.css';
import dangerIcon from '../../assets/public/Danger.png';
import exitIcon from '../../assets/public/exit-icon.png';

function Input({
  onChange,
  label,
  required,
  optional,
  size = 'small',
  errorMessage,
  helpTextLeft,
  helpTextRight,
  disabled,
  rightIcon,
  leftIcon,
  onLeftIconClick,
  onRightIconClicked,
  clearInput,
  placeholder,
  type,
  value,
  onKeyDown,
  _ref,
  containerStyle,
  onInput,
  id,
  maxLength,
  onKeyUp,
  onPaste,
  isRequired,
  name,
  withSelect,
  onBlur
}) {
  const isHelpTextActive = helpTextLeft || helpTextRight;
  const hasError = Boolean(errorMessage);
  return (
    <div style={containerStyle} className={cx('custom-input-main-container', { [`custom-input-disabled`]: disabled })}>
      <div className={`custom-input-label-${size}`}>
        {label && <div className="custom-input-label">{label}</div>}
        {required && <div className="custom-input-label-required">*</div>}
        {optional && <div className="custom-input-label-optional">(optional)</div>}
      </div>
      <label
        htmlFor={id}
        className={cx('custom-input-container', `custom-input-container-${size}`, {
          'custom-input-container-error': hasError,
          'custom-input-container-withSelect': withSelect,
        })}
      >
        {leftIcon && <img className={`custom-input-img-${size}`} src={leftIcon} onClick={onLeftIconClick} alt="left icon" />}
        <input
          className={cx('custom-input', `custom-input-${size}`, {
            'custom-input-error': hasError,
            'custom-input-disabled': disabled,
            'custom-input-image-right': rightIcon,
            'custom-input-image-left': leftIcon,
          })}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          type={type}
          onKeyDown={onKeyDown}
          ref={_ref}
          onInput={onInput}
          id={id}
          maxLength={maxLength}
          onKeyUp={onKeyUp}
          onPaste={onPaste}
          required={isRequired}
          name={name}
          onBlur={onBlur}
          // {...props}
        />
        {Boolean(clearInput) && <img className={`custom-input-img-${size}`} src={exitIcon} onClick={clearInput} alt="clear input" />}
        {rightIcon && <img className={`custom-input-img-${size}`} src={rightIcon} onClick={onRightIconClicked} alt="right icon" />}
      </label>
      {isHelpTextActive && (
        <div className="custom-input-helptext">
          {helpTextLeft && <div>{helpTextLeft}</div>}
          {helpTextRight && <div>{helpTextRight}</div>}
        </div>
      )}
      {hasError && (
        <div className="custom-input-error-message">
          <img src={dangerIcon} alt="error icon" className="custom-input-error-icon" />
          {errorMessage}
        </div>
      )}
    </div>
  );
}

Input.propTypes = {
  onChange: PropTypes.func,
};

export default Input;
