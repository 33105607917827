import { findUserTypePatternBasedOnTypeName, UserTypes } from './userTypes';
import _uniq from 'lodash/uniq';
import { agGridDateComparator, agGridDateFilter, getFormattedTimestampAgGrid } from '../../utils/agGridUtils';
import CustomFilter from '../../components/CurrentFleetStatus/components/CustomFilter';

export default {
  USERNAME: {
    key: 'USERNAME',
    displayName: 'Username',
    attributeName: 'username',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
  },
  ENABLED: {
    key: 'ENABLED',
    displayName: 'Enabled',
    attributeName: 'enabled',
    agGridProps: {
      flex: 1,
      sortable: false,
      suppressMenu: true,
      resizable: true,
      cellRenderer: 'userEnabled',
      filter: true,
      floatingFilterComponent: 'userEnabledCustomFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: [
          {
            displayKey: 'regexp',
            displayName: 'Regular Expression',
            predicate: ([fv1], cellValue) => {
              if (fv1 === 'Enabled') {
                return cellValue;
              }
              return !cellValue;
            },
            numberOfInputs: 1,
          },
        ],
      },
    },
    columnFilterOptions: ['Enabled', 'Disabled'],
    customSelectIdentifierName: 'userEnabledUserDropdown',
  },
  ACCOUNT_STATUS: {
    key: 'ACCOUNT_STATUS',
    displayName: 'Account Status',
    attributeName: 'accountStatus',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'accountStatusCustomFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    columnFilterOptions: ['CONFIRMED', 'FORCE_CHANGE_PASSWORD'],
    customSelectIdentifierName: 'accountStatusDropdown',
  },
  EMAIL_VERIFIED: {
    key: 'EMAIL_VERIFIED',
    displayName: 'Email Verified',
    attributeName: 'emailVerified',
    agGridProps: {
      flex: 1,
      sortable: false,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'emailVerifiedCustomFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    columnFilterOptions: ['Yes', 'No'],
    customSelectIdentifierName: 'emailVerifiedDropdown',
  },
  TYPE: {
    key: 'TYPE',
    displayName: 'Type',
    attributeName: 'roleType',
    agGridProps: {
      flex: 1,
      sortable: false,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: 'roleTypeCustomFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: [
          {
            displayKey: 'regexp',
            displayName: 'Regular Expression',
            predicate: ([userTypeName], cellValue) => {
              const roleTypesArray = cellValue?.split(', ');
              const userTypeRegEx = findUserTypePatternBasedOnTypeName(userTypeName);

              switch (userTypeName) {
                case UserTypes.STANDARD_USER.userType:
                  return roleTypesArray?.includes(UserTypes.STANDARD_USER.userType);
                case UserTypes.ACCOUNT_ADMINISTRATOR.userType:
                  return roleTypesArray?.includes(UserTypes.ACCOUNT_ADMINISTRATOR.userType);
                case UserTypes.GO_INSTALLER.userType:
                  return roleTypesArray?.includes(UserTypes.GO_INSTALLER.typeName);
                default:
                  return roleTypesArray?.some(role => role.match(userTypeRegEx));
              }
            },
            numberOfInputs: 1,
          },
        ],
      },
    },
    columnFilterOptions: _uniq(Object.values(UserTypes).map(user => user.typeName)),
    customSelectIdentifierName: 'roleTypeDropdown',
  },
  UPDATED: {
    key: 'UPDATED',
    displayName: 'Updated',
    attributeName: 'updatedDate',
    agGridProps: {
      comparator: agGridDateComparator,
      valueFormatter: getFormattedTimestampAgGrid,
      flex: 1,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressAndOrCondition: true,
        comparator: agGridDateFilter,
      },
    },
  },
  CREATED: {
    key: 'CREATED',
    displayName: 'Created',
    attributeName: 'createdDate',
    agGridProps: {
      comparator: agGridDateComparator,
      valueFormatter: getFormattedTimestampAgGrid,
      flex: 1,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        debounceMs: 500,
        suppressAndOrCondition: true,
        comparator: agGridDateFilter,
      },
    },
  },
  CUSTOMER_NAMES: {
    key: 'CUSTOMER_NAMES',
    displayName: 'Customer Name(s)',
    attributeName: 'customerName',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
  },
  THERMO_KING_CONTROL: {
    key: 'THERMO_KING_CONTROL',
    displayName: 'Reefer Controls',
    attributeName: 'tkCommandControl',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
      cellRenderer: 'tkCommandControl',
    },
  },
  GROUPS: {
    key: 'GROUPS',
    displayName: 'Group(s)',
    attributeName: 'groups',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      resizable: true,
      filter: true,
      floatingFilterComponent: CustomFilter,
    },
  },
  ACTION: {
    key: 'ACTION',
    displayName: 'Action',
    attributeName: 'action',
    agGridProps: {
      flex: 1,
      sortable: false,
      suppressMenu: true,
      resizable: true,
      cellRenderer: 'userRoleEdit',
    },
  },
};
