export const classes = {
  paper: {
    width: '100%',
    height: 'calc(100vh - 170px)',
    // marginBottom: '20px',
  },
  paperCustomer: {
    width: '100%',
    height: 'calc(100vh - 114px)',
    marginBottom: '20px',
  },
  container: {
    height: '89%',
  },
  navHeadRow: {
    backgroundColor: 'white',
    display: 'flex ',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    height: '56px',
    width: '100%',
  },
  tableHeaderStyle: {
    fontFamily: 'Muli',
    fontSize: '13px',
    fontWeight: 700,
    textAlign: 'left',
    color: '#2b2e31',
    backgroundColor: '#e0e4ec',
    whiteSpace: 'noWrap',
  },
  stickyColumn: {
    position: 'sticky',
    right: 0,
    top: 0,
    fontFamily: 'Muli',
    fontSize: '13px',
    fontWeight: 700,
    textAlign: 'left',
    color: '#2b2e31',
    backgroundColor: '#e0e4ec',
    whiteSpace: 'noWrap',
    borderLeft: '1px solid #d9dde7',
    boxShadow: '-12px 0 8px -6px #d8d8d8',
  },
  tableCellStyle: {
    fontFamily: 'Muli',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'left',
    color: '#2b2e31',
  },
  tableStickyCellStyle: {
    position: 'sticky',
    right: 0,
    top: 0,
    fontFamily: 'Muli',
    fontSize: '11px',
    fontWeight: 700,
    color: '#2b2e31',
    backgroundColor: 'white',
    borderLeft: '1px solid #d9dde7',
    boxShadow: '-12px 0 8px -6px #d8d8d8',
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '56px',
    backgroundColor: 'white',
    padding: '0 10px',
    justifyContent: 'space-between',
  },
  leftHeaderGrid: {
    display: 'flex',
    gap: '10px',
  },
  rightHeaderGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  editIcon: {
    cursor: 'pointer',
  },
  addRemoveGroupsGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};
