import {
  FETCH_TRAILER_IMAGE_HISTORY_DATA,
  TOGGLE_TRAILER_IMAGE_HISTORY_DATA_LOADING,
  FETCH_TRAILER_IMAGE_HISTORY_DATA_FAIL,
  EMPTY_TRAILER_IMAGE_HISTORY,
} from '../constants';

const initialState = {
  trailerImageHistoryData: [],
  trailerImageHistoryDataLoading: false,
  trailerImageHistoryDataFailError: false,
  hasMoreData: true,
};

const actionMappings = {
  [FETCH_TRAILER_IMAGE_HISTORY_DATA]: '_fetchTrailerImageHistoryData',
  [TOGGLE_TRAILER_IMAGE_HISTORY_DATA_LOADING]: '_toggleTrailerImageHistoryDataLoading',
  [FETCH_TRAILER_IMAGE_HISTORY_DATA_FAIL]: '_fetchTrailerImageHistoryDataFail',
  [EMPTY_TRAILER_IMAGE_HISTORY]: '_setEmptyTrailerImageHistory',
};

const reducer = {
  _fetchTrailerImageHistoryData(state, { payload: { trailerImageHistoryData } }) {
    const hasMoreData = trailerImageHistoryData.length !== 0;
    return {
      ...state,
      trailerImageHistoryData: state.trailerImageHistoryData.concat(trailerImageHistoryData),
      trailerImageHistoryDataFailError: false,
      hasMoreData,
    };
  },
  _toggleTrailerImageHistoryDataLoading(state) {
    return {
      ...state,
      trailerImageHistoryDataLoading: !state.trailerImageHistoryDataLoading,
    };
  },
  _fetchTrailerImageHistoryDataFail(state) {
    return {
      ...state,
      trailerImageHistoryDataFailError: true,
    };
  },
  _setEmptyTrailerImageHistory(state) {
    return {
      ...state,
      trailerImageHistoryData: [],
    };
  },
};

const trailerImageHistoryReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default trailerImageHistoryReducer;
