export const disableUserApi = async (fetch, userId) => {
    const query = `
      mutation {
        disableUser(
            userId: "${userId}",
        )
      }
    `;
  
    return fetch('/auth/graphql', {
      method: 'POST',
      body: JSON.stringify({ query }),
    });
  };
  