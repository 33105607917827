/* eslint-disable import/prefer-default-export */
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _pickBy from 'lodash/pickBy';
import _isNil from 'lodash/isNil';
import _uniqBy from 'lodash/uniqBy';
import alertPredicateTypes from '../constants/enums/alertPredicateTypes';
import { findGeofenceByName } from './geofenceUtils';
import alertConfigurationTypes from '../constants/enums/alertConfigurationTypes';
import thermoKingFaultLevelEnum from '../constants/enums/thermoKingFaultLevelEnum';
import sensorTypeIds from '../constants/enums/sensorTypeIds';

export const findAlertPredicateTypeByName = predicateTypeName => {
  if (!predicateTypeName) {
    return '';
  }

  return (getAlertPredicateTypes() || []).find(predicateType => predicateType.name === predicateTypeName);
};

export const findAlertPredicateTypeById = aptId => {
  if (!aptId) {
    return '';
  }
  return (getAlertPredicateTypes() || []).find(it => it.id === aptId);
};

export const findReportTypeById = reportTypeId => {
  if (!reportTypeId) {
    return null;
  }

  return (getAlertPredicateTypes() || []).filter(it => it.isReportOption).find(reportType => reportType.reportTypeId === reportTypeId);
};

export const getAlertPredicateTypes = () => Object.values(alertPredicateTypes || []);

export const getSelectableAlertPredicateTypes = () => Object.values(alertPredicateTypes || []).filter(alertpred => alertpred.selectable);

export const isDurationPredicateTypeBySensorTypeName = sensorTypeName =>
  (getSelectableAlertPredicateTypes() || []).some(predicate => predicate.name === sensorTypeName && predicate.isDurationPredicate);

export const getAlertPredicateTypeOptions = alertPredicateType => Object.values(alertPredicateType?.predicateOptions || {});

export const getLightPredicateDescByPredicateTypeName = alertPredicateOptionName => {
  if (!alertPredicateOptionName) {
    return '';
  }

  const lightAlertPredicateSensorStatusType = (getAlertPredicateTypeOptions(alertPredicateTypes.LIGHTS) || []).find(
    alertPred => alertPred.name === alertPredicateOptionName,
  );
  return lightAlertPredicateSensorStatusType ? lightAlertPredicateSensorStatusType.description : '';
};

export const findLightFaultPredicateByFaultTypeIds = (lightFaultOptions, alertPredicate) =>
  (lightFaultOptions || []).find(fault => _isEqual(fault.faultTypeIds, alertPredicate.lightFaultTypeIds));

export const filterNonNull = values => (values || []).map(it => it.value);

export const isLoadedBogieWeightAlertPredicateType = alertPredicateTypeName => alertPredicateTypeName === alertPredicateTypes.NET_BOGIE_WEIGHT_THRESHOLD.name;

export const isTowAlertPredicateType = alertPredicateTypeName => alertPredicateTypeName === alertPredicateTypes.TOW_ALERT.name;

export const isGeofenceBoundaryAlertPredicateType = alertPredicateTypeName => alertPredicateTypeName === alertPredicateTypes.GEOFENCE_BOUNDARY_CROSSING.name;

export const filterEmptyObjectProperties = array =>
  (array || []).map(it =>
    _pickBy(it, value => {
      if (Array.isArray(value)) {
        return !_isEmpty(value);
      }
      return !_isNil(value);
    }),
  );

export const findAlertPredicateByName = (selectedAlertPredicateTypeName, alertPredicateType, geofenceIds) => {
  if (!selectedAlertPredicateTypeName || !alertPredicateType) {
    return '';
  }

  if (alertPredicateType.isGeofencePredicate && !anyCustomerGeofencePredicate(selectedAlertPredicateTypeName)) {
    return findGeofenceByName(selectedAlertPredicateTypeName, geofenceIds);
  }

  const alertPredicateTypeOptions = getAlertPredicateTypeOptions(alertPredicateType);
  return (alertPredicateTypeOptions || []).find(apto => apto.displayName === selectedAlertPredicateTypeName);
};

export const anyCustomerGeofencePredicate = selectedAlertPredicateTypeName =>
  selectedAlertPredicateTypeName === alertPredicateTypes.GEOFENCE_BOUNDARY_CROSSING.predicateOptions.ALL_GEOFENCES.name;

export const findTisAlertPredicateByName = name => Object.values(alertPredicateTypes.TIS_STATUS_CHANGE.predicateOptions).find(apto => apto?.name === name);

export const getSelectedGeofenceIds = (allGeofences, selectedGeofences) => {
  if (!allGeofences || !selectedGeofences) return;
  return allGeofences?.filter(geofence => selectedGeofences?.some(selected => geofence?.name === selected?.label)).map(geofence => geofence?.id);
};

export const formatAllGeofencesIntoSelect = allGeofences => {
  if (!allGeofences) {
    return [];
  }
  return allGeofences?.map(geofence => ({ label: geofence?.name ?? '', value: geofence?.id ?? null }));
};

const isAlertPredicateOptionAnyCustomerGeofence = selectedAlertPredicateOption =>
  selectedAlertPredicateOption?.some(
    o =>
      o?.name?.trim().toLowerCase() ||
      o?.label?.trim().toLowerCase() === alertPredicateTypes.GEOFENCE_BOUNDARY_CROSSING.predicateOptions.ALL_GEOFENCES.name.trim().toLowerCase(),
  );

export const isAnyCustomerGeofence = selectedAlertPredicateOption => {
  if (!selectedAlertPredicateOption) return;
  if (Array.isArray(selectedAlertPredicateOption)) {
    return isAlertPredicateOptionAnyCustomerGeofence(selectedAlertPredicateOption);
  }

  let selectedAlertPredicateOptionArray = [];
  selectedAlertPredicateOptionArray.push(selectedAlertPredicateOption);

  return isAlertPredicateOptionAnyCustomerGeofence(selectedAlertPredicateOptionArray);
};

export const handleSwitchChange = (event, props, alertRuleId) => (event ? props.enableAlertRule(alertRuleId) : props.disableAlertRule(alertRuleId));

export const vinsAsString = alertRuleVehicles => {
  const uniqAlertRuleVehicles = _uniqBy(alertRuleVehicles, vehicle => vehicle?.vin);
  return (uniqAlertRuleVehicles || []).map(it => it?.vin).join(', ');
};

export const filterBasedOnCustomer = (trailer, customerId) => trailer?.customerId?.toString() === customerId?.toString();

export const matchesReportPathPattern = path =>
  path.match(alertConfigurationTypes.YARD.pathPattern) ||
  path.match(alertConfigurationTypes.DWELL.pathPattern) ||
  path.match(alertConfigurationTypes.ABS_SUMMARY.pathPattern);

export const matchesAlertPathPattern = path => path.match(alertConfigurationTypes.ALERT.pathPattern);

export const getAlarmSeverityData = thermoKingFaultLevelValue => {
  const formattedSeverityOptions = Object.values(thermoKingFaultLevelEnum).map(item => ({ label: item.name, value: item.key }));
  const formattedSeverityValue = formattedSeverityOptions.filter(item => thermoKingFaultLevelValue?.includes(item?.value));

  return { formattedSeverityOptions, formattedSeverityValue };
};

export const reeferAvailableVehicles = availableVehicles =>
  availableVehicles?.filter(trailer => trailer?.installedSensorTypeIdsList?.includes(sensorTypeIds.reeferUnit.id));

export const hasReeferInstalled = availableVehicles =>
  availableVehicles?.some(trailer => trailer?.installedSensorTypeIdsList?.includes(sensorTypeIds.reeferUnit.id));
