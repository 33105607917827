export const deleteUserApi = async (fetch, userId) => {
  const query = `
      mutation {
        deleteUser(
            userId: "${userId}",
        )
      }
    `;

  return fetch('/auth/graphql', {
    method: 'POST',
    body: JSON.stringify({ query }),
  });
};
