import {
  POWER_BI_TOKEN_SET_LOADING,
  POWER_BI_TOKEN_CREATE_SUCCESS,
  POWER_BI_TOKEN_CREATE_ERROR,
} from '../constants';

const initialState = {
  loading: false,
  token: null,
  error: null,
};

const actionMappings = {
  [POWER_BI_TOKEN_SET_LOADING]: '_powerBiTokenSetLoading',
  [POWER_BI_TOKEN_CREATE_SUCCESS]: '_powerBiTokenCreateSuccess',
  [POWER_BI_TOKEN_CREATE_ERROR]: '_powerBiTokenCreateError',
};

const reducer = {
  _powerBiTokenSetLoading(state, { loading }) {
    return {
      ...state,
      loading,
      error: null,
    };
  },

  _powerBiTokenCreateSuccess(state, { token }) {
    return {
      ...state,
      token,
      error: null,
    };
  },

  _powerBiTokenCreateError(state, { error }) {
    return {
      ...state,
      error,
    };
  },
};

const powerBiToken = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default powerBiToken;
