import React from 'react';
import _isNumber from 'lodash/isNumber';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import AlertIcon from 'mdi-react/AlertIcon';
import CheckIcon from 'mdi-react/CheckCircleIcon';
import ProgressIcon from 'mdi-react/ProgressClockIcon';
import sensorStatusTypes from '../constants/enums/sensorStatusTypes';
import { COMMAND_STATUS, REEFER_UNIT_STATUS, commandTypes } from '../constants/enums/thermoKing';
import { convertCelciusToFahrenheit } from './customMathUtils';
import { getMsElapsedFormattedTimeAgo, getMsElapsedSince, getTimeAgo } from './dateUtils';
import cms from '../messages/index';
import '../components/SensorTab/styles/TKCommandStatuses.css';

export const loggerSensorsActivityLogRow = ({ ambientTemperature, humidity, indSensor1, indSensor2, indSensor3, indSensor4, indSensor5, indSensor6 }) => {
  if (!ambientTemperature && !humidity && !indSensor1 && !indSensor2 && !indSensor3 && !indSensor4 && !indSensor5 && !indSensor6) return 'N/A';
  const ambientTemperatureFormatted = ambientTemperature !== null ? `Ambient: ${convertCelciusToFahrenheit(ambientTemperature)}°F` : null;
  const humidityFormatted = humidity ? `Humidity: ${displayHumidity(humidity)}` : null;
  const sensor1Formatted = indSensor1 ? `Sensor1: ${convertCelciusToFahrenheit(indSensor1)}°F` : null;
  const sensor2Formatted = indSensor2 ? `Sensor2: ${convertCelciusToFahrenheit(indSensor2)}°F` : null;
  const sensor3Formatted = indSensor3 ? `Sensor3: ${convertCelciusToFahrenheit(indSensor3)}°F` : null;
  const sensor4Formatted = indSensor4 ? `Sensor4: ${convertCelciusToFahrenheit(indSensor4)}°F` : null;
  const sensor5Formatted = indSensor5 ? `Sensor5: ${convertCelciusToFahrenheit(indSensor5)}°F` : null;
  const sensor6Formatted = indSensor6 ? `Sensor6: ${convertCelciusToFahrenheit(indSensor6)}°F` : null;
  const displayLoggerSensors = [
    ambientTemperatureFormatted,
    humidityFormatted,
    sensor1Formatted,
    sensor2Formatted,
    sensor3Formatted,
    sensor4Formatted,
    sensor5Formatted,
    sensor6Formatted,
  ]
    .filter(logger => logger)
    .join(', ');

  return displayLoggerSensors;
};

const zoneInfoFormat = zone => {
  if (!_isNumber(zone)) return 'N/A';

  return `${convertCelciusToFahrenheit(zone)}°F,`;
};

export const zoneInfoActivityLogRow = ({
  setPoint1,
  setPoint2,
  setPoint3,
  returnAir1,
  returnAir2,
  returnAir3,
  dischargeAir1,
  dischargeAir2,
  dischargeAir3,
}) => {
  if (!setPoint1 && !setPoint2 && !setPoint3 && !returnAir1 && !returnAir2 && !returnAir3 && !dischargeAir1 && !dischargeAir2 && !dischargeAir3) {
    return 'N/A';
  }
  return (
    <div>
      <div>
        Set Point: {zoneInfoFormat(setPoint1)} {zoneInfoFormat(setPoint2)} {zoneInfoFormat(setPoint3)}
      </div>
      <div>
        Return Air: {zoneInfoFormat(returnAir1)} {zoneInfoFormat(returnAir2)} {zoneInfoFormat(returnAir3)}
      </div>
      <div>
        Discharge Air: {zoneInfoFormat(dischargeAir1)} {zoneInfoFormat(dischargeAir2)} {zoneInfoFormat(dischargeAir3)}
      </div>
    </div>
  );
};

export const engineHoursLogRow = ({ engineHours, electricalHours, totalHours }) => {
  if (!engineHours && !electricalHours && !totalHours) return 'N/A';
  const engineHoursFormatted = engineHours ?? 'N/A';
  const electricalHoursFormatted = electricalHours ?? 'N/A';
  const totalHoursFormatted = totalHours ?? 'N/A';

  return (
    <div>
      <div>Engine Hours - {engineHoursFormatted}</div>
      <div>Electric Hours - {electricalHoursFormatted}</div>
      <div>Total Hours - {totalHoursFormatted}</div>
    </div>
  );
};

export const engineHoursLogDisplayV2 = ({ engine_hours, electrical_hours, total_hours }) => {
  if (!engine_hours?.value && !electrical_hours?.value && !total_hours?.value) {
    return 'N/A';
  }
  const engineHoursFormatted = engine_hours?.value ?? 'N/A';
  const electricalHoursFormatted = electrical_hours?.value ?? 'N/A';
  const totalHoursFormatted = total_hours?.value ?? 'N/A';

  return (
    <div>
      <div>Engine Hours - {engineHoursFormatted}</div>
      <div>Electric Hours - {electricalHoursFormatted}</div>
      <div>Total Hours - {totalHoursFormatted}</div>
    </div>
  );
};

export const displayTempValue = tempValue => {
  if (!_isNumber(tempValue)) {
    return sensorStatusTypes.NOT_AVAILABLE.display;
  }

  return `${tempValue} °F`;
};

export const displayVoltage = voltageValue => {
  if (!voltageValue) {
    return sensorStatusTypes.NOT_AVAILABLE.display;
  }

  return `${voltageValue} V`;
};

export const displayFaultInfo = thermoKingFaults => {
  if (!thermoKingFaults) {
    return sensorStatusTypes.NOT_AVAILABLE.display;
  }
  const faultDescription = thermoKingFaults?.map(fault => fault.description).join('; ');

  return faultDescription;
};

export const displayTKFaultInfoV2 = faultsArray => {
  if (!faultsArray?.length) {
    return sensorStatusTypes.NOT_AVAILABLE.display;
  }
  return faultsArray?.map(fault => fault?.alarmDescription)?.join('; ') ?? '';
};

export const displayFaultInfoStatus = thermoKingFaults => {
  if (_isEmpty(thermoKingFaults)) {
    return 'No Faults';
  }
  const faultsCount = thermoKingFaults?.length;

  return faultsCount === 1 ? `${faultsCount} Fault` : `${faultsCount} Faults`;
};

export const faultInfoSeverityLevel = thermoKingFaults => {
  const faultInfoSeverity = thermoKingFaults?.filter(item => item.color !== 'G')?.map(item => item?.color);

  return !_isEmpty(faultInfoSeverity) ? 'Caution' : 'Good';
};

export const displayReeferUnitStatus = ({ powerOn, unitModeDetail }) => {
  if (!powerOn) return REEFER_UNIT_STATUS.OFF;

  return REEFER_UNIT_STATUS[unitModeDetail] ?? REEFER_UNIT_STATUS.ON;
};

export const displayHumidity = humidityValue => {
  if (!_isNumber(humidityValue)) return sensorStatusTypes.NOT_AVAILABLE.display;

  return `${humidityValue} %`;
};

export const mainTKCommandStatus = thermoKingCommands => {
  if (!thermoKingCommands || _isEmpty(thermoKingCommands)) {
    return <div>No Recent Commands</div>;
  }
  const tkCommandsInProgressStatuses = thermoKingCommands?.filter(item => !item?.status && !item?.commandCompleted)?.length;
  const tkCommandsFailedStatuses = thermoKingCommands?.filter(item => item?.status === COMMAND_STATUS.FAILED)?.length;
  const tkCommandsSuccessStatuses = thermoKingCommands?.filter(item => item?.status === COMMAND_STATUS.SUCCESS)?.length;
  const lastTKCommand = thermoKingCommands?.[0] ?? null;
  const lastTKCommandDateTime = lastTKCommand?.created ?? null;
  const timeAgo = getMsElapsedFormattedTimeAgo(lastTKCommandDateTime);
  const timeElapsedInMs = getMsElapsedSince(lastTKCommandDateTime);
  const { day } = getTimeAgo(timeElapsedInMs);

  if (day >= 1) {
    return <div>No Recent Commands</div>;
  }
  return (
    <div className="commandstatus-container">
      <div className="commandstatus">
        <div className="commandstatus-row">
          <ProgressIcon size={19} />
          <div>{tkCommandsInProgressStatuses ?? 'N/A'}</div>
        </div>
        <div className="commandstatus-row">
          <AlertIcon size={19} color="#d50000" />
          <div>{tkCommandsFailedStatuses ?? 'N/A'}</div>
        </div>
        <div className="commandstatus-row">
          <CheckIcon size={19} color="#5ba477" />
          <div>{tkCommandsSuccessStatuses ?? 'N/A'}</div>
        </div>
      </div>
      <div className="commandstatuses-timeago">Last Command: {timeAgo}</div>
    </div>
  );
};

export const tkCommandErrorHandler = (json, commandType) => {
  const isBatchCommand = _includes(commandTypes, commandType);
  const errorMessage = json?.errors?.[0]?.message;
  const isForbidden = errorMessage === 'Forbidden';
  const tkError = json?.data?.[commandType]?.tkError?.responseBody;
  const commandFailedErrorMessage = tkError && JSON.parse(tkError);
  const commandFailedWithoutErrorMessage = !json?.data?.[commandType];

  if (errorMessage) {
    return isForbidden ? cms.tkUserNoAccess : errorMessage;
  }

  if (isBatchCommand) {
    return json?.data?.[commandType]?.map(command => {
      const tkBatchError = command?.tkError?.responseBody;
      const batchCommandFailedErrorMessage = tkBatchError && JSON.parse(tkBatchError);
      return { error: batchCommandFailedErrorMessage?.message, commandType, vin: command.vin };
    });
  }

  if (commandFailedErrorMessage) {
    return commandFailedErrorMessage?.message;
  }

  if (commandFailedWithoutErrorMessage) {
    return cms.tkUserBadRequest;
  }

  return null;
};
