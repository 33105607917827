import sensorStatusTypes from './sensorStatusTypes';
export const WHEEL_LOCATION = {
  frontLeftWheel: 'Roadside Front',
  frontRightWheel: 'Curbside Front',
  rearLeftWheel: 'Roadside Rear',
  rearRightWheel: 'Curbside Rear',
};
export const WHEEL_SIDE = {
  tireA: 'Outside Tire',
  tireB: 'Inside Tire',
};
export const WHEEL_END_STATUS = {
  OK: {
    status: 'OK',
    display: 'OK',
    sensorStatus: sensorStatusTypes.GOOD,
  },
  WHEEL_END_OVER_TEMPERATURE: {
    status: 'Wheel End Over Temperature',
    display: 'Wheel End Over Temperature',
    sensorStatus: sensorStatusTypes.CRITICAL,
  },
  WHEEL_END_UNDER_TEMPERATURE: {
    status: 'Wheel End Under Temperature',
    display: 'Wheel End Under Temperature',
    sensorStatus: sensorStatusTypes.CRITICAL,
  },
};
export const TIRE_PRESSURE_STATUS_A = {
  OK: {
    status: 'OK',
    display: 'OK',
    sensorStatus: sensorStatusTypes.GOOD,
  },
  SLOW_TIRE_LEAK_WHILE_NOT_OPERATING: {
    status: 'Slow tire leak detected while not operating',
    display: 'Slow tire leak detected while not operating',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SLOW_TIRE_LEAK_WHILE_OPERATING: {
    status: 'Slow tire leak detected while operating',
    display: 'Slow tire leak detected while operating',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  MODERATE_TIRE_LEAK: {
    status: 'Moderate tire leak detected',
    display: 'Moderate tire leak detected',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SEVERE_TIRE_LEAK_DETECTED: {
    status: 'Severe tire leak detected',
    display: 'Severe tire leak detected',
    sensorStatus: sensorStatusTypes.CRITICAL,
  },
  SITAR_OK: {
    status: '00',
    display: 'OK',
    sensorStatus: sensorStatusTypes.GOOD
  },
  SITAR_NOT_OK: {
    status: '01',
    display: 'Not Ok',
    sensorStatus: sensorStatusTypes.CRITICAL,
  },
  SUP_OUT_OF_RANGE: {
    status: '02',
    display: 'Out of Range',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SUP_LIMIT: {
    status: '03',
    display: 'Limit Exceeded',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SUP_STALE_VALUE: {
    status: '04',
    display: 'Stale Value',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SUP_TIMED_OUT: {
    status: '05',
    display: 'Timed Out',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SUP_SOFT_FAULT: {
    status: '06',
    display: 'Soft Fault',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SUP_HARD_FAULT: {
    status: '07',
    display: 'Hard Fault',
    sensorStatus: sensorStatusTypes.CRITICAL,
  },
  UNKNOWN: {
    status: '08',
    display: 'Sitar Unknown Status',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
};
export const TIRE_PRESSURE_STATUS_B = {
  OK: {
    status: 'OK',
    display: 'OK',
    sensorStatus: sensorStatusTypes.GOOD,
  },
  LOW_TIRE_PRESSURE: {
    status: 'Low Tire Pressure Detected',
    display: 'Low Tire Pressure Detected',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  LOW_TIRE_PRESSURE_WHILE_NOT_OPERATING: {
    status: 'Low Tire Pressure Detected While Not Operating',
    display: 'Low Tire Pressure Detected While Not Operating',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  HIGH_TIRE_PRESSURE: {
    status: 'High Tire Pressure Detected',
    display: 'High Tire Pressure Detected',
    sensorStatus: sensorStatusTypes.CRITICAL,
  },
  SITAR_OK: {
    status: '00',
    display: 'OK',
    sensorStatus: sensorStatusTypes.GOOD
  },
  SITAR_NOT_OK: {
    status: '01',
    display: 'Not Ok',
    sensorStatus: sensorStatusTypes.CRITICAL,
  },
  SUP_OUT_OF_RANGE: {
    status: '02',
    display: 'Out of Range',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SUP_LIMIT: {
    status: '03',
    display: 'Limit Exceeded',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SUP_STALE_VALUE: {
    status: '04',
    display: 'Stale Value',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SUP_TIMED_OUT: {
    status: '05',
    display: 'Timed Out',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SUP_SOFT_FAULT: {
    status: '06',
    display: 'Soft Fault',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
  SUP_HARD_FAULT: {
    status: '07',
    display: 'Hard Fault',
    sensorStatus: sensorStatusTypes.CRITICAL,
  },
  UNKNOWN: {
    status: '08',
    display: 'Sitar Unknown Status',
    sensorStatus: sensorStatusTypes.CAUTION,
  },
};