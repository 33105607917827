import { CONFIRM_DEVICE_CONFIG_CREATE, CONFIRM_DEVICE_CONFIG_SUBMIT, OTA_ERROR, OTA_LOADING, OTA_SUCCESS } from '../constants';
import { upgradeJobType } from '../utils/otaUtils';

const initialState = {
  otaData: null,
  otaLoading: false,
  otaError: false,
};

const actionMappings = {
  [OTA_LOADING]: '_otaLoading',
  [OTA_ERROR]: '_otaError',
  [OTA_SUCCESS]: '_otaSuccess',
  [CONFIRM_DEVICE_CONFIG_CREATE]: '_confirmDeviceConfigCreate',
  [CONFIRM_DEVICE_CONFIG_SUBMIT]: '_confirmDeviceConfigSubmit',
};

const reducer = {
  _otaLoading(state, { payload: { otaLoading } }) {
    return {
      ...state,
      otaLoading,
    };
  },
  _otaError(state) {
    return {
      ...state,
      error: true,
    };
  },
  _otaSuccess(state, { payload: { fleetOtaStatus } }) {
    return {
      ...state,
      otaData: fleetOtaStatus,
      error: false,
    };
  },

  _confirmDeviceConfigCreate(state, { payload: { confirmConfig, message } }) {
    return {
      ...state,
      successDeviceConfigCreate: confirmConfig,
      deviceConfigCreateMessage: message,
    };
  },

  _confirmDeviceConfigSubmit(state, { payload: { confirmConfig, message, jobType } }) {
    let newState = null;
    switch (jobType) {
      case upgradeJobType.CONFIG:
        newState = {
          ...state,
          successDeviceConfigSubmit: confirmConfig,
          deviceConfigCreateSubmitMessage: message,
        };
        break;
      case upgradeJobType.SCRIPT:
        newState = {
          ...state,
          successDeviceScriptConfigSubmit: confirmConfig,
          deviceConfigScriptCreateMessage: message,
        };
        break;
      case upgradeJobType.MAIN_APP:
        newState = {
          ...state,
          successDeviceMainAppConfigSubmit: confirmConfig,
          deviceConfigMainAppCreateMessage: message,
        };
        break;
      case upgradeJobType.DEVICE_RESET:
        newState = {
          ...state,
          successDeviceResetConfigSubmit: confirmConfig,
          deviceConfigResetCreateMessage: message,
        };
        break;
      default:
        return newState;
    }
    return newState;
  },
};

const otaReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default otaReducer;
