import React from 'react';
import Switch from '../../Switch/Switch';

function EnableTKCustomComponent({ data, onToggleCommandControlState }) {
  const userRoleTypesArray = data?.roleType?.split(', ')?.map(role => ({ name: role })) ?? [];
  const style = {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (
    <div style={style}>
      <Switch
        icon="checkicon"
        onChange={checked => onToggleCommandControlState(checked, { ...data, roles: userRoleTypesArray })}
        checked={data?.tkCommandControl}
      />
    </div>
  );
}

export default EnableTKCustomComponent;
