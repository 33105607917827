import React from 'react';
import _invoke from 'lodash/invoke';
import _isNumber from 'lodash/isNumber';
import CloseOctagonIcon from 'mdi-react/CloseOctagonIcon';

export const checkFuse = () => {
  const checkFuseStyles = {
    container: { display: 'flex', gap: '2px', color: '#C11414', textAlign: 'start' },
    checkFuseVoltage: { color: '#C11414', fontWeight: '700', fontSize: '14px' },
    checkFuseText: { color: '#970500', fontWeight: '700', fontSize: '12px' },
  };

  return (
    <div style={checkFuseStyles.container}>
      <div>
        <CloseOctagonIcon color="#C11414" size={16} />
      </div>
      <div>
        <div style={checkFuseStyles.checkFuseVoltage}>0 V</div>
        <u style={checkFuseStyles.checkFuseText}>Check Fuse</u>
      </div>
    </div>
  );
};

export const getActivityLogBatteryValue = batteryBoxValue => {
  if (!batteryBoxValue || Number.isNaN(batteryBoxValue)) {
    return 'N/A';
  }

  const valueInVolts = batteryBoxValue / 1000;
  const formattedValueInVolts = _invoke(valueInVolts, 'toFixed', 1);

  return `${formattedValueInVolts}V`;
};

export const getBatteryVoltageDetails = (vehicleDetailData, deviceType, features) => {
  if (!vehicleDetailData || !deviceType) return;

  const batteryVoltage = vehicleDetailData?.fleetPulseDeviceDiagnostics?.batteryVoltage ?? null;
  const healthStatus = vehicleDetailData?.fleetPulseDeviceDiagnostics?.healthStatus ?? null;
  const trailerBatteryStatus = healthStatus === 'Critical - Check Fuse' && !features.CheckFuse ? 'Critical' : healthStatus;
  const formattedBatteryVoltage = `${_invoke(batteryVoltage, 'toFixed', 1)} V`;
  let trailerBatteryText = typeof batteryVoltage === 'number' ? formattedBatteryVoltage : 'N/A';

  if (vehicleDetailData?.checkFuse && features.CheckFuse) {
    trailerBatteryText = checkFuse();
  }

  return { trailerBatteryText, trailerBatteryStatus };
};

export const getBackupBatteryDetails = (vehicleDetailData, deviceType) => {
  if (!vehicleDetailData || !deviceType) return;

  const batteryVoltage = vehicleDetailData?.backupBatteryDiagnostics?.backupBatteryMillivolts ?? null;
  const backupBatteryStatus = vehicleDetailData?.backupBatteryDiagnostics?.healthStatus ?? null;
  const backupBatteryText = getActivityLogBatteryValue(batteryVoltage);

  return { backupBatteryText, backupBatteryStatus };
};

export const getBatteryVoltageFromMillivolts = millivoltsValue => {
  if (!millivoltsValue || !_isNumber(millivoltsValue)) {
    return '-';
  }

  const valueInVolts = millivoltsValue / 1000;
  const decimalValue = valueInVolts?.toFixed(1) ?? null;
  return Number(decimalValue) ?? null;
};
