/* eslint-disable import/prefer-default-export */

// eslint-disable-next-line no-return-assign
export const navigateToLogin = () => (window.location.href = '/');

export const isTabActive = tab => {
  let pathname = window.location.pathname;

  pathname.indexOf(1);

  pathname.toLowerCase();

  pathname = pathname.split('/')[1];

  return pathname === tab;
};

export const isCurrentPage = pageName => {
  let pathname = window.location.pathname;
  pathname.indexOf(1);
  pathname.toLowerCase();
  pathname = pathname.split('/')[2];

  return pathname === pageName;
};

export const pushHistory = (props, route) => {
  const { pathname } = props.location;

  if (pathname !== route) {
    props.history.push(route);
  }
};
