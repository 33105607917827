import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import {
  FETCH_ALL_VINS_AND_UNIT_IDS_DATA_FAIL,
  RECEIVE_ALL_TRUCKS_VINS_UNIT_IDS,
  RECEIVE_TRAILERS_PER_CUSTOMER_COUNT,
  TOGGLE_ALL_VINS_AND_UNIT_IDS_LOADING,
  SET_INITIAL_VIN,
  DROPDOWN_VIN_OPTION,
  DROPDOWN_UNIT_ID_OPTION,
} from '../constants';
import { isUserTechnician } from '../security/authorization';

const initialState = {
  allTrucks: [],
  trailersPerCustomerCount: [],
  error: false,
  isLoading: false,
  selectedVinValue: '',
  selectedUnitIdValue: '',
};

const actionMappings = {
  [RECEIVE_ALL_TRUCKS_VINS_UNIT_IDS]: '_receiveAllTrucksVinsAndUnitIds',
  [RECEIVE_TRAILERS_PER_CUSTOMER_COUNT]: '_receiveTrailersPerCustomerCount',
  [TOGGLE_ALL_VINS_AND_UNIT_IDS_LOADING]: '_toggleLoading',
  [FETCH_ALL_VINS_AND_UNIT_IDS_DATA_FAIL]: '_setFailureState',
  [SET_INITIAL_VIN]: '_setInitialVinValue',
  [DROPDOWN_VIN_OPTION]: '_dropdownVinOption',
  [DROPDOWN_UNIT_ID_OPTION]: '_dropdownUnitIdOption',
};

const reducer = {
  _receiveAllTrucksVinsAndUnitIds(state, { payload: { data } }) {
    return {
      ...state,
      allTrucks: data,
    };
  },
  _receiveTrailersPerCustomerCount(state, { trailersPerCustomerCount }) {
    return {
      ...state,
      trailersPerCustomerCount,
    };
  },
  _toggleLoading(state) {
    return {
      ...state,
      isLoading: !state.isLoading,
    };
  },

  _setFailureState(state) {
    return {
      ...state,
      error: true,
    };
  },
  _setInitialVinValue(state, { vin }) {
    const newObject = {
      label: vin,
      value: vin,
    };

    if (isUserTechnician()) {
      return {
        ...state,
        selectedVinValue: newObject,
      };
    }

    if (!_isEmpty(state.allTrucks)) {
      const vinValid = _get(state, 'allTrucks').find(truck => _get(truck, 'vinNumber') === _get(newObject, 'value'), '[0]');
      if (vinValid) {
        const selectedUnitId = _get(state, 'allTrucks').filter(truck => _get(truck, 'vinNumber') === _get(newObject, 'value'), '[0]');

        const selectedUnitIdValue = {
          label: _get(selectedUnitId, '[0].unitID', ''),
          value: _get(selectedUnitId, '[0].unitID', ''),
        };

        return {
          ...state,
          selectedVinValue: newObject,
          selectedUnitIdValue,
        };
      }
    }

    return {
      ...state,
    };
  },
  _dropdownVinOption(state, { vin }) {
    const newObject = {
      label: vin.label,
      value: vin.value,
    };

    if (!_isEmpty(state.allTrucks)) {
      const selectedUnitId = state.allTrucks.filter(truck => truck.vinNumber === newObject.value)[0];

      const selectedUnitIdValue = {
        label: selectedUnitId.unitID,
        value: selectedUnitId.unitID,
      };
      return {
        ...state,
        selectedVinValue: newObject,
        selectedUnitIdValue: selectedUnitId.unitID ? selectedUnitIdValue : null,
      };
    }
    return {
      ...state,
      selectedVinValue: newObject,
    };
  },
  _dropdownUnitIdOption(state, { unitID }) {
    const newObject = {
      label: unitID.label,
      value: unitID.value,
    };

    if (!_isEmpty(state.allTrucks)) {
      const selectedVin = state.allTrucks.filter(truck => truck.unitID === newObject.value)[0];

      const selectedOption = {
        label: selectedVin.vinNumber,
        value: selectedVin.vinNumber,
      };
      return {
        ...state,
        selectedVinValue: selectedOption,
        selectedUnitIdValue: newObject,
      };
    }

    return {
      ...state,
      selectedUnitIdValue: newObject,
    };
  },
};

const allVinsAndUnitIds = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default allVinsAndUnitIds;
