import _get from 'lodash/get';

import {isUserTechnician} from '../security/authorization'

export const formatUserGeolocationHeader = () => {
  const userGeolocationHeader = setUserGeolocationHeader();
  return !userGeolocationHeader ? '' : {
    "User-Geolocation": userGeolocationHeader,
  };
};

export const setUserGeolocationHeader = () => {
  const lat = getLocalStorageCoordinate('latitude');
  const lng = getLocalStorageCoordinate('longitude');

  if(lat && lng && isUserTechnician()) {
    return `${lat}, ${lng}`
  }

  return '';
};

const getLocalStorageCoordinate = (coordUnit) => localStorage.getItem(coordUnit);

export const getCurrentUserLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(getLocationSuccess, () => false);
  }
};

export const getLocationSuccess = position => {
  const lat = getCurrentCoordinate(position, 'latitude');
  const lng = getCurrentCoordinate(position, 'longitude');

  if(lat && lng) {
    setCurrentCoordinate('latitude', lat);
    setCurrentCoordinate('longitude', lng);
  }
};

const getCurrentCoordinate = (position, coordUnit) => _get(position, `coords.${coordUnit}`, '');

const setCurrentCoordinate = (coordUnit, value) => localStorage.setItem(coordUnit, value);
