export const UserResendEmailTypes = {
  RESET_PASSWORD: {
    title: 'Password Reset Email',
    description: 'Would you like to send the password reset email to ',
    confirmationMessage: 'The password reset email was sent to ',
    key: 'RESET_PASSWORD',
  },
  RESEND_VERIFICATION: {
    title: 'Resend Verification Email',
    description: 'Would you like to send the verification email to ',
    confirmationMessage: 'The verification email was sent to ',
    key: 'RESEND_VERIFICATION',
  },
};
