import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { geofenceTypes } from '../constants/enums/geofenceTypes';

export const formatWithinCustomerGeofenceNames = withinCustomerGeofenceNames => {
  return _isEmpty(withinCustomerGeofenceNames) ? '' : withinCustomerGeofenceNames;
};

export const findGeofenceByName = (selectedGeofenceName, geofenceIds) => {
  if (!selectedGeofenceName || _isEmpty(geofenceIds)) {
    return '';
  }
  return (geofenceIds || []).find(geofence => _get(geofence, 'name') === selectedGeofenceName);
};

export const findSelectedGeofencesByGeofenceIds = (selectedGeofenceIds, allGeofences) => {
  if (_isEmpty(selectedGeofenceIds) || _isEmpty(allGeofences)) {
    return [];
  }
  let filteredGeofences = [];
  selectedGeofenceIds.forEach(geofenceId => {
    const geofence = allGeofences?.find(it => it.id === geofenceId);
    filteredGeofences.push({ label: geofence?.name, value: geofence?.id });
  });
  return filteredGeofences;
};

export const parseMapGeofencesV2 = geofence => {
  if (_isEmpty(geofence)) {
    return {};
  }

  let formattedGeofence = {
    id: geofence?.geofenceId ?? '',
    name: geofence?.name ?? '',
    isActive: !geofence?.geofenceDeactivated,
    description: geofence?.description ?? '',
    geofenceTypeId: geofence?.geofenceTypeId ?? '',
  };

  if (geofence?.geofenceTypeId === geofenceTypes.RADIUS.id) {
    formattedGeofence.shape = {
      type: geofenceTypes.RADIUS.name,
      radius: geofence?.radiusMeters ?? '',
      center: {
        lat: geofence?.centerPointCoordinate?.latitude ?? '',
        lng: geofence?.centerPointCoordinate?.longitude ?? '',
      },
    };
  } else if (geofence?.geofenceTypeId === geofenceTypes.POLYGON.id) {
    formattedGeofence.shape = {
      type: geofenceTypes.POLYGON.name,
      path: mapBoundaryCoordinates(geofence?.boundaryCoordinates),
    };
  } else {
    throw new Error('Unknown geofence type!');
  }

  return formattedGeofence;
};

const mapBoundaryCoordinates = boundaryCoordinates => boundaryCoordinates?.map(bc => ({ lat: bc.latitude, lng: bc.longitude }));

export const convertMapOverlayToShape = (type, overlay) => {
  if (type === geofenceTypes.RADIUS.name) {
    return {
      type,
      center: { lat: overlay.center.lat(), lng: overlay.center.lng() },
      radius: overlay.radius,
    };
  }

  return {
    type,
    path: overlay
      .getPath()
      .getArray()
      .map(c => ({ lat: c.lat(), lng: c.lng() })),
  };
};
