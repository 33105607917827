export const showVehicleLocation = vehicleGpsData => {
  const vehicleCityAndState = vehicleGpsData?.displayCityStateAndZip ?? '';
  const vehicleStreetAndAdress = vehicleGpsData?.displayAddressLine1 ?? '';
  const lat = vehicleGpsData?.location?.lat ?? '';
  const lng = vehicleGpsData?.location?.lng ?? '';
  const vehicleAddressAvailable = vehicleCityAndState && vehicleStreetAndAdress;
  let vehicleLocation;
  if (vehicleAddressAvailable) {
    vehicleLocation = vehicleCityAndState;
  } else if (lat === 0 && lng === 0) {
    vehicleLocation = `${lat},${lng}`;
  } else if (lat && lng) {
    vehicleLocation = `${lat.toFixed(2)}, ${lng.toFixed(2)}`;
  }

  return {
    location: vehicleLocation ?? 'N/A',
    addressLine: vehicleStreetAndAdress ?? 'N/A',
    cityAndState: vehicleCityAndState ?? 'N/A',
    latLng: lat && lng ? `${lat?.toFixed(3)}, ${lng?.toFixed(3)}` : 'N/A',
  };
};

export const formattedSpeedValue = speed => {
  if (speed === null || speed === undefined) {
    return 'N/A';
  }

  return Math.round(speed) ?? 'N/A';
};
