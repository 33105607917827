import { fetchVehicleGroups } from '../api/vehicleGroupsApi';

import { VEHICLE_GROUPS_LOADING, VEHICLE_GROUPS_ERROR, VEHICLE_GROUPS_SUCCESS } from '../constants';
import { handleErrors } from '../utils/errorUtils';

const toggleLoading = vehicleGroupsLoading => ({
  type: VEHICLE_GROUPS_LOADING,
  payload: { vehicleGroupsLoading },
});

const fetchDataFailure = () => ({ type: VEHICLE_GROUPS_ERROR });

export const receiveVehicleGroupsData = (customerId, vehicleGroupsData) => ({
  type: VEHICLE_GROUPS_SUCCESS,
  payload: { customerId, vehicleGroupsData },
});

export const fetchVehicleGroupsData = (customerId, fetch) => async dispatch => {
  try {
    dispatch(toggleLoading(true));
    const response = await fetchVehicleGroups(customerId, fetch);

    if (!response.ok) {
      handleErrors(response);
      dispatch(fetchDataFailure());
      dispatch(toggleLoading(false));
      return;
    }

    const data = await response.json();
    dispatch(receiveVehicleGroupsData(customerId, data?.customers[0]));
    dispatch(toggleLoading(false));
  } catch (err) {
    dispatch(fetchDataFailure());
    console.log(`err`, err);
    dispatch(toggleLoading(false));
  }
};
