export default {
  ALL: {
    label: 'All',
    value: 'All',
    key: 'ALL',
    default: true,
  },
  LAST_24_HOURS: {
    label: 'Last 24 Hours',
    value: 'Last 24 Hours',
    key: 'LAST_24_HOURS',
    days: 1,
  },
  LAST_7_DAYS: {
    label: 'Last 7 Days',
    value: 'Last 7 Days',
    key: 'LAST_7_DAYS',
    days: 7,
  },
  LAST_14_DAYS: {
    label: 'Last 14 Days',
    value: 'Last 14 Days',
    key: 'LAST_14_DAYS',
    days: 14,
  },
  LAST_31_DAYS: {
    label: 'Last 31 Days',
    value: 'Last 31 Days',
    key: 'LAST_31_DAYS',
    days: 31,
  },
};
