import {
  SET_USER_MANAGEMENT_DATA_LOADING,
  FETCH_USER_MANAGEMENT_DATA_FAIL,
  FETCH_USER_MANAGEMENT_DATA_SUCCESS,
  TOGGLE_USER_SELECT,
  TOGGLE_ALL_USERS_SELECT,
  SEARCH_ALL_USERS,
  SET_CUSTOMERS_DATA_LOADING,
  FETCH_CUSTOMERS_DATA_FAIL,
  FETCH_CUSTOMERS_DATA_SUCCESS,
  OPEN_CREATE_ACCOUNT_MODAL,
  SET_EMAIL_ADDRESS,
  SET_SELECTED_USER_TYPES,
  SET_SELECTED_CUSTOMERS,
  SET_SELECTED_GO_CUSTOMERS,
  SET_SELECTED_ADMIN_CUSTOMERS,
  SET_DISPLAY_CUSTOMERS,
  SET_DISPLAY_GO_CUSTOMERS,
  SET_DISPLAY_ADMIN_CUSTOMERS,
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CLOSE_RESEND_EMAIL_MODAL,
  SET_RESEND_EMAIL_TYPE,
  RESEND_EMAIL_BEGIN,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAIL,
  SET_EDIT_USER_MODE,
  DISABLE_USER,
  OPEN_CREATE_NEW_USER_DIALOG,
  OPEN_EDIT_USER_DIALOG,
  OPEN_DELETE_USER_DIALOG,
  OPEN_USER_ALERT_DIALOG_BOX,
  CLOSE_USER_ALERT_DIALOG_BOX,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from '../constants';

const initialState = {
  loading: false,
  error: null,
  users: [],
  filteredUsers: [],
  selectedUsers: [],
  customersDataLoading: false,
  customersDataError: null,
  customers: [],
  emailAddress: '',
  selectedUserInformation: {},
  emailAddressError: '',
  selectedUserTypes: [],
  selectedCustomers: [],
  selectedGOCustomers: [],
  selectedAdminCustomers: [],
  displayCustomers: false,
  displayGOCustomers: false,
  displayAdminCustomers: false,
  createAccountError: '',
  deleteUserError: null,
  isCreatingAccount: false,
  showCreateAccountModal: false,
  showResendEmailModal: false,
  resendEmailType: '',
  isSendingEmail: false,
  resendEmailError: '',
  resendEmailSuccess: false,
  initialSelectedUserTypes: [],
  initialSelectedCustomers: [],
  initialSelectedGOCustomers: [],
  initialSelectedAdminCustomers: [],
  isEditMode: false,
  disableUserFail: false,
  isCreateUserDialogOpen: false,
  isEditUserDialogOpen: false,
  isDeleteUserDialogOpen: false,
  newCreatedUser: '',
  deletedUser: '',
  deleteAccountError: false,
  resendEmailAdressess: '',

  userAlertDialogOpen: false,
  userAlertDialogTitle: '',
  userAlertDialogMessage: '',
};

const actionMappings = {
  [SET_USER_MANAGEMENT_DATA_LOADING]: '_setUserManagementDataLoading',
  [FETCH_USER_MANAGEMENT_DATA_FAIL]: '_fetchUserManagementDataFail',
  [FETCH_USER_MANAGEMENT_DATA_SUCCESS]: '_fetchUserManagementDataSuccess',
  [TOGGLE_USER_SELECT]: '_toggleUserSelect',
  [TOGGLE_ALL_USERS_SELECT]: '_toggleAllUsersSelect',
  [SEARCH_ALL_USERS]: '_searchAllUsers',
  [SET_CUSTOMERS_DATA_LOADING]: '_setCustomersDataLoading',
  [FETCH_CUSTOMERS_DATA_FAIL]: '_fetchCustomersDataFail',
  [FETCH_CUSTOMERS_DATA_SUCCESS]: '_fetchCustomersDataSuccess',
  [OPEN_CREATE_ACCOUNT_MODAL]: '_openCreateAccountModal',
  [OPEN_CREATE_NEW_USER_DIALOG]: '_openCreateUserDialog',
  [OPEN_EDIT_USER_DIALOG]: '_openEditUserDialog',
  [OPEN_DELETE_USER_DIALOG]: '_openDeleteUserDialog',
  [SET_EMAIL_ADDRESS]: '_setEmailAddress',
  [SET_SELECTED_USER_TYPES]: '_setSelectedUserTypes',
  [SET_SELECTED_CUSTOMERS]: '_setSelectedCustomers',
  [SET_SELECTED_GO_CUSTOMERS]: '_setSelectedGOCustomers',
  [SET_SELECTED_ADMIN_CUSTOMERS]: '_setSelectedAdminCustomers',
  [SET_DISPLAY_CUSTOMERS]: '_setDisplayCustomers',
  [SET_DISPLAY_GO_CUSTOMERS]: '_setDisplayGOCustomers',
  [SET_DISPLAY_ADMIN_CUSTOMERS]: '_setDisplayAdminCustomers',
  [CREATE_USER_BEGIN]: '_createUserBegin',
  [CREATE_USER_SUCCESS]: '_createUserSuccess',
  [CREATE_USER_FAIL]: '_createUserFail',
  [DELETE_USER_BEGIN]: '_deleteUserBegin',
  [DELETE_USER_SUCCESS]: '_deleteUserSuccess',
  [DELETE_USER_FAIL]: '_deleteUserFail',
  [CLOSE_RESEND_EMAIL_MODAL]: '_closeResendEmailModal',
  [SET_RESEND_EMAIL_TYPE]: '_setResendEmailType',
  [RESEND_EMAIL_BEGIN]: '_resendEmailBegin',
  [RESEND_EMAIL_SUCCESS]: '_resendEmailSuccess',
  [RESEND_EMAIL_FAIL]: '_resendEmailFail',
  [SET_EDIT_USER_MODE]: '_setEditUserMode',
  [DISABLE_USER]: '_disableUser',
  [OPEN_USER_ALERT_DIALOG_BOX]: '_openUserAlertDialogBox',
  [CLOSE_USER_ALERT_DIALOG_BOX]: '_closeUserAlertDialogBox',
};

const reducer = {
  _setUserManagementDataLoading(state, { loading }) {
    return {
      ...state,
      loading,
    };
  },
  _fetchUserManagementDataFail(state, { error }) {
    return {
      ...state,
      error,
    };
  },
  _fetchUserManagementDataSuccess(state, { users }) {
    return {
      ...state,
      users,
      filteredUsers: users,
    };
  },
  _toggleUserSelect(state, { user }) {
    let selectedUsers;
    const userExists = state.selectedUsers.some(selected => selected.email === user.email);

    if (userExists) {
      selectedUsers = state.selectedUsers.filter(curr => curr.email !== user.email);
    } else {
      selectedUsers = [...state.selectedUsers, user];
    }

    return {
      ...state,
      selectedUsers,
    };
  },
  _toggleAllUsersSelect(state, { event }) {
    let selectedUsers;
    if (event?.target?.checked) {
      selectedUsers = state.users;
    } else selectedUsers = [];
    return {
      ...state,
      selectedUsers,
    };
  },
  _searchAllUsers(state, { keyword }) {
    const filteredUsers = state.users.filter(user => user.email.includes(keyword));
    return {
      ...state,
      filteredUsers,
    };
  },
  _setCustomersDataLoading(state, { customersDataLoading }) {
    return {
      ...state,
      customersDataLoading,
    };
  },
  _fetchCustomersDataFail(state, { customersDataError }) {
    return {
      ...state,
      customersDataError,
    };
  },
  _fetchCustomersDataSuccess(state, { customers }) {
    return {
      ...state,
      customers,
    };
  },
  _setEmailAddress(state, { emailAddress, emailAddressError }) {
    return {
      ...state,
      emailAddress,
      emailAddressError,
    };
  },
  _setSelectedUserTypes(state, { selectedUserTypes }) {
    return {
      ...state,
      selectedUserTypes,
    };
  },
  _setSelectedCustomers(state, { selectedCustomers }) {
    return {
      ...state,
      selectedCustomers,
    };
  },
  _setSelectedGOCustomers(state, { selectedGOCustomers }) {
    return {
      ...state,
      selectedGOCustomers,
    };
  },
  _setSelectedAdminCustomers(state, { selectedAdminCustomers }) {
    return {
      ...state,
      selectedAdminCustomers,
    };
  },
  _setDisplayCustomers(state, { displayCustomers }) {
    return {
      ...state,
      displayCustomers,
    };
  },
  _setDisplayGOCustomers(state, { displayGOCustomers }) {
    return {
      ...state,
      displayGOCustomers,
    };
  },
  _setDisplayAdminCustomers(state, { displayAdminCustomers }) {
    return {
      ...state,
      displayAdminCustomers,
    };
  },
  _createUserBegin(state) {
    return {
      ...state,
      createAccountError: '',
      isCreatingAccount: true,
    };
  },
  _openCreateUserDialog(state, { newCreatedUser }) {
    return {
      ...state,
      isCreateUserDialogOpen: !state.isCreateUserDialogOpen,
      newCreatedUser,
    };
  },
  _openEditUserDialog(state, { newCreatedUser }) {
    return {
      ...state,
      isEditUserDialogOpen: !state.isEditUserDialogOpen,
      newCreatedUser,
    };
  },
  _openDeleteUserDialog(state, { deletedUser, deleteAccountError }) {
    return {
      ...state,
      isDeleteUserDialogOpen: !state.isDeleteUserDialogOpen,
      deletedUser,
      deleteAccountError,
    };
  },
  _createUserSuccess(state) {
    return {
      ...state,
      emailAddress: '',
      emailAddressError: '',
      selectedUserTypes: [],
      selectedCustomers: [],
      selectedGOCustomers: [],
      selectedAdminCustomers: [],
      displayCustomers: false,
      displayGOCustomers: false,
      displayAdminCustomers: false,
      createAccountError: '',
      isCreatingAccount: false,
      showCreateAccountModal: false,
      initialSelectedUserTypes: [],
      initialSelectedCustomers: [],
      initialSelectedGOCustomers: [],
      initialSelectedAdminCustomers: [],
      isEditMode: false,
    };
  },
  _createUserFail(state, { error }) {
    return {
      ...state,
      createAccountError: error,
      isCreatingAccount: false,
    };
  },
  _deleteUserBegin(state) {
    return {
      ...state,
      deleteUserError: null,
      loading: true,
    };
  },
  _deleteUserSuccess(state) {
    return {
      ...state,
      deleteUserError: null,
      loading: false,
    };
  },
  _deleteUserFail(state) {
    return {
      ...state,
      deleteUserError: true,
      loading: false,
    };
  },
  _openCreateAccountModal(state) {
    return {
      ...state,
      showCreateAccountModal: true,
    };
  },
  _closeCreateAccountModal(state) {
    return {
      ...state,
      emailAddress: '',
      emailAddressError: '',
      selectedUserTypes: [],
      selectedCustomers: [],
      selectedGOCustomers: [],
      selectedAdminCustomers: [],
      displayCustomers: false,
      displayGOCustomers: false,
      displayAdminCustomers: false,
      showCreateAccountModal: false,
      createAccountError: '',
      initialSelectedUserTypes: [],
      initialSelectedCustomers: [],
      initialSelectedGOCustomers: [],
      initialSelectedAdminCustomers: [],
      isEditMode: false,
    };
  },
  _setEditUserMode(state, { emailAddress, selectedUserTypes, selectedCustomers, selectedGOCustomers, selectedAdminCustomers, isEditMode, user }) {
    return {
      ...state,
      emailAddress,
      selectedUserTypes,
      selectedCustomers,
      selectedGOCustomers,
      selectedAdminCustomers,
      displayCustomers: selectedCustomers.length !== 0,
      displayGOCustomers: selectedGOCustomers.length !== 0,
      displayAdminCustomers: selectedAdminCustomers.length !== 0,
      initialSelectedUserTypes: selectedUserTypes,
      initialSelectedCustomers: selectedCustomers,
      initialSelectedGOCustomers: selectedGOCustomers,
      initialSelectedAdminCustomers: selectedAdminCustomers,
      isEditMode,
      showCreateAccountModal: true,
      selectedUserInformation: user,
    };
  },
  _openResendEmailModal(state) {
    return {
      ...state,
      showResendEmailModal: true,
      createAccountError: '',
    };
  },
  _closeResendEmailModal(state) {
    return {
      ...state,
      showResendEmailModal: false,
      resendEmailSuccess: false,
      resendEmailError: '',
      isSendingEmail: false,
    };
  },
  _setResendEmailType(state, { emailType }) {
    return {
      ...state,
      resendEmailType: emailType,
    };
  },
  _resendEmailBegin(state) {
    return {
      ...state,
      resendEmailError: '',
      isSendingEmail: true,
    };
  },
  _resendEmailSuccess(state, { emails }) {
    return {
      ...state,
      resendEmailError: '',
      isSendingEmail: false,
      resendEmailSuccess: true,
      resendEmailAdressess: emails,
    };
  },
  _resendEmailFail(state, { error }) {
    return {
      ...state,
      resendEmailError: error,
      isSendingEmail: false,
      resendEmailSuccess: false,
    };
  },
  _disableUser(state, { wasSuccess }) {
    return {
      ...state,
      disableUserFail: !wasSuccess,
    };
  },
  _openUserAlertDialogBox(state, { title, message }) {
    return {
      ...state,
      userAlertDialogOpen: true,
      userAlertDialogTitle: title,
      userAlertDialogMessage: message,
    };
  },
  _closeUserAlertDialogBox(state) {
    return {
      ...state,
      userAlertDialogOpen: false,
    };
  },
};

const userReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default userReducer;
