import {
  FETCH_FLEET_STATUS_DATA_FAIL,
  RECEIVE_FLEET_STATUS_DATA,
  TOGGLE_FLEET_STATUS_LOADING,
  TOGGLE_FLEET_STATUS_FILTER,
  TOGGLE_HIDE_FIELDS_DROPDOWN,
  TOGGLE_FILTER_BY_FAULTS_DROPDOWN,
  TOGGLE_HIDE_FIELD_FILTER,
  SET_BATCH_COMMAND_SCHEDULE,
} from '../constants';
import fleetStatusAttributes from '../constants/enums/fleetStatusAttributes';
import fleetStatusFaultFilters from '../constants/enums/fleetStatusFaultFilters';

const initialState = {
  allTrucks: [],
  filteredAllTrucks: [],
  filteredData: [],
  data: [],
  error: false,
  isLoading: false,
  fleetStatusFilters: Object.values(fleetStatusFaultFilters),
  fleetStatusColumns: Object.values(fleetStatusAttributes),
  hideFieldsDropdown: false,
  filterByFaultsDropdown: false,
  batchCommandSchedule: null,
};

const actionMappings = {
  [RECEIVE_FLEET_STATUS_DATA]: '_receiveData',
  [TOGGLE_FLEET_STATUS_LOADING]: '_toggleLoading',
  [FETCH_FLEET_STATUS_DATA_FAIL]: '_setFailureState',
  [TOGGLE_FLEET_STATUS_FILTER]: '_toggleFleetStatusFilter',
  [TOGGLE_HIDE_FIELDS_DROPDOWN]: '_toggleHideFieldsDropdown',
  [TOGGLE_FILTER_BY_FAULTS_DROPDOWN]: '_toggleFilterByFaultsDropdown',
  [TOGGLE_HIDE_FIELD_FILTER]: '_toggleHideFieldFilter',
  [SET_BATCH_COMMAND_SCHEDULE]: '_setBatchCommandSchedule',
};

const reducer = {
  _receiveData(state, { payload: { data } }) {
    return {
      ...state,
      data,
      filteredData: data,
      filteredAllTrucks: data,
      allTrucks: data,
    };
  },
  _toggleLoading(state) {
    return {
      ...state,
      isLoading: !state.isLoading,
    };
  },

  _setFailureState(state) {
    return {
      ...state,
      error: true,
    };
  },
  _toggleFleetStatusFilter(state, { payload }) {
    const { filter, value } = payload;
    const filters = state.fleetStatusFilters.map(_filter => {
      if (_filter.type.key === filter.type.key) {
        _filter.active = value;
        filter.active = value;
      } else _filter.active = false;

      return _filter;
    });
    return {
      ...state,
      fleetStatusFilters: filters,
    };
  },
  _toggleHideFieldsDropdown(state) {
    return {
      ...state,
      hideFieldsDropdown: !state.hideFieldsDropdown,
      filterByFaultsDropdown: false,
    };
  },
  _toggleFilterByFaultsDropdown(state) {
    return {
      ...state,
      filterByFaultsDropdown: !state.filterByFaultsDropdown,
      hideFieldsDropdown: false,
    };
  },
  _toggleHideFieldFilter(state, { payload }) {
    const { value, column } = payload;

    const filteredColumns = state.fleetStatusColumns.map(col => {
      if (col.title === column.title) {
        col.isColumnHidden = value;
      }

      return col;
    });
    return {
      ...state,
      fleetStatusColumns: filteredColumns,
    };
  },
  _setBatchCommandSchedule(state, { payload }) {
    return {
      ...state,
      batchCommandSchedule: payload,
    };
  },
};

const currentFleetStatus = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default currentFleetStatus;
