import React from 'react';
import PropTypes from 'prop-types';
import Switch from '../../Switch/Switch';

function UserEnabledCustomComponent({ data, onToggleActiveState }) {
  const userRoleTypesArray = data?.roleType?.split(', ')?.map(role => ({ name: role })) ?? [];
  const style = {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (
    <div style={style}>
      <Switch onChange={checked => onToggleActiveState(checked, { ...data, roles: userRoleTypesArray })} checked={data?.enabled} icon="checkicon" />
    </div>
  );
}

UserEnabledCustomComponent.propTypes = {
  data: PropTypes.shape({
    roleType: PropTypes.string,
    enabled: PropTypes.bool,
  }),
  onToggleActiveState: PropTypes.func.isRequired,
};

UserEnabledCustomComponent.defaultProps = {
  data: {
    roleType: '',
    enabled: false,
  },
};

export default UserEnabledCustomComponent;
