export default {
  ALERT: {
    id: 1,
    name: 'alert',
    key: 'ALERT',
    pathPattern: '/alertconfiguration/alert/([0-9a-f-]+)$',
    shortPath: '/alertconfiguration/alert',
  },
  YARD: {
    id: 2,
    name: 'yard',
    key: 'YARD',
    pathPattern: '/alertconfiguration/yard/([0-9a-f-]+)$',
    shortPath: '/alertconfiguration/yard',
  },
  DWELL: {
    id: 3,
    name: 'dwell',
    key: 'DWELL',
    pathPattern: '/alertconfiguration/dwell/([0-9a-f-]+)$',
    shortPath: '/alertconfiguration/dwell',
  },
  ABS_SUMMARY: {
    id: 4,
    name: 'absSummary',
    key: 'ABS_SUMMARY',
    pathPattern: '/alertconfiguration/absSummary/([0-9a-f-]+)$',
    shortPath: '/alertconfiguration/absSummary',
  },
};

export const durationTimeTypes = [
  {
    label: 'minutes',
    value: 'minutes',
  },
  {
    label: 'hours',
    value: 'hours',
  },
  {
    label: 'days',
    value: 'days',
  },
];

export const geofenceBoundaryEnterExitTypes = [
  {
    label: 'Enter/Exit',
    value: 'EnterExit',
  },
  {
    label: 'Enter',
    value: 'Enter',
  },
  {
    label: 'Exit',
    value: 'Exit',
  },
];
