export default {
  MONDAY: {
    id: 0,
    name: 'Monday',
    key: 'MONDAY',
    dayOfWeek: 'Mon',
  },
  TUESDAY: {
    id: 1,
    name: 'Tuesday',
    key: 'TUESDAY',
    dayOfWeek: 'Tue',
  },
  WEDNESDAY: {
    id: 2,
    name: 'Wednesday',
    key: 'WEDNESDAY',
    dayOfWeek: 'Wed',
  },
  THURSDAY: {
    id: 3,
    name: 'Thursday',
    key: 'THURSDAY',
    dayOfWeek: 'Thu',
  },
  FRIDAY: {
    id: 4,
    name: 'Friday',
    key: 'FRIDAY',
    dayOfWeek: 'Fri',
  },
  SATURDAY: {
    id: 5,
    name: 'Saturday',
    key: 'SATURDAY',
    dayOfWeek: 'Sat',
  },
  SUNDAY: {
    id: 6,
    name: 'Sunday',
    key: 'SUNDAY',
    dayOfWeek: 'Sun',
  },
};
