import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import PlusBoxOutlineIcon from 'mdi-react/PlusBoxOutlineIcon';
import MinusBoxOutlineIcon from 'mdi-react/MinusBoxOutlineIcon';
import './MultiSelectTreeView.css';
import cloneDeep from 'lodash/cloneDeep';
import Checkbox from '../Checkbox/Checkbox';

function TreeNode({ data, node, onChange, level = 0, disable = false }) {
  const [expanded, setExpanded] = useState(node.expanded);

  const toggleExpanded = useCallback(() => {
    setExpanded(e => !e);
  }, []);

  const toggleSelected = useCallback(() => {
    node.selected = !node.selected;
    node.indeterminate = false;
    onChange(cloneDeep(data), node);
  }, [node, onChange, data]);

  const hasChildren = useMemo(() => node.children?.length > 0, [node.children]);

  return (
    <div>
      <div className="node" style={{ paddingLeft: 24 * level + 'px', alignItems: 'center' }}>
        <div onClick={toggleExpanded} className={hasChildren ? '' : 'hidden'}>
          {expanded ? <MinusBoxOutlineIcon size={18} cursor="pointer" /> : <PlusBoxOutlineIcon size={18} cursor="pointer" />}
        </div>
        <Checkbox
          checked={node.selected}
          indeterminate={node.indeterminate}
          disabled={disable || node.disabled}
          onChange={toggleSelected}
          data-name={node.name}
          labelRight={node.name}
        />
      </div>
      {expanded && node.children && (
        <div>
          {node.children.map(n => (
            <TreeNode key={n.id} data={data} node={n} onChange={onChange} level={level + 1} disable={disable || node.selected} />
          ))}
        </div>
      )}
    </div>
  );
}

function MultiSelectTreeView({ data, onChange }) {
  return (
    <div className="tree-view">
      {data?.map(node => (
        <TreeNode key={node.id} data={data} node={node} onChange={onChange} />
      ))}
    </div>
  );
}

MultiSelectTreeView.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiSelectTreeView;
