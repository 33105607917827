export const REEFER_UNIT_STATUS = {
  OFF: 'Off',
  ON: 'On',
  CYCLE_SENTRY: 'Cycle Sentry',
  CONTINUOUS: 'Continuous',
};

export const REEFER_UNIT_MODE = {
  OFF: {
    key: 'OFF',
    value: REEFER_UNIT_STATUS.OFF,
  },
  ON: {
    key: 'ON',
    value: REEFER_UNIT_STATUS.ON,
  },
  CONTINUOUS: {
    key: 'CONTINUOUS',
    value: REEFER_UNIT_STATUS.CONTINUOUS,
  },
  CYCLE_SENTRY: {
    key: 'CYCLE_SENTRY',
    value: REEFER_UNIT_STATUS.CYCLE_SENTRY,
  },
};

export const COMMAND_STATUS = {
  IN_PROGRESS: 'In Progress',
  FAILED: 'Failed',
  SUCCESS: 'Success',
};

export const tkModeData = {
  [REEFER_UNIT_MODE.OFF.value]: 'remoteOff',
  [REEFER_UNIT_MODE.ON.value]: 'remoteOn',
  [REEFER_UNIT_MODE.CONTINUOUS.value]: 'enterContinuousMode',
  [REEFER_UNIT_MODE.CYCLE_SENTRY.value]: 'enterCycleSentryMode',
};

export const commandTypes = {
  updateSetPointBatch: 'updateSetPointBatch',
  clearAlarmsBatch: 'clearAlarmsBatch',
  enterContinuousModeBatch: 'enterContinuousModeBatch',
  enterCycleSentryModeBatch: 'enterCycleSentryModeBatch',
  remoteOffBatch: 'remoteOffBatch',
  remoteOnBatch: 'remoteOnBatch',
};

export const displayCommandTypes = {
  updateSetPointBatch: 'Set Point',
  clearAlarmsBatch: 'Clear All Faults',
  enterContinuousModeBatch: 'Continuous Mode',
  enterCycleSentryModeBatch: 'Cycle Sentry Mode',
  remoteOffBatch: 'Remote Off',
  remoteOnBatch: 'Remote On',
};
