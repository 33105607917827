import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '../../assets/public/Check.png';
import CloseIcon from '../../assets/public/Close.png';
import cx from 'classnames';
import './Switch.css';
import ReactSwitch from 'react-switch';

function Switch({ labelLeft, labelRight, description, onChange, icon, size, disabled, checked, id }) {
  const [hover, setHover] = useState(false);
  const sizeMedium = size === 'medium';
  const switchIcon = {
    checkicon: CheckIcon,
    xicon: CloseIcon,
  };

  const switchLabel = label => {
    return (
      <div className="switch-label-container">
        <div
          className={cx('switch-label', {
            [`switch-label-${size}`]: sizeMedium,
            [`switch-label-unchecked `]: !checked,
          })}
        >
          {label}
        </div>
        {description && (
          <div
            className={cx('switch-description', {
              [`switch-description-${size}`]: sizeMedium,
            })}
          >
            {description}
          </div>
        )}
      </div>
    );
  };

  const switchHandleIcon = () => {
    if (!icon) return;
    return (
      <span className="checked-handle-icon">
        <img src={switchIcon[icon]} alt="switchHandleIcon" width={sizeMedium ? 16 : 10} height={sizeMedium ? 16 : 10} />
      </span>
    );
  };

  return (
    <label
      htmlFor={id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={cx('switch-container', { [`switch-container-disabled`]: disabled })}
    >
      {labelLeft && switchLabel(labelLeft)}
      <ReactSwitch
        disabled={disabled}
        handleDiameter={sizeMedium ? 24 : 12}
        uncheckedIcon={false}
        checkedIcon={false}
        checkedHandleIcon={switchHandleIcon()}
        height={sizeMedium ? 28 : 16}
        width={sizeMedium ? 50 : 32}
        checked={checked}
        onColor={hover && !disabled ? '#0628CD' : '#5690FF'}
        offColor={hover && !disabled ? '#95A6AA' : '#AEBBC0'}
        onChange={onChange}
        id={id}
      />
      {labelRight && switchLabel(labelRight)}
    </label>
  );
}

Switch.propTypes = {
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Switch;
