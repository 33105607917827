import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Checkbox.css';
import CheckboxMUI from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

function Checkbox({ labelLeft, labelRight, description, onChange, size = 'medium', disabled, checked, id, indeterminate }) {
  const checkboxIconSize = {
    small: 10,
    medium: 12,
    large: 16,
  };

  const checkboxLabel = label => {
    return (
      <div className="checkbox-label-container">
        <div
          className={cx(`checkbox-label-${size}`, {
            [`checkbox-label-checked`]: checked,
          })}
        >
          {label}
        </div>
        {description && <div className={`checkbox-description-${size}`}>{description}</div>}
      </div>
    );
  };

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    icon: {
      'borderRadius': 3,
      'width': checkboxIconSize[size],
      'height': checkboxIconSize[size],
      'border': '2px solid #7C9098',
      'backgroundColor': 'transparent',
      'input:hover ~ &': {
        backgroundColor: 'transparent',
        border: '2px solid #000',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        border: '2px solid #7C909830',
        background: 'transparent',
      },
    },
    checkedIcon: {
      'borderRadius': 3,
      'backgroundColor': 'var(--blue-brand-400)',
      'backgroundImage': 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: checkboxIconSize[size],
        height: checkboxIconSize[size],
        border: '2px solid #7C909830',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 7'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M7.37037 1.51354L6.52346 0.666626L2.53087 4.65922L0.958028 3.08638L0.111115 3.93329L2.53087 6.35305L7.37037 1.51354Z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#102C9F',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        backgroundColor: '#2F65FF30',
      },
    },
    indeterminateIcon: {
      'borderRadius': 3,
      'backgroundColor': 'var(--blue-brand-400)',
      'backgroundImage': 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: checkboxIconSize[size],
        height: checkboxIconSize[size],
        border: '2px solid #7C909830',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 7'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='m 7.4300624,4.1607826 2.8e-6,-1.1977145 c 0,0 -6.84929282,-0.0058 -6.84409292,-6.81e-5 l -3.53e-6,1.1977896 z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#102C9F',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        backgroundColor: '#2F65FF30',
      },
    },
  });
  const classes = useStyles();
  const checkboxStyle = { padding: 0, margin: '4px' };

  return (
    <label htmlFor={id} className={cx('checkbox-container', { [`checkbox-container-disabled`]: disabled })}>
      {labelLeft && checkboxLabel(labelLeft)}
      <CheckboxMUI
        disableRipple
        style={checkboxStyle}
        indeterminate={indeterminate}
        checkedIcon={<span className={classes.checkedIcon} />}
        indeterminateIcon={<span className={classes.indeterminateIcon} />}
        icon={<span className={classes.icon} />}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        id={id}
        color="default"
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        classes={{ root: classes.root }}
      />
      {labelRight && checkboxLabel(labelRight)}
    </label>
  );
}

Checkbox.propTypes = {
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
