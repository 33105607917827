export const RoleNames = {
  ADMINS: {
    name: 'ADMINS',
    regex: RegExp(/^\s*ADMINS?\s*$/i),
  },
  CUSTOMER: {
    name: 'CUSTOMER',
    regex: RegExp(/^\s*CUSTOMER_?([0-9]+)?\s*$/i),
  },
  CUSTOMER_ADMIN: {
    name: 'CUSTOMER_ADMIN',
    regex: RegExp(/^\s*CUSTOMER_ADMIN_?([0-9]+)?\s*$/i),
  },
  INSTALLER: {
    name: 'INSTALLER',
    regex: RegExp(/^\s*INSTALLERS?\s*$/i),
  },
  TECHNICIAN: {
    name: 'TECHNICIAN',
    regex: RegExp(/^\s*TECHNICIANS?\s*$/i),
  },
  ALL_CUSTOMER_VIEW: {
    name: 'ALL_CUSTOMER_VIEW',
    regex: RegExp(/^\s*ALL_CUSTOMER_VIEW?\s*$/i),
  },
  GO_INSTALLER_CUSTOMER: {
    name: 'GO_INSTALLER_CUSTOMER',
    regex: RegExp(/^\s*GO_INSTALLER_CUSTOMER?\s*$/i),
  },
  GO_INSTALLER_ALL_CUSTOMERS: {
    name: 'GO_INSTALLER_ALL_CUSTOMERS',
    regex: RegExp(/^\s*GO_INSTALLER_ALL_CUSTOMERS?\s*$/i),
  },
  SUPPORT: {
    name: 'SUPPORT',
    regex: RegExp(/^\s*SUPPORT?\s*$/i),
  },
  DEALER: {
    name: 'DEALER',
    regex: RegExp(/^\s*DEALER?\s*$/i),
  },
  DEVELOPER: {
    name: 'DEVELOPER',
    regex: RegExp(/^\s*DEVELOPER?\s*$/i),
  },
  VIEW_ONLY_ADMIN: {
    name: 'VIEW_ONLY_ADMIN',
    regex: RegExp(/^\s*VIEW_ONLY_ADMIN?\s*$/i),
  }
};
