import { TOGGLE_OVERLAY_BAR } from '../constants';

const initialState = {
    expandedOverlayBar: false,
};

const actionMappings = {
  [TOGGLE_OVERLAY_BAR]: '_toggleOverlayBar',
};

const reducer = {
    _toggleOverlayBar(state) {
    return {
      expandedOverlayBar: !state.expandedOverlayBar,
    };
  },
};

const overlayBarReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default overlayBarReducer;
