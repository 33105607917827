export const SENSOR_BOX_TYPES = {
  PRO: {
    id: 1,
    longName: 'Morey',
    shortName: 'Morey',
    type: 'FleetPulse Pro',
    deviceType: 'Pro',
  },
  BASE: {
    id: 2,
    longName: 'Base',
    shortName: 'Base',
    type: 'FleetPulse Base',
    deviceType: 'Base',
  },
  GO: {
    id: 3,
    shortName: 'Xirgo',
    longName: 'Xirgo',
    type: 'FleetPulse Go',
    deviceType: 'Go',
  },
  CARGO_CAMERA: {
    id: 4,
    shortName: 'Xirgo Cargo Camera',
    longName: 'Cargo Camera',
    type: 'Cargo Camera',
    deviceType: 'Cargo Camera',
  },
  XT5300: {
    id: 5,
    shortName: 'XT5300',
    longName: 'Sensata XT5300',
    type: 'XT5300',
    deviceType: 'XT5300',
  },
  SLAP_N_TRACK: {
    id: 6,
    shortName: 'SlapNTrack',
    longName: 'Morey MachineLink Slap-N-Track',
    type: 'SlapNTrack',
    deviceType: 'SlapNTrack',
  },
};

export const getDeviceType = deviceTypeId => {
  const deviceType = Object.values(SENSOR_BOX_TYPES)?.find(mfgr => mfgr.id === deviceTypeId);
  return deviceType?.type ?? 'N/A';
};

export const getDeviceTypeName = deviceTypeId => {
  const deviceType = Object.values(SENSOR_BOX_TYPES)?.find(mfgr => mfgr.id === deviceTypeId);
  return deviceType?.deviceType ?? 'N/A';
};

export const getDeviceTypeDropdownOptions = () => Object.values(SENSOR_BOX_TYPES).map(mfgr => mfgr.type);
