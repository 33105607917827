export const UserTypes = {
  ADMINISTRATOR: {
    userType: 'Administrator',
    typeName: 'ADMINS',
    canBeCreatedBy: ['ADMINS'],
    regEx: new RegExp('^ADMINS$'),
  },
  ALL_CUSTOMER_VIEW: {
    userType: 'All Customer View',
    typeName: 'ALL_CUSTOMER_VIEW',
    canBeCreatedBy: ['ADMINS'],
    regEx: new RegExp('^ALL_CUSTOMER_VIEW$'),
  },
  CUSTOMER: {
    userType: 'Customer',
    typeName: 'CUSTOMER',
    canBeCreatedBy: ['ADMINS'],
    rolePattern: 'CUSTOMER_',
    regEx: new RegExp('CUSTOMER_(\\d+)'),
  },
  CUSTOMER_ADMIN: {
    userType: 'Customer Admin',
    typeName: 'CUSTOMER_ADMIN',
    canBeCreatedBy: ['ADMINS'],
    rolePattern: 'CUSTOMER_ADMIN_',
    regEx: new RegExp('CUSTOMER_ADMIN_(\\d+)'),
  },
  TECHNICIAN: {
    userType: 'Technician',
    typeName: 'TECHNICIAN',
    canBeCreatedBy: ['ADMINS'],
    regEx: new RegExp('^TECHNICIAN$'),
  },
  INSTALLER: {
    userType: 'Installer',
    typeName: 'INSTALLER',
    canBeCreatedBy: ['ADMINS'],
    regEx: new RegExp('^INSTALLER$'),
  },
  STANDARD_USER: {
    userType: 'Standard User',
    typeName: 'CUSTOMER',
    canBeCreatedBy: ['CUSTOMER_ADMIN'],
    regEx: new RegExp('CUSTOMER_(\\d+)'),
  },
  ACCOUNT_ADMINISTRATOR: {
    userType: 'Account Administrator',
    typeName: 'CUSTOMER_ADMIN',
    canBeCreatedBy: ['CUSTOMER_ADMIN'],
    regEx: new RegExp('CUSTOMER_ADMIN_(\\d+)'),
  },
  GO_INSTALLER_ALL_CUSTOMER: {
    userType: 'Go Installer All Customers',
    typeName: 'GO_INSTALLER_ALL_CUSTOMERS',
    canBeCreatedBy: ['ADMINS'],
    regEx: new RegExp('^GO_INSTALLER_ALL_CUSTOMERS$'),
  },
  GO_INSTALLER: {
    userType: 'Go Installer Customer',
    typeName: 'GO_INSTALLER_CUSTOMER',
    canBeCreatedBy: ['ADMINS', 'CUSTOMER_ADMIN'],
    regEx: new RegExp('^GO_INSTALLER_CUSTOMER$'),
  },
  SUPPORT: {
    userType: 'Support',
    typeName: 'SUPPORT',
    canBeCreatedBy: ['ADMINS'],
    regEx: new RegExp('^SUPPORT$'),
  },
  DEALER: {
    userType: 'Dealer',
    typeName: 'DEALER',
    canBeCreatedBy: ['ADMINS'],
    regEx: new RegExp('^DEALER$'),
  },
  VIEW_ONLY_ADMIN: {
    userType: 'View Only Admin',
    typeName: 'VIEW_ONLY_ADMIN',
    canBeCreatedBy: ['ADMINS'],
    regEx: new RegExp('^VIEW_ONLY_ADMIN$'),
  },

};

export const findUserTypePatternBasedOnTypeName = typeName => {
  const foundedUser = Object.values(UserTypes).find(user => user.typeName === typeName);
  return foundedUser?.regEx ?? {};
};

export const findCustomerAdminFilteringOptions = () =>
  Object.values(UserTypes)
    .filter(it => it.canBeCreatedBy?.some(ut => ut === UserTypes.CUSTOMER_ADMIN.typeName))
    .map(user => user.userType);
