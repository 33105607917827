import _get from 'lodash/get';
import {
  SET_ACTIVITY_LOG_DATA_LOADING,
  FETCH_ACTIVITY_LOG_DATA_FAIL,
  FETCH_ACTIVITY_LOG_DATA_SUCCESS,
  SET_ACTIVITY_LOG_PAGE_NUMBER,
  INCREMENT_ACTIVITY_LOG_PAGE_NUMBER,
  SET_ACTIVITY_LOG_ADDITIONAL_DATA_LOADING,
  FETCH_ACTIVITY_LOG_ADDITIONAL_DATA_SUCCESS,
  FETCH_ACTIVITY_LOG_ADDITIONAL_DATA_FAIL,
} from '../constants';
import navigationDrawerSensors from '../constants/enums/navigationDrawerSensors';
import { getActivityLogDataUtil } from '../utils/getActivityLogDataUtil';
import { ACTIVITY_LOG_DEFAULT_PAGE_SIZE, SENSOR_TYPE_DEFAULT_PAGE_SIZE } from '../api/activityLogApi';

const initialState = {
  activityLogData: [],
  activityLogDataLoading: false,
  error: false,
  activityLogPageNumber: 0,
  isLoadingMore: false,
  hasMoreData: true,
};

const actionMappings = {
  [FETCH_ACTIVITY_LOG_DATA_SUCCESS]: '_fetchActivityLogDataSuccess',
  [FETCH_ACTIVITY_LOG_DATA_FAIL]: '_fetchActivityLogDataFail',
  [SET_ACTIVITY_LOG_DATA_LOADING]: '_setActivityLogDataLoading',
  [SET_ACTIVITY_LOG_PAGE_NUMBER]: '_setActivityLogPageNumber',
  [INCREMENT_ACTIVITY_LOG_PAGE_NUMBER]: '_incrementActivityLogPageNumber',
  [SET_ACTIVITY_LOG_ADDITIONAL_DATA_LOADING]: '_setActivityLogAdditionalDataLoading',
  [FETCH_ACTIVITY_LOG_ADDITIONAL_DATA_FAIL]: '_fetchActivityLogAdditionalDataFail',
  [FETCH_ACTIVITY_LOG_ADDITIONAL_DATA_SUCCESS]: '_fetchActivityLogAdditionalDataSuccess',
};

const reducer = {
  _fetchActivityLogDataSuccess(state, { activityLogData, activeSensor, features }) {
    const activityLogSensorSamples = activityLogData?.vehicleTrackingV2.activityLog ?? [];
    const formattedActivityLogData = getActivityLogDataUtil(activityLogSensorSamples, features);
    const hasMoreData =
      activeSensor !== navigationDrawerSensors.drawerFooterSensorTypesGD.ACTIVITY_LOG
        ? activityLogSensorSamples.length >= SENSOR_TYPE_DEFAULT_PAGE_SIZE
        : activityLogSensorSamples.length >= ACTIVITY_LOG_DEFAULT_PAGE_SIZE;
    return {
      ...state,
      hasMoreData,
      activityLogData: formattedActivityLogData,
    };
  },
  _fetchActivityLogDataFail(state) {
    return {
      ...state,
      error: true,
    };
  },
  _setActivityLogDataLoading(state) {
    return {
      ...state,
      activityLogDataLoading: !state.activityLogDataLoading,
    };
  },
  _setActivityLogPageNumber(state, { activityLogPageNumber }) {
    return {
      ...state,
      activityLogPageNumber,
      activityLogData: [],
    };
  },
  _incrementActivityLogPageNumber(state) {
    return {
      ...state,
      activityLogPageNumber: state.activityLogPageNumber + 1,
    };
  },
  _setActivityLogAdditionalDataLoading(state) {
    return {
      ...state,
      isLoadingMore: !state.isLoadingMore,
    };
  },
  _fetchActivityLogAdditionalDataFail(state) {
    return {
      ...state,
      error: true,
      isLoadingMore: false,
    };
  },
  _fetchActivityLogAdditionalDataSuccess(state, { activityLogData, activeSensor, features }) {
    const isZoneInfoSensor = activeSensor?.key === navigationDrawerSensors.defaultDrawerSensorTypes.REEFER_UNIT.groupingSensors.ZONE_INFO.key;
    const activityLogSensorSamples = isZoneInfoSensor
      ? _get(activityLogData, 'thermoKingSetPointActivityLog.activityLog', [])
      : _get(activityLogData, 'vehicleTrackingV2.activityLog', []);
    const hasMoreData = activityLogSensorSamples.length !== 0;
    const formattedActivityLogData = getActivityLogDataUtil(activityLogSensorSamples, features);

    return {
      ...state,
      activityLogData: state.activityLogData.concat(formattedActivityLogData),
      hasMoreData,
    };
  },
};

const activityLogReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default activityLogReducer;
