import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';

export const reorderSensors = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getKey = (value, map) => {
  if (value === undefined || map === undefined || _isEmpty(map)) {
    return '';
  }

  const sensorIdOrderArray = [...map].find(([key, val]) => val === value);
  if (sensorIdOrderArray) {
    return sensorIdOrderArray[0];
  }
};

export const orderSensors = (array, order, key) => {
  const ordersToKeys = new Map();

  order.forEach((ord, idx) => {
    ordersToKeys.set(idx, ord);
  });

  array.forEach(elem => {
    const getElementKey = getKey(Number(elem[key]), ordersToKeys);
    return (elem.sortOrder = getElementKey);
  });

  const orderedSensors = _sortBy(array, ['sortOrder']);
  return orderedSensors;
};
