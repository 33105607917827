import { MOREY_MAIN_APP_UPLOAD_ERROR, MOREY_MAIN_APP_UPLOAD_SUCCESS } from '../constants';

const initialState = {
  mainAppUploadResponse: null,
  mainAppUploadError: false,
};

const actionMappings = {
  [MOREY_MAIN_APP_UPLOAD_ERROR]: '_moreyMainAppUploadError',
  [MOREY_MAIN_APP_UPLOAD_SUCCESS]: '_moreyMainAppUploadSuccess',
};

const reducer = {
  _moreyMainAppUploadError(state, { payload: { errorMessage } }) {
    return {
      ...state,
      mainAppUploadError: true,
      errorMessage: errorMessage,
    };
  },
  _moreyMainAppUploadSuccess(state, { payload: { mainAppUploadResponse } }) {
    return {
      ...state,
      mainAppUploadResponse: mainAppUploadResponse,
      mainAppUploadError: false,
    };
  },
};

const moreyOtaMainAppUpload = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default moreyOtaMainAppUpload;
