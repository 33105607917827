export const geofenceTypes = {
  RADIUS: {
    id: 1,
    key: 'RADIUS',
    name: 'circle',
  },
  POLYGON: {
    id: 2,
    key: 'POLYGON',
    name: 'polygon',
  },
};

export const SHAPE_OPTIONS = {
  editable: true,
  draggable: true,
  strokeColor: '#0a185c',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#0a185c',
  fillOpacity: 0.35,
};

export const DRAWING_MANAGER_OPTIONS = {
  drawingControl: false,
  circleOptions: SHAPE_OPTIONS,
  polygonOptions: SHAPE_OPTIONS,
};

export const findGeofenceTypeIdByName = currentType => {
  if (!currentType) {
    return null;
  }
  const formattedTypeName = currentType?.trim()?.toLowerCase();
  const geofenceType = Object.values(geofenceTypes)?.find(gt => gt.name === formattedTypeName);
  return geofenceType?.id ?? null;
};
