import { DEVICE_CONFIG_ERROR, DEVICE_CONFIG_LOADING, DEVICE_CONFIG_SUCCESS } from '../constants';

const initialState = {
  deviceConfigVersions: null,
  deviceConfigLoading: false,
  deviceConfigError: null,
};

const actionMappings = {
  [DEVICE_CONFIG_LOADING]: '_deviceConfigLoading',
  [DEVICE_CONFIG_ERROR]: '_deviceConfigError',
  [DEVICE_CONFIG_SUCCESS]: '_deviceConfigSuccess',
};

const reducer = {
  _deviceConfigLoading(state, { payload: { deviceConfigLoading } }) {
    return {
      ...state,
      deviceConfigLoading,
    };
  },
  _deviceConfigError(state) {
    return {
      ...state,
      error: true,
    };
  },
  _deviceConfigSuccess(state, { payload: { deviceConfigVersions } }) {
    return {
      ...state,
      deviceConfigVersions: deviceConfigVersions,
      error: false,
    };
  },
};

const deviceConfigManagement = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default deviceConfigManagement;
