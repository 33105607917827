import React from 'react';
import PropTypes from 'prop-types';
import './Tab.css';

function Tab({ tabLabel, onClick, isActive, disabled, leftIcon, rightIcon, style }) {
  const tabClassname = () => {
    if (isActive) {
      return 'tab-container-active';
    }
    if (disabled) {
      return 'tab-container-disabled';
    }
    return 'tab-container';
  };

  return (
    <div style={style} className={tabClassname()} onClick={() => !disabled && onClick()}>
      {leftIcon && leftIcon}
      {tabLabel}
      {rightIcon && rightIcon}
    </div>
  );
}

Tab.propTypes = {
  tabLabel: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  style: PropTypes.object,
};

export default Tab;
