import React from 'react';
import cx from 'classnames';
import loadingSpinner from '../../assets/public/LoadingSpinner.png';
import loadingSpinnerLight from '../../assets/public/LoadingSpinnerLight.png';
import './LoadingCircle.css';

const LoadingCircle = (props: {
  optionalClassName?: string;
  light?: boolean;
  alignScreenCenter?: boolean;
  spinnerSize?: string;
  alignDivCenter?: boolean;
}): JSX.Element => (
  <div className={props.alignDivCenter ? 'loadingContainer' : ''}>
    <img
      alt="Loading"
      src={props.light ? loadingSpinnerLight : loadingSpinner}
      width={props.spinnerSize ?? '40px'}
      height={props.spinnerSize ?? '40px'}
      className={cx('loadingCircle', {
        [`${props.optionalClassName}`]: props.optionalClassName,
        [`alignScreenCenter`]: props.alignScreenCenter,
      })}
    />
  </div>
);

export default LoadingCircle;
