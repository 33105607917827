export const upgradeJobType = {
  CONFIG: 'Config',
  SCRIPT: 'Script',
  MAIN_APP: 'Main App',
  DEVICE_RESET: 'Device Reset',
};

export const createDeviceConfigFormatter = config => {
  const { translationServerLocation, applicationUpdateServerLocation, gspmApn, includeHendricksonConfig, includeServerConfig } = config;

  if (includeHendricksonConfig && !includeServerConfig) {
    return `{
      hendricksonEnabled: ${includeHendricksonConfig}
    }`;
  }

  if (!includeHendricksonConfig && includeServerConfig) {
    return `{
      translationServerLocation: "${translationServerLocation}",
      gsmApn: "${gspmApn}",
      applicationUpdateServerLocation: "${applicationUpdateServerLocation}",
    }`;
  }

  return `{
    translationServerLocation: "${translationServerLocation}",
    gsmApn: "${gspmApn}",
    applicationUpdateServerLocation: "${applicationUpdateServerLocation}",
    hendricksonEnabled: ${includeHendricksonConfig}
  }`;
};
