export const fetchCustomersApi = `{
customers {
    id
    shortName
    longName
    created
    updated
    abbrev
  }
}`;

export const fetchCustomersApiWithOption = (showDeactivatedCustomers) => {
  return `{
    customers(showDeactivated: ${showDeactivatedCustomers}) {
      id
      shortName
      longName
      created
      updated
      abbrev
    }
  }`
}
