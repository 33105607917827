import {
  RECEIVE_APP_DATA,
  FETCH_APP_DATA_FAIL,
  TOGGLE_APP_DATA_LOADING,
} from '../constants';

const initialState = {
  globalData: {},
  error: false,
  isLoading: false,
};

const actionMappings = {
  [RECEIVE_APP_DATA]: '_receiveData',
  [FETCH_APP_DATA_FAIL]: '_setFailureState',
  [TOGGLE_APP_DATA_LOADING]: '_toggleLoading',
};

const reducer = {
  _receiveData(
    state,
    {
      payload: { data },
    },
  ) {
    return {
      ...state,
      globalData: data,
    };
  },

  _toggleLoading(state) {
    return {
      ...state,
      isLoading: !state.isLoading,
    };
  },

  _setFailureState(state) {
    return {
      ...state,
      error: true,
    };
  },
};

const globalReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default globalReducer;
