// import { handleErrors } from '../utils/errorUtils';
// import { sendLogoutRequest } from '../api/loginApi';

import { CLEAR_LOGIN_DATA } from '../constants';
import { navigateToLogin } from '../utils/navigationUtils';

export const clearLoginData = () => ({
  type: CLEAR_LOGIN_DATA,
  payload: {},
});

export const logout = () => async dispatch => {
  // const resp = await sendLogoutRequest(fetch);
  // handleErrors(resp);
  // await resp.json();
  const dispatchPromise = await dispatch(clearLoginData());
  await dispatchPromise;
  window.localStorage.clear();
  navigateToLogin();
};
