import {fetchCustomersApi, fetchCustomersApiWithOption} from '../api/customersApi';

import {CUSTOMERS_ERROR, CUSTOMERS_LOADING, CUSTOMERS_SUCCESS} from '../constants';
import {handleErrors, is401Response} from '../utils/errorUtils';
import {doQuery} from '../utils/fetch';

const toggleLoading = customersLoading => ({
  type: CUSTOMERS_LOADING,
  payload: {customersLoading},
});

const fetchDataFailure = () => ({type: CUSTOMERS_ERROR});

export const receiveCustomersData = customersData => ({
  type: CUSTOMERS_SUCCESS,
  payload: {customersData},
});

export const fetchCustomersData = (showDeactivatedCustomers) => async dispatch => {
  try {
    dispatch(toggleLoading(true));
    const response = await doQuery(showDeactivatedCustomers ? fetchCustomersApiWithOption(showDeactivatedCustomers) : fetchCustomersApi);

    if (!response.ok) {
      handleErrors(response);
      dispatch(fetchDataFailure());
      !is401Response(response.status) && dispatch(toggleLoading(false));
      return;
    }

    const data = await response.json();
    dispatch(receiveCustomersData(data?.data?.customers));
    dispatch(toggleLoading(false));
  } catch (err) {
    dispatch(fetchDataFailure());
    dispatch(toggleLoading(false));
  }
};
