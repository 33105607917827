import React, { useState, useMemo, forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import userManagementAttributes from '../../../constants/enums/userManagementAttributes';
import { formatUserMngmntColumns, formatUserMngmntData } from '../../../utils/userManagementUtils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import UserEnabledCustomComponent from './UserEnabledCustomComponent';
import UserEditCustomComponent from './UserEditCustomComponent';
import UserMngmntCustomFloatingFilter from './UserMngmntCustomFloatingFilter';
import { isUserAdmin } from '../../../security/authorization';
import CustomDateComponent from '../../CurrentFleetStatus/components/CustomDateComponent';
import { findCustomerAdminFilteringOptions } from '../../../constants/enums/userTypes';
import EnableTKCustomComponent from './EnableTKCustomComponent';

const UserMngmtTable = forwardRef((props, ref) => {
  const {
    filteredUsers,
    hasCustomerAdminRole,
    setSelectedUsers,
    filtersActive,
    onToggleActiveState,
    onToggleCommandControlState,
    onClickEdit,
    selectedCustomer,
    onClickDelete,
    previousFilterModel,
    setPreviousFilterModel,
  } = props;
  const userTypeFilteringOptions = isUserAdmin() ? userManagementAttributes.TYPE.columnFilterOptions : findCustomerAdminFilteringOptions();
  const [gridApi, setGridApi] = useState(null);

  const handleEditClick = useCallback(
    data => {
      const model = ref.current.api.getFilterModel();
      setPreviousFilterModel(model);
      onClickEdit(data);
    },
    [onClickEdit, ref, setPreviousFilterModel],
  );

  const [components] = useState({
    accountStatusCustomFloatingFilter: UserMngmntCustomFloatingFilter({
      columnName: userManagementAttributes.ACCOUNT_STATUS.attributeName,
      columnIdentifier: userManagementAttributes.ACCOUNT_STATUS.customSelectIdentifierName,
      columnFilteringOptions: userManagementAttributes.ACCOUNT_STATUS.columnFilterOptions,
    }),
    emailVerifiedCustomFloatingFilter: UserMngmntCustomFloatingFilter({
      columnName: userManagementAttributes.EMAIL_VERIFIED.attributeName,
      columnIdentifier: userManagementAttributes.EMAIL_VERIFIED.customSelectIdentifierName,
      columnFilteringOptions: userManagementAttributes.EMAIL_VERIFIED.columnFilterOptions,
    }),
    roleTypeCustomFloatingFilter: UserMngmntCustomFloatingFilter({
      columnName: userManagementAttributes.TYPE.attributeName,
      columnIdentifier: userManagementAttributes.TYPE.customSelectIdentifierName,
      columnFilteringOptions: userTypeFilteringOptions,
    }),
    userEnabledCustomFloatingFilter: UserMngmntCustomFloatingFilter({
      columnName: userManagementAttributes.ENABLED.attributeName,
      columnIdentifier: userManagementAttributes.ENABLED.customSelectIdentifierName,
      columnFilteringOptions: userManagementAttributes.ENABLED.columnFilterOptions,
    }),
    userEnabled: props => <UserEnabledCustomComponent {...props} onToggleActiveState={onToggleActiveState} />,
    tkCommandControl: props => <EnableTKCustomComponent {...props} onToggleCommandControlState={onToggleCommandControlState} />,
    userRoleEdit: props => <UserEditCustomComponent {...props} onClickEdit={handleEditClick} onClickDelete={onClickDelete} />,
    agDateInput: CustomDateComponent,
  });

  const onGridReady = params => {
    setGridApi(params.api);
    if (previousFilterModel) {
      retainCustomFiltersState(params);
    }
    params.api.sizeColumnsToFit();
    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const retainCustomFiltersState = params => {
    params.api.setFilterModel(previousFilterModel);
    document.getElementById(userManagementAttributes.ENABLED.customSelectIdentifierName).value = previousFilterModel?.enabled?.filter ?? 'All';
    document.getElementById(userManagementAttributes.ACCOUNT_STATUS.customSelectIdentifierName).value = previousFilterModel?.accountStatus?.filter ?? 'All';
    document.getElementById(userManagementAttributes.EMAIL_VERIFIED.customSelectIdentifierName).value = previousFilterModel?.emailVerified?.filter ?? 'All';
    document.getElementById(userManagementAttributes.TYPE.customSelectIdentifierName).value = previousFilterModel?.roleType?.filter ?? 'All';
    params.api.onFilterChanged();
  };

  const themeStyle = isUserAdmin() && !selectedCustomer ? { height: '65.5vh', padding: '10px 0' } : { height: '70vh', padding: '10px 0' };
  const columns = useMemo(
    () =>
      Object.values(userManagementAttributes)
        .map(formatUserMngmntColumns)
        .map(col => {
          return {
            ...col,
            floatingFilter: filtersActive,
            suppressMenu: filtersActive,
          };
        }),
    [filtersActive],
  );

  const userMngmntData = (filteredUsers ?? []).map(user => formatUserMngmntData(user, hasCustomerAdminRole));

  return (
    <div className="ag-theme-alpine" style={themeStyle}>
      <AgGridReact
        rowData={userMngmntData}
        columnDefs={columns}
        components={components}
        onGridReady={onGridReady}
        rowSelection="multiple"
        suppressDragLeaveHidesColumns
        onSelectionChanged={() => {
          const selectedRows = gridApi?.getSelectedRows() ?? [];
          setSelectedUsers(selectedRows);
        }}
        ref={ref}
        suppressRowClickSelection
        enableCellTextSelection
      />
    </div>
  );
});

UserMngmtTable.propTypes = {
  filteredUsers: PropTypes.array,
  hasCustomerAdminRole: PropTypes.bool,
  setSelectedUsers: PropTypes.func.isRequired,
  filtersActive: PropTypes.bool,
  onToggleActiveState: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
};

UserMngmtTable.defaultProps = {
  filteredUsers: [],
  hasCustomerAdminRole: false,
  filtersActive: true,
  selectedCustomer: null,
};

export default UserMngmtTable;
