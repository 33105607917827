// import Cookies from 'js-cookie';
import pick from 'lodash/pick';
import isString from 'lodash/isString';

import { navigateToLogin } from './navigationUtils';
import history from '../history';
import { PAGES } from '../constants/enums/fpPagesEnum';

const errorAttributes = ['name', 'message'];

// Server
const safeError = error => (isString(error) ? error : pick(error, errorAttributes));

export const send401Error = (error, res) => res.status(401).send(safeError(error));

export const send422Error = (error, res) => res.status(422).send(safeError(error));

export const send500Error = (error, res) => res.status(500).send(safeError(error));

export const is401Response = status => status === 401;
export const is422Response = status => status === 422;
export const is500Response = status => status === 500;

// Client
export const handleErrors = async response => {
  const isPrivateRoute = Object.values(PAGES).find(it => history?.location?.pathname === it?.path)?.isPrivateRoute ?? false;
  if (!response) {
  }
  if (response.ok) {
    return response;
  }
  if (is401Response(response.status) && isPrivateRoute) {
    localStorage.clear();
    return navigateToLogin();
  }
  throw new Error(response.statusText);
};

export const handleNetworkRequestStatus = async (response, fallbackFn) => {
  if (!response) {
  }
  if (response.ok) {
    return response;
  }
  if (is401Response(response.status)) {
    localStorage.clear();
    return navigateToLogin();
  }
  console.warn('Network request failed with exception: ', response.statusText);
  fallbackFn();
};
