import { CUSTOMERS_LOADING, CUSTOMERS_ERROR, CUSTOMERS_SUCCESS } from '../constants';
import {sortCustomerDataAsc} from '../utils/userManagementUtils'

const initialState = {
  customersData: null,
  customersLoading: false,
  customersError: false,
};

const actionMappings = {
  [CUSTOMERS_LOADING]: '_customersLoading',
  [CUSTOMERS_ERROR]: '_customersError',
  [CUSTOMERS_SUCCESS]: '_customersSuccess',
};

const reducer = {
  _customersLoading(state, { payload: { customersLoading } }) {
    return {
      ...state,
      customersLoading,
    };
  },
  _customersError(state) {
    return {
      ...state,
      error: true,
    };
  },
  _customersSuccess(state, { payload: { customersData } }) {
    return {
      ...state,
      customersData:sortCustomerDataAsc(customersData),
      error: false,
    };
  },
};

const customersReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default customersReducer;
