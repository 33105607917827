import {
  RECEIVE_VEHICLE_SPECS_DETAIL_DATA,
  TOGGLE_VEHICLE_SPECS_DETAIL_DATA_LOADING,
  FETCH_VEHICLE_SPECS_DETAIL_DATA_FAIL,
} from '../constants';

const initialState = {
  vehicleSpecsDetailData: {},
  vehicleSpecsDetailDataLoading: false,
  error: false,
};

const actionMappings = {
  [RECEIVE_VEHICLE_SPECS_DETAIL_DATA]: '_receiveVehicleSpecsDetailData',
  [TOGGLE_VEHICLE_SPECS_DETAIL_DATA_LOADING]:
    '_toggleVehicleSpecsDetailDataLoading',
  [FETCH_VEHICLE_SPECS_DETAIL_DATA_FAIL]: '_fetchVehicleSpecsDetailDataFail',
};

const reducer = {
  _receiveVehicleSpecsDetailData(
    state,
    { payload: { vehicleSpecsDetailData } },
  ) {
    return {
      ...state,
      vehicleSpecsDetailData,
    };
  },
  _toggleVehicleSpecsDetailDataLoading(state, { payload: { specsDetailDataLoading } }) {
    return {
      ...state,
      vehicleSpecsDetailDataLoading: specsDetailDataLoading,
    };
  },
  _fetchVehicleSpecsDetailDataFail(state) {
    return {
      ...state,
      error: true,
    };
  },
};

const vehicleSpecsDetailDataReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default vehicleSpecsDetailDataReducer;
