import React from 'react';
import './Layout.css';
import Header from '../Header/components/Header';
import 'normalize.css';

const Layout = ({ children }) => (
  <div className="layout-root">
    <Header />
    {children}
  </div>
);

export default Layout;
