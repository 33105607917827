import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import SearchIcon from 'mdi-react/MagnifyIcon';
import '../styles/CustomFilter.css';

export default forwardRef((props, ref) => {
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel) {
        if (!parentModel) {
          inputRef.current.value = '';
        } else {
          inputRef.current.value = parentModel.filter + '';
        }
      },
    };
  });

  const onInputBoxChanged = input => {
    if (input.target.value === '') {
      props.parentFilterInstance(instance => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }

    props.parentFilterInstance(instance => {
      instance.onFloatingFilterChanged('contains', input.target.value);
    });
  };

  return (
    <div className="customFilter-searchContainer">
      <input className="customFilter-searchInput" ref={inputRef} type="text" onInput={onInputBoxChanged} />
      <div className="customFilter-searchIcon">
        <SearchIcon size={20} />
      </div>
    </div>
  );
});
