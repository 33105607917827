import _isNumber from 'lodash/isNumber';
import _ceil from 'lodash/ceil';

export const convertCelciusToFahrenheit = celcius => {
  if (!_isNumber(celcius)) return null;

  const fahrenheitValue = (celcius * 1.8 + 32)?.toFixed(0);

  if (fahrenheitValue === '-0') return 0;

  return Number(fahrenheitValue);
};

export const convertFahrenheitToCelcius = fahrenheit => {
  if (!_isNumber(fahrenheit)) return null;
  const convertToCelcius = ((fahrenheit - 32) * 5) / 9;
  return _ceil(convertToCelcius);
};

export const convertLiterToGallon = liter => {
  if (!liter) return 0;
  return Number((liter * 0.2641720524).toFixed(0));
};

export const calculatePercentage = (numerator, denominator, precision = 1) => {
  if (!numerator) return 0;
  if (!denominator) return null;
  const result = (numerator / denominator) * 100;

  return _ceil(result, precision);
};

export const isNumeric = value => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};
