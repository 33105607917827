import { API_URL } from '../config/url';

export const resetUserPasswordRequestApi = async username => {
  return fetch(`${API_URL}/auth/v1/request-password-reset`, {
    method: 'POST',
    headers: {
      'Authorization': `${window.localStorage.getItem('id_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
    }),
  });
};
