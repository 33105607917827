import _every from 'lodash/every';

export const checkPasswordValidation = (password, confirmPassword, email) => {
  const passwordMismatch = password !== confirmPassword;
  const passwordTooShort = !(password.length > 7 && confirmPassword.length > 7);

  const passwordPattern = /[-!$%^&*()_+|~=`'{}"/\\[\]:;<>?,.@#]/;
  const passwordPatternNoMatch = !(
    passwordPattern.test(password) && passwordPattern.test(confirmPassword)
  );

  const passwordlowerUpperCasePattern = /^(?=.*?[A-Z])(?=.*?[a-z])/;
  const passwordRequiresAtLeastOneLowercase = !(
    passwordlowerUpperCasePattern.test(password) &&
    passwordlowerUpperCasePattern.test(confirmPassword)
  );

  const passwordNumberPattern = /(?=.*?[0-9])/;
  const passwordRequiresAtLeastOneNumber = !(
    passwordNumberPattern.test(password) &&
    passwordNumberPattern.test(confirmPassword)
  );

  const passwordCriterias = {
    passwordPatternNoMatch,
    passwordRequiresAtLeastOneLowercase,
    passwordMismatch,
    passwordTooShort,
    passwordRequiresAtLeastOneNumber,
  };

  const isPasswordCriteriasValid = _every(passwordCriterias, value => !value);

  if (isPasswordCriteriasValid) {
    return {
      ...passwordCriterias,
      isPasswordCriteriasValid,
      formValid: true,
    };
  }

  return {
    ...passwordCriterias,
    isPasswordCriteriasValid,
    formValid: false,
  };
};
