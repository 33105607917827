import { UNSUBSCRIBE_EMAIL_NOTIFICATION, UNSUBSCRIBE_EMAIL_NOTIFICATION_FAIL, SET_UNSUBSCRIBE_EMAIL_NOTIFICATION_LOADING } from '../constants';

const initialState = {
  unsubscribeResponse: '',
  unsubscribeResponseError: false,
  unsubscribeEmailLoading: false,
};

const actionMappings = {
  [UNSUBSCRIBE_EMAIL_NOTIFICATION]: '_unsubscribeEmailNotification',
  [UNSUBSCRIBE_EMAIL_NOTIFICATION_FAIL]: '_unsubscribeEmailNotificationFail',
  [SET_UNSUBSCRIBE_EMAIL_NOTIFICATION_LOADING]: '_setUnsubscribeEmailNotificationLoading',
};

const reducer = {
  _unsubscribeEmailNotification(state, { unsubscribeResponse }) {
    return {
      ...state,
      unsubscribeResponse,
      unsubscribeEmailLoading: false,
    };
  },
  _unsubscribeEmailNotificationFail(state, { error }) {
    return {
      ...state,
      unsubscribeResponseError: error,
      unsubscribeEmailLoading: false,
    };
  },
  _setUnsubscribeEmailNotificationLoading(state, { loading }) {
    return {
      ...state,
      unsubscribeEmailLoading: loading,
    };
  },
};

const alerts = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default alerts;
