export const getDurationInMs = (value, period) => {
  const parsedValue = parseFloat(value);

  if (parsedValue <= 0) {
    return '';
  }

  if (period === 'days') {
    return parsedValue * 24 * 60 * 60000;
  }

  if (period === 'hours') {
    return parsedValue * 60 * 60000;
  }

  if (period === 'minutes') {
    return parsedValue * 60000;
  }

  return 0;
};

export const getDurationPeriodFromMs = durationInMs => {
  const totalMinutes = durationInMs / 60000;
  const hours = totalMinutes / 60;
  const extraMinutes = totalMinutes % 60;
  if (hours > 0 && extraMinutes === 0) {
    const days = hours / 24;
    const extraHours = hours % 24;
    if (days > 0 && extraHours === 0) {
      return {
        durationMs: days,
        selectedDurationType: 'days',
      };
    } else {
      return {
        durationMs: hours,
        selectedDurationType: 'hours',
      };
    }
  } else {
    return {
      durationMs: totalMinutes,
      selectedDurationType: 'minutes',
    };
  }
};
