import styles from '../schemas/googleMapStyles';
import clusterIcon from '../assets/public/m1.png';

export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const googleMapConfig = {
  gridSize: 25,
  clusterIcon: [
    {
      url: clusterIcon,
      height: 53,
      width: 53,
    },
  ],
  defaultOptions: {
    scrollwheel: true,
    styles,
    mapTypeControl: true,
    mapTypeControlOptions: {
      position: 11,
    },
    fullscreenControl: false,
  },
};

export const searchTrucksFields = ['vinNumber', 'idNumber', 'unitID'];
export const idToken = 'id_token';
export const userName = 'userName';
export const temporaryTruckDetails = {
  '1GRAA0626JW130105': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
    door: 'Open',
  },

  '1GRAP0621JT121618': {
    weight: '80,000 lbs',
    status: 'Untethered',
    truckType: 'dryVan',
    door: 'Closed',
  },
  '1GRAA0624JW130104': {
    weight: '80,000 lbs',
    status: 'Untethered',
    truckType: 'dryVan',
    door: '',
  },
  '1GRAA0621JW130108': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  'INSPIRE1101': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAA0622JW130103': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAP0629JD128357': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAP0629JT114724': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAA0627JK285269': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAP0622JT114726': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAA0623JW114007': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  'VIN03': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'reefer',
  },
  '1GRAP0620JD128361': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  'VIN02': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'flat',
  },
  '1GRAA0628JW130106': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAA0623JW130109': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAA0621JW130111': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAA0627JK285275': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAP0624JT114727': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAA0627JK285274': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAA0627JK285273': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  '1GRAA0627JK285272': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'flat',
  },
  '1GRAA0627JK285271': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'flat',
  },
  '1GRAP0620JD128358': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'dryVan',
  },
  'GREENBOARDTESTUNIT1': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'reefer',
  },
  'SPRINGRIDE07': {
    weight: '80,000 lbs',
    status: 'Tethered',
    truckType: 'reefer',
  },
};

// FIXME: use live data and key any UI elements by a DB UUID
export const serviceCentersData = [
  {
    name: 'Maxim Truck & Trailer',
    position: {
      lat: 51.108086,
      lng: -113.912011,
    },
    physicalAddress: '6704-84th Street S.E.',
    city: 'Calgary, AB T2C 4T6',
    phone: '403-571-1283',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Maxim Truck & Trailer#1',
    position: {
      lat: 53.544073,
      lng: -113.591166,
    },
    physicalAddress: '13240 - 170 th Street',
    city: 'Edmonton, AB T5V1M7',
    phone: '780-413-4139',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Maxim Truck & Trailer#2',
    position: {
      lat: 53.572716,
      lng: -113.284797,
    },
    physicalAddress: '80 Liberty Rd',
    city: 'Edmonton, AB T8H2J6',
    phone: '',
    advantEDGE: '',
    parts: '',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Trailine Trailer Parts',
    position: {
      lat: 49.155345,
      lng: -122.889606,
    },
    physicalAddress: '10304A - 120th St.',
    city: 'Surrey, BC V3V 4G1',
    phone: '604-582-4888',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: '',
  },
  {
    name: 'Maxim Trailers (Winnipeg-45 Loweson)',
    position: {
      lat: 49.8400189,
      lng: -97.2077952,
    },
    physicalAddress: '45 Loweson Crescent',
    city: 'Winnipeg, MB R3P O3T',
    phone: '204-790-6544',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Maxim Trailers (Winnipeg-580 Oakpoint)',
    position: {
      lat: 49.933729,
      lng: -97.216991,
    },
    physicalAddress: '580 Oakpoint Highway',
    city: 'Winnipeg, MB R3C 2000000',
    phone: '204-790-6544',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Maxim Truck & Trailer#3',
    position: {
      lat: 49.887286,
      lng: -99.941178,
    },
    physicalAddress: '225 Middleton Ave.',
    city: 'Brandon, MB R7C 1A8',
    phone: '800-263-6319',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Maxim Truck & Trailer#4',
    position: {
      lat: 49.946602,
      lng: -97.229584,
    },
    physicalAddress: '1860 Brookside Blvd.',
    city: 'Winnipeg, MB R3C 2E6',
    phone: '204-790-6544',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Nova Enterprises Limited',
    position: {
      lat: 45.3502237,
      lng: -63.3116987,
    },
    physicalAddress: '114 Connector Road',
    city: 'Truro, NS B2N 1C9',
    phone: '902-897-0809',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Glasvan Great Dane Sales, Inc.',
    position: {
      lat: 43.632784,
      lng: -79.630796,
    },
    physicalAddress: '1201 Aimco Blvd.',
    city: 'Mississauga, ON L4W 1B3',
    phone: '888-452-7826',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Glasvan Trailers, Inc.',
    position: {
      lat: 44.163259,
      lng: -79.818465,
    },
    physicalAddress: '4917 C.W. Leach Road',
    city: 'Alliston, ON L9R 2B1',
    phone: '705-434-1423',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Glasvan Trailers, Inc.#2',
    position: {
      lat: 44.134002,
      lng: -71.264571,
    },
    physicalAddress: '3378 Putman Road',
    city: 'Putnam, ON N0L 1B0',
    phone: '519-269-9970',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Glasvan Trailers, Inc.#1',
    position: {
      lat: 43.8741,
      lng: -78.917827,
    },
    physicalAddress: '1025 Hopkins Street',
    city: 'Whitby, ON L1N 2C2',
    phone: '905-430-1262',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane of Montreal',
    position: {
      lat: 45.575275,
      lng: -73.512752,
    },
    physicalAddress: '8000 rue St-Patrick',
    city: 'Montreal, QU H8N1V1',
    phone: '514-363-0000',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Maxim Truck & Trailer#5',
    position: {
      lat: 53.20008,
      lng: -105.76772,
    },
    physicalAddress: 'Highway 2 South',
    city: 'Prince Albert, SK S6V 5S2',
    phone: '306-922-1900',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Maxim Truck & Trailer#6',
    position: {
      lat: 50.45008,
      lng: -104.6178,
    },
    physicalAddress: 'B-385 Henderson Drive',
    city: 'Regina, SK S4P 3C2',
    phone: '204-790-6599',
    advantEDGE: '',
    parts: '',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Maxim Truck & Trailer#7',
    position: {
      lat: 50.467246,
      lng: -104.567125,
    },
    physicalAddress: '475 Henderson Dr.',
    city: 'Regina, SK S4N',
    phone: '306-721-9700',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Maxim Truck & Trailer#8',
    position: {
      lat: 52.133772,
      lng: -106.669802,
    },
    physicalAddress: '3250 Idylwyld Dr. N.',
    city: 'Saskatoon, SK S7L 5Y7',
    phone: '306-657-5600',
    advantEDGE: '',
    parts: '',
    sales: 'yes',
    service: '',
  },
  {
    name: 'Comercial Kaufmann',
    position: {
      lat: -33.461812,
      lng: -70.7189772,
    },
    physicalAddress: 'Av. Gladys Marin 5830 Estacion Central',
    city: 'Santiago',
    phone: '#011567202305',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Colombia',
    position: {
      lat: 4.710914,
      lng: -74.072574,
    },
    physicalAddress: 'Carrera 4 #70A-82 Of. 105',
    city: 'Bogota, Bogota',
    phone: '+57 1 222 13 92',
    advantEDGE: '',
    parts: '',
    sales: 'yes',
    service: '',
  },
  {
    name: 'Zapata Aerpuerto',
    position: {
      lat: 19.340773,
      lng: -98.9602205,
    },
    physicalAddress: 'Carretera Los Reyes-Texcoco-Lecherla Km 23',
    city: 'Texcoco, Edo. Mexico, ED 56200',
    phone: '+52 595 954 9933',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Zapata Leon',
    position: {
      lat: 21.0818737,
      lng: -101.6131222,
    },
    physicalAddress: 'Blv. Aeropuerto No 744, Col. San Jose el Alto',
    city: 'Leon, JA 37660',
    phone: '(01) 52 477-710-0016',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Zapata Guadalajara',
    position: {
      lat: 19.3079763,
      lng: -99.2178755,
    },
    physicalAddress: 'Periferico sur no. 1823, esp. Mariano Otero',
    city: 'Zapopan, JA 45236',
    phone: '(01)52 333-180-8542',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Zapata Camiones Tlalnepantla',
    position: {
      lat: 19.5460888,
      lng: -99.1758569,
    },
    physicalAddress: '1o de Mayo esq. Fulton s/n',
    city: 'Tlalnepantla, MX 54030',
    phone: '(01) 52 555-321-3030',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Zapata Monterrey',
    position: {
      lat: 39.201449,
      lng: -91.838271,
    },
    physicalAddress: 'Carr. Monterrey - Laredo km. 19',
    city: 'Apodaca, Nuevo Leon, QU 66600',
    phone: '# 011528183054500',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'TMI (Mexico)',
    position: {
      lat: 20.6216033,
      lng: -100.3927502,
    },
    physicalAddress: 'Privada del Marques 2',
    city: 'Queretaro, QU 76246',
    phone: '#011524422215632',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Zapata Queretaro',
    position: {
      lat: 20.6175,
      lng: -100.4055146,
    },
    physicalAddress: 'Blvd. Bernardo Quintana #588, San Pedrito Penuelas',
    city: 'Queretaro, QU 76148',
    phone: '(01) 52 442-309-3500',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Divemotor',
    position: {
      lat: -12.0873461,
      lng: -77.0181007,
    },
    physicalAddress: 'Av Nicolás Arriola 500',
    city: 'Lima, Lima 15034',
    phone: '#0115117122000',
    advantEDGE: '',
    parts: '',
    sales: 'yes',
    service: '',
  },
  {
    name: 'Great Dane Branch - Birmingham',
    position: {
      lat: 33.534047,
      lng: -86.85685,
    },
    physicalAddress: '1208 Bankhead Hwy',
    city: 'Birmingham, AL 35204',
    phone: '205-324-3491',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Little Rock',
    position: {
      lat: 34.784834,
      lng: -92.13879,
    },
    physicalAddress: '9901 Diamond Drive',
    city: 'N. Little Rock, AR 72117',
    phone: '501-945-8008',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Springdale',
    position: {
      lat: 36.176424,
      lng: -94.195288,
    },
    physicalAddress: '5850 West Sunset',
    city: 'Springdale, AR 72762',
    phone: '501-361-2019',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Patriot Truck and Trailer',
    position: {
      lat: 33.46919,
      lng: -94.037063,
    },
    physicalAddress: '510 Realtor Avenue',
    city: 'Texarkana, AR 71854',
    phone: '870-772-9545',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Thermo King West (Kingman)',
    position: {
      lat: 35.135929,
      lng: -114.1074216,
    },
    physicalAddress: '3120 S. Gatlin Rd.',
    city: 'Kingman, AZ 86413',
    phone: '928-728-0378',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: '',
  },
  {
    name: 'Superstition Trailers, LLC',
    position: {
      lat: 33.457003,
      lng: -112.165808,
    },
    physicalAddress: '535 North 51st Avenue',
    city: 'Phoenix, AZ 85043',
    phone: '602-415-0222',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Thermo King West (Tollenson)',
    position: {
      lat: 33.435369,
      lng: -112.286406,
    },
    physicalAddress: '8800 W Buckeye Rd',
    city: 'Tolleson, AZ 85353',
    phone: '602-415-9378',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'The Trailer Company',
    position: {
      lat: 35.357029,
      lng: -118.967666,
    },
    physicalAddress: '234 Mount Vernon Ave.',
    city: 'Bakersfield, CA 93307',
    phone: '661-324-7377',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Franklin Truck Parts (Carson)',
    position: {
      lat: 33.824485,
      lng: -118.238692,
    },
    physicalAddress: '2035 East 223rd',
    city: 'Carson, CA 90810',
    phone: '310-513-8292',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Franklin Truck Parts (Commerce)',
    position: {
      lat: 33.986548,
      lng: -118.137664,
    },
    physicalAddress: '6925 Bandini Blvd',
    city: 'Commerce, CA 90040',
    phone: '323-726-1034',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Eight Point Trailer',
    position: {
      lat: 34.063531,
      lng: -117.482675,
    },
    physicalAddress: '14770 Slover Avenue',
    city: 'Fontana, CA 92337',
    phone: '909-357-9227',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Franklin Truck Parts (Fontana)',
    position: {
      lat: 34.06398,
      lng: -117.501165,
    },
    physicalAddress: '13932 Slover Ave',
    city: 'Fontana, CA 92337-7068',
    phone: '909-356-5933',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Fontana',
    position: {
      lat: 34.069162,
      lng: -117.491535,
    },
    physicalAddress: '14387 Valley Blvd.',
    city: 'Fontana, CA 92335',
    phone: '909-823-0500',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Central Valley Trailer Repair, Inc.',
    position: {
      lat: 36.694173,
      lng: -119.773914,
    },
    physicalAddress: '2974 S. East Ave',
    city: 'Fresno, CA 93725',
    phone: '559-233-8444',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Franklin Truck Parts (Indio)',
    position: {
      lat: 33.735507,
      lng: -116.237088,
    },
    physicalAddress: '43181 Sunburst St',
    city: 'Indio, CA 92201',
    phone: '760-347-3824',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Franklin Truck Parts (Lancaster)',
    position: {
      lat: 34.705906,
      lng: -118.134365,
    },
    physicalAddress: '45314 Trevor Ave',
    city: 'Lancaster, CA 93534',
    phone: '661-949-7272',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Franklin Truck Parts (Oxnard)',
    position: {
      lat: 34.254998,
      lng: -119.161797,
    },
    physicalAddress: '370 Lambert St. Unit 4',
    city: 'Oxnard, CA 93030',
    phone: '805-983-7565',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Franklin Truck Parts (Valley)',
    position: {
      lat: 34.251314,
      lng: -118.405582,
    },
    physicalAddress: '9936 San Fernando Rd',
    city: 'Pacoima, CA 91331',
    phone: '818-897-5200',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Miramar Ford Truck Sales, Inc. (LA Freightliner)',
    position: {
      lat: 32.878429,
      lng: -117.181158,
    },
    physicalAddress: '6066 Miramar Road',
    city: 'San Diego, CA 92121',
    phone: '858-450-0707',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Advanced Trailer Sales & Service',
    position: {
      lat: 37.995619,
      lng: -121.264355,
    },
    physicalAddress: '3931 N. Wilson Way',
    city: 'Stockton, CA 95205',
    phone: '209-946-0450',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Central Valley Trailer Repair, Inc.#1',
    position: {
      lat: 37.937037,
      lng: -121.299762,
    },
    physicalAddress: '825 Navy Dr.',
    city: 'Stockton, CA 95206',
    phone: '209-941-8444',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Franklin Truck Parts (Victorville)',
    position: {
      lat: 34.506172,
      lng: -117.353076,
    },
    physicalAddress: '14215 Palmdale Rd',
    city: 'Victorville, CA 92392',
    phone: '760-241-7232',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Franklin Truck Parts (Walnut)',
    position: {
      lat: 33.99837,
      lng: -117.860779,
    },
    physicalAddress: '20350 E. Walnut Dr',
    city: 'Walnut, CA 91789',
    phone: '909-598-5768',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Amparts Sourcing, Inc. (Doral)',
    position: {
      lat: 25.752421,
      lng: -80.339735,
    },
    physicalAddress: '8860 NW 18th Terrace',
    city: 'Doral, FL 33172',
    phone: '305-715-9554',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Jacksonville',
    position: {
      lat: 30.327674,
      lng: -81.7363,
    },
    physicalAddress: '5231 West Beaver St.',
    city: 'Jacksonville, FL 32254',
    phone: '904-786-3300',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Miami',
    position: {
      lat: 25.802975,
      lng: -80.325856,
    },
    physicalAddress: '3130 N.W. 79th Ave.',
    city: 'Miami, FL 33122',
    phone: '305-592-0580',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Tampa',
    position: {
      lat: 27.9825247,
      lng: -82.3819487,
    },
    physicalAddress: '6710 East M.L. King Blvd.',
    city: 'Tampa, FL 33619',
    phone: '813-626-2147',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Atlanta',
    position: {
      lat: 33.609185,
      lng: -84.338321,
    },
    physicalAddress: '1637 Forest Pkwy',
    city: 'Lake City, GA 30260',
    phone: '404-755-7711',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Savannah',
    position: {
      lat: 32.096181,
      lng: -81.116956,
    },
    physicalAddress: '400 North Lathrop Avenue',
    city: 'Savannah, GA 31415',
    phone: '912-644-2377',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Jim Hawk Truck-Trailers of Des Moines, Inc.',
    position: {
      lat: 41.659939,
      lng: -93.507627,
    },
    physicalAddress: '3515 Adventureland Drive',
    city: 'Altoona, IA 50009',
    phone: '515-967-3800',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Jim Hawk Truck Trailer Sales, Inc.',
    position: {
      lat: 41.230671,
      lng: -95.857106,
    },
    physicalAddress: '3119 South 9ths Street',
    city: 'Council Bluffs, IA 51501',
    phone: '712-366-2241',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Indepedndent Parts Distribution (Jim Hawk)',
    position: {
      lat: 41.228446,
      lng: -95.857438,
    },
    physicalAddress: '2918 South 9th Street',
    city: 'Council Bluffs, IA 51501',
    phone: '712-366-2437',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Jim Hawk Truck-Trailers of Davenport, Inc.',
    position: {
      lat: 41.596309,
      lng: -90.586123,
    },
    physicalAddress: '900 W. 76th St.',
    city: 'Davenport, IA 52806',
    phone: '563-386-2990',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Jim Hawk Truck Trailers, Inc.',
    position: {
      lat: 42.466675,
      lng: -96.375785,
    },
    physicalAddress: '5200 S. Lewis Blvd.',
    city: 'Sioux City, IA 51106',
    phone: '712-258-9130',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Western Trailer Sales Co.#1',
    position: {
      lat: 43.545675,
      lng: -116.181155,
    },
    physicalAddress: '251 W. Gowen Rd.',
    city: 'Boise, ID 83716-5014',
    phone: '208-908-590',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Western Trailer (OEM MFG) - OE Purchasing Only',
    position: {
      lat: 43.523227,
      lng: -116.148575,
    },
    physicalAddress: '8623 S. Federal Way',
    city: 'Boise, ID 83715',
    phone: '208-908-5852',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Bolingbrook',
    position: {
      lat: 41.6982187,
      lng: -88.0420508,
    },
    physicalAddress: '699 E. So. Frontage Rd.',
    city: 'Bolingbrook, IL 60440',
    phone: '630-972-9292',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Jim Hawk Truck Trailers',
    position: {
      lat: 40.139819,
      lng: -88.285881,
    },
    physicalAddress: '2309 W. Bloomington Rd.',
    city: 'Champaign, IL 61822',
    phone: '217-353-6950',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'G&S Trailer Repair (Chicago)',
    position: {
      lat: 41.8318,
      lng: -87.716491,
    },
    physicalAddress: '3359 S. Lawndale Ave',
    city: 'Chicago, IL 60623',
    phone: '773-254-4293',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'U.S. Trailer Parts and Supply, Inc. (Chicago)',
    position: {
      lat: 41.813653,
      lng: -87.73033,
    },
    physicalAddress: '4334 South Tripp Avenue',
    city: 'Chicago, IL 60632',
    phone: '773-927-0600',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Jim Hawk Trailers of Illinois, Inc.',
    position: {
      lat: 40.717017,
      lng: -89.527807,
    },
    physicalAddress: '4001 North Main St.',
    city: 'East Peoria, IL 61611',
    phone: '309-694-6271',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Elk Grove Truck and Trailer Repair',
    position: {
      lat: 41.99405,
      lng: -87.942068,
    },
    physicalAddress: '2520 E Devon Ave.',
    city: 'Elk Grove Village, IL 60007',
    phone: '224-875-3109',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'TRS - Transportation Rep. & Serv.',
    position: {
      lat: 41.761318,
      lng: -87.859816,
    },
    physicalAddress: '7001 Santa Fe Drive',
    city: 'Hodgkins, IL 60525',
    phone: '708-482-9292',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Interstate Transport Repair',
    position: {
      lat: 41.795157,
      lng: -87.830299,
    },
    physicalAddress: '8500 W 53rd St.',
    city: 'McCook, IL 60525',
    phone: '708-447-4452',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Thermo King Midwest (Elkhart)',
    position: {
      lat: 41.727099,
      lng: -85.991924,
    },
    physicalAddress: '2926 Lillian Avenue',
    city: 'Elkhart, IN 46514',
    phone: '574-264-1221',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Hardy Brake & Electic',
    position: {
      lat: 37.980133,
      lng: -87.576436,
    },
    physicalAddress: '920 West Franklin St.',
    city: 'Evansville, IN 47710',
    phone: '800-441-0705',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'G&S Trailer Repair (Gary)',
    position: {
      lat: 41.59516,
      lng: -87.427227,
    },
    physicalAddress: '7159 W. 9th Ave',
    city: 'Gary, IN 46406',
    phone: '773-254-4293',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Indianapolis',
    position: {
      lat: 39.731269,
      lng: -86.237251,
    },
    physicalAddress: '4301 W. Bradbury Ave.',
    city: 'Indianapolis, IN 46241',
    phone: '317-248-2467',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Thermo King Midwest (New Haven)',
    position: {
      lat: 41.114581,
      lng: -85.162043,
    },
    physicalAddress: '2275 Patrick Henry Way',
    city: 'New Haven, IN 46774',
    phone: '260-493-0046',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Lower Great Lakes (Whiteford Trl)',
    position: {
      lat: 41.675467,
      lng: -86.283518,
    },
    physicalAddress: '137 South Olive Street',
    city: 'South Bend, IN 46619',
    phone: '800-860-9645',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Northcutt, Inc. (Ship To only)',
    position: {
      lat: 37.984844,
      lng: -100.901882,
    },
    physicalAddress: '2105 N. VFW Road',
    city: 'Garden City, KS 67846',
    phone: '620-276-0552',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Northcutt, Inc.',
    position: {
      lat: 37.775349,
      lng: -97.335967,
    },
    physicalAddress: '5055 North Broadway',
    city: 'Wichita, KS 67219',
    phone: '316-838-1477',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Interstar North America',
    position: {
      lat: 38.97817,
      lng: -84.630723,
    },
    physicalAddress: '7575 Empire Drive',
    city: 'Florence, KY 41042',
    phone: '859-283-1741',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Kentucky Service Parts and Equipment',
    position: {
      lat: 38.110532,
      lng: -84.518785,
    },
    physicalAddress: '2803 Georgetown Road',
    city: 'Lexington, KY 40511',
    phone: '859-258-2557',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Transport Specialists (Lexington)',
    position: {
      lat: 37.9681435,
      lng: -84.376354,
    },
    physicalAddress: '371 Cutter Hill',
    city: 'Lexington, KY 40509',
    phone: '859-263-3312',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: "Redden's Mobile Mechanics",
    position: {
      lat: 38.145352,
      lng: -85.729164,
    },
    physicalAddress: '4612 Melton Ave.',
    city: 'Louisville, KY 40213',
    phone: '502-541-6637',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'U.S. Trailer Parts and Supply, Inc. (Louisville)',
    position: {
      lat: 38.194015,
      lng: -85.704345,
    },
    physicalAddress: '1225 Gardiner Lane',
    city: 'Louisville, KY 40213',
    phone: '502-485-1700',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Tri-State Refrigeration (Baton Rouge)',
    position: {
      lat: 30.472938,
      lng: -91.114687,
    },
    physicalAddress: '7387 E Industrial Ave.',
    city: 'Baton Rouge, LA 70805',
    phone: '225-924-1652',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Tri-State Refrigeration (Jefferson)',
    position: {
      lat: 29.951473,
      lng: -90.176317,
    },
    physicalAddress: '334 Butterworth St.',
    city: 'Jeffersonville, LA 70121',
    phone: '504-733-9581',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Northeast Great Dane (New England Trailer Division)',
    position: {
      lat: 42.194122,
      lng: -71.825179,
    },
    physicalAddress: '442 Washington Street',
    city: 'Auburn, MA 1501',
    phone: '800-231-6343',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Thermo King Baltimore (Elkridge)',
    position: {
      lat: 39.199367,
      lng: -76.729089,
    },
    physicalAddress: '6317 Macaw Ct.',
    city: 'Elkridge, MD 21075',
    phone: '410-796-8921',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Royal Truck And Trailer',
    position: {
      lat: 42.322605,
      lng: -83.154609,
    },
    physicalAddress: '4840 Wyoming',
    city: 'Dearborn, MI 48126',
    phone: '313-846-4000',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Fleet Maintenance',
    position: {
      lat: 45.72163,
      lng: -87.074828,
    },
    physicalAddress: '2003 21st Street',
    city: 'Escanaba, MI 49829',
    phone: '906-786-1761',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Trudell Trailers of Grand Rapids, Inc.',
    position: {
      lat: 42.889662,
      lng: -85.833976,
    },
    physicalAddress: '6634 Center Industrial Drive',
    city: 'Jenison, MI 49428',
    phone: '616-777-0890',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Royal Truck and Trailer(Warren)',
    position: {
      lat: 42.467637,
      lng: -83.034834,
    },
    physicalAddress: '23660 Sherwood Ave.',
    city: 'Warren, MI 48091',
    phone: '586-756-8050',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Royal Truck And Trailer#1',
    position: {
      lat: 42.503028,
      lng: -83.533768,
    },
    physicalAddress: '28930 Wixom Rd',
    city: 'Wixom, MI 48393',
    phone: '313-846-400',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Crossroads Trailer Sales & Service, Inc.',
    position: {
      lat: 43.619195,
      lng: -93.347167,
    },
    physicalAddress: '2501 Crossroads Boulevard',
    city: 'Albert Lea, MN 56007',
    phone: '507-373-4443',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Trudell Trailers of Minnesota',
    position: {
      lat: 45.25451,
      lng: -93.659212,
    },
    physicalAddress: '9600 71st. St. NE',
    city: 'Albertville, MN 55301',
    phone: '763-497-6621',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Kolstad Co., Inc.',
    position: {
      lat: 45.124959,
      lng: -93.183012,
    },
    physicalAddress: '8501 Naples Street NE',
    city: 'Blaine, MN 55449',
    phone: '612-805-9419',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Trudell Trailers - Eagan',
    position: {
      lat: 44.839262,
      lng: -93.130731,
    },
    physicalAddress: '829 Aldrin Dr.',
    city: 'Eagan, MN 55121',
    phone: '651-406-8000',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Wallworks Truck Center (Fergus Falls)',
    position: {
      lat: 46.29117,
      lng: -96.119588,
    },
    physicalAddress: '712 Frontier Drive',
    city: 'Fergus Falls, MN 56537',
    phone: '218-998-3297',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Missouri Great Dane',
    position: {
      lat: 37.0844202,
      lng: -89.5403462,
    },
    physicalAddress: '2650 County Highway 401',
    city: 'Benton, MO 63736',
    phone: '866-545-3065',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Jim Hawk Truck-Trailers of Kansas City, Inc.',
    position: {
      lat: 39.125749,
      lng: -94.494555,
    },
    physicalAddress: '7500 NE Gardner Ave.',
    city: 'Kansas City, MO 64120',
    phone: '816-399-5660',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Missouri Great Dane#1',
    position: {
      lat: 38.805833,
      lng: -90.675903,
    },
    physicalAddress: '404 South Cool Springs Rd.',
    city: 'OFallon, MO 63366',
    phone: '866-280-6110',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Trux Trailer/Tractor Repair, Inc.',
    position: {
      lat: 37.2187283,
      lng: -93.3500507,
    },
    physicalAddress: '900 North West Bypass',
    city: 'Springfield, MO 65802',
    phone: '417-831-4074',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'CSTK of St Louis',
    position: {
      lat: 38.583899,
      lng: -90.360139,
    },
    physicalAddress: '420 Carrie Avenue',
    city: 'St Louis, MO 63147',
    phone: '314-771-6666',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Fortenberry Diesel Services',
    position: {
      lat: 31.2254669,
      lng: -90.4278013,
    },
    physicalAddress: '1165 Suite A Hwy 98 East',
    city: 'McComb, MS 39648',
    phone: '800-748-9063',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Rocky Mountain Great Dane',
    position: {
      lat: 45.798742,
      lng: -108.455222,
    },
    physicalAddress: '1210 Lockwood Road',
    city: 'Billings, MT 59101',
    phone: '406-245-6361',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'I - State (Great Falls)',
    position: {
      lat: 47.514724,
      lng: -111.348232,
    },
    physicalAddress: '2121 Vaughn Road',
    city: 'Great Falls, MT 59404',
    phone: '406-454-1311',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'I - State (Missoula)',
    position: {
      lat: 46.916213,
      lng: -114.033199,
    },
    physicalAddress: '5561 Expressway',
    city: 'Missoula, MT 59808',
    phone: '406-728-7600',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Charlotte',
    position: {
      lat: 35.331094,
      lng: -80.826853,
    },
    physicalAddress: '8011 Statesville Rd.',
    city: 'Charlotte, NC 28226',
    phone: '704-596-3721',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Wallworks Truck Center (Bismark)',
    position: {
      lat: 46.830382,
      lng: -100.72605,
    },
    physicalAddress: '4020 Miriam Ave',
    city: 'Bismark, ND 58502',
    phone: '701-224-1026',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Wallworks Truck Center (Dickinson)',
    position: {
      lat: 46.876158,
      lng: -102.84812,
    },
    physicalAddress: '4741 Villard Avenue',
    city: 'Dickinson, ND 58601',
    phone: '701-227-330',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Northwest Truck & Trailer',
    position: {
      lat: 46.889954,
      lng: -96.848667,
    },
    physicalAddress: '4020 12th Ave NW',
    city: 'Fargo, ND 58102',
    phone: '701-282-7470',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Wallwork Truck Center',
    position: {
      lat: 46.885979,
      lng: -96.837452,
    },
    physicalAddress: '900 35th St. NW',
    city: 'Fargo, ND 58102',
    phone: '701-476-7000',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Wallworks Truck Center (Minot)',
    position: {
      lat: 48.211152,
      lng: -101.259738,
    },
    physicalAddress: '2300 20th Ave SE',
    city: 'Minot, ND 58701',
    phone: '701-837-2800',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Wallworks Truck Center (West Fargo)',
    position: {
      lat: 46.876232,
      lng: -96.866088,
    },
    physicalAddress: '1909 East Main Avenue',
    city: 'West Fargo, ND 58078',
    phone: '701-282-7470',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Wallworks Truck Center (Williston)',
    position: {
      lat: 48.169395,
      lng: -103.615464,
    },
    physicalAddress: '2615 University Avenue',
    city: 'Williston, ND 58801',
    phone: '701-774-3007',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: "Floyd's Truck Center (Scottsbluff)",
    position: {
      lat: 41.852585,
      lng: -103.655998,
    },
    physicalAddress: '322 South Beltline East',
    city: 'Scottsbluff, NE 69361',
    phone: '308-632-2911',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: "Floyd's Truck Center (Sidney)",
    position: {
      lat: 41.110361,
      lng: -102.948894,
    },
    physicalAddress: '818 Hoffies Drive',
    city: 'Sidney, NE 69162',
    phone: '308-254-5956',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Northeast Great Dane (Empire Southern Tier Division)',
    position: {
      lat: 40.514533,
      lng: -74.612186,
    },
    physicalAddress: '315 Sunnymeade Rd.',
    city: 'Hillsborough, NJ 8844',
    phone: '800-231-6343',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'H.A. DeHart and Son- DeHart Great Dane',
    position: {
      lat: 39.842397,
      lng: -75.196116,
    },
    physicalAddress: '311 Crown Point Road',
    city: 'Thorofare, NJ 8086',
    phone: '800-222-0271',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Southwest Great Dane/Transportation Rental',
    position: {
      lat: 35.031748,
      lng: -106.657333,
    },
    physicalAddress: '3531 Second Street, S.W.',
    city: 'Albuquerque, NM 87105',
    phone: '505-877-1615',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Thermo King West (Aluquerque)',
    position: {
      lat: 35.064266,
      lng: -106.645549,
    },
    physicalAddress: '1901 Broadway NE',
    city: 'Albuquerque, NM 87102',
    phone: '505-342-9378',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Border International#1',
    position: {
      lat: 32.288094,
      lng: -106.781815,
    },
    physicalAddress: '1670 S. Valley Dr.',
    city: 'Las Cruces, NM 88005',
    phone: '575-647-8620',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'BRC Coach & Transit',
    position: {
      lat: 36.277531,
      lng: -115.016478,
    },
    physicalAddress: '6857 P Speedway Blvd',
    city: 'Las Vegas, NV 89115',
    phone: '702-632-2847',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Silver State Truck & Trailer',
    position: {
      lat: 36.2442167,
      lng: -115.1016242,
    },
    physicalAddress: '3701 Freightliner Drive',
    city: 'Las Vegas, NV 89081',
    phone: '702-643-0313',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Transportation Refrigeration of Nothern Nevada',
    position: {
      lat: 39.527622,
      lng: -119.759378,
    },
    physicalAddress: '1285 Glendale',
    city: 'Sparks, NV 89431',
    phone: '602-663-3728',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Northeast Great Dane (Empire Southern Tier Division)#1',
    position: {
      lat: 41.34308,
      lng: -74.347613,
    },
    physicalAddress: '4434 Randall St. Route 94',
    city: 'Florida, NY 10921',
    phone: '800-231-6343',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Northeast Great Dane (Lancaster Equipment Division)',
    position: {
      lat: 42.917891,
      lng: -78.653342,
    },
    physicalAddress: '3875 Walden Avenue',
    city: 'Lancaster, NY 14086',
    phone: '800-433-6343',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Northeast GDT (Empire Southern Tier)',
    position: {
      lat: 43.138141,
      lng: -76.07736,
    },
    physicalAddress: '7424 Eastman Road',
    city: 'N. Syracuse, NY 13212',
    phone: '315-458-0662',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Transport Specialists (Cincinnati)',
    position: {
      lat: 39.296584,
      lng: -84.431373,
    },
    physicalAddress: '12130 Best Place',
    city: 'Cincinnati, OH 45241',
    phone: '513-771-2220',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Tri-State Trailer Sales (Cincinnati)',
    position: {
      lat: 39.308711,
      lng: -84.450308,
    },
    physicalAddress: '5230 Duff Drive',
    city: 'Cincinnati, OH 45246',
    phone: '513-874-4880',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Columbus',
    position: {
      lat: 40.036174,
      lng: -83.127899,
    },
    physicalAddress: '4080 Lyman Drive',
    city: 'Hilliard, OH 43026',
    phone: '614-876-0666',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Tri-State Trailer Sales (Hubbard)',
    position: {
      lat: 41.180554,
      lng: -80.569103,
    },
    physicalAddress: '2340 North Main Street',
    city: 'Hubbard, OH 44425',
    phone: '330-534-0082',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: "Dan's Truck Equipment",
    position: {
      lat: 41.528249,
      lng: -83.549427,
    },
    physicalAddress: '8042 Fremont Pike',
    city: 'Perrysburg, OH 43551',
    phone: '419-874-2249',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Ohio Semi Trailer, Inc.',
    position: {
      lat: 41.406059,
      lng: -81.622255,
    },
    physicalAddress: '9099 Bank Street',
    city: 'Valley View, OH 44125',
    phone: '800-418-9513',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Roberts Truck Parts',
    position: {
      lat: 39.521572,
      lng: -83.836801,
    },
    physicalAddress: '600 Gillam Rd.',
    city: 'Wilmington, OH 45177',
    phone: '800-582-1485',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'City Trailer',
    position: {
      lat: 35.464465,
      lng: -97.696186,
    },
    physicalAddress: '10220 W. Reno Ave',
    city: 'Oklahoma City, OK 73127',
    phone: '405-947-4885',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Grand National Trailer, LLC',
    position: {
      lat: 36.1608,
      lng: -95.810614,
    },
    physicalAddress: '15091 E Admiral Place',
    city: 'Tulsa, OK 74116',
    phone: '918-437-1800',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Western Trailer (Albany)',
    position: {
      lat: 44.681072,
      lng: -123.055243,
    },
    physicalAddress: '3794 Conser Road NE',
    city: 'Albany, OR 97321',
    phone: '888-753-9048',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Mount Trailer Co.',
    position: {
      lat: 45.569529,
      lng: -122.597847,
    },
    physicalAddress: '6364 NE 63rd Ave.',
    city: 'Portland, OR 97218',
    phone: '503-282-7207',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'WW Trailers',
    position: {
      lat: 45.596906,
      lng: -122.719012,
    },
    physicalAddress: '5000 NE Columbia Blvd',
    city: 'Portland, OR 979218',
    phone: '503-821-6000',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Fleet Equipment, Inc.',
    position: {
      lat: 40.34199,
      lng: -79.75787,
    },
    physicalAddress: '110 Beeno Road',
    city: 'Darragh, PA 15625',
    phone: '724-446-6000',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Mt. Joy',
    position: {
      lat: 40.1178538,
      lng: -76.4641911,
    },
    physicalAddress: '1155 Four Star Drive',
    city: 'Mount Joy, PA 17552',
    phone: '717-492-0057',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Philadelphia',
    position: {
      lat: 40.041423,
      lng: -75.008352,
    },
    physicalAddress: '4800 Pennypack St.',
    city: 'Philadelphia, PA 19136',
    phone: '215-624-7501',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Tri-State Trailer Sales (Pittsburgh)',
    position: {
      lat: 40.50556,
      lng: -80.106805,
    },
    physicalAddress: '3111 Grand Avenue',
    city: 'Pittsburgh, PA 15225',
    phone: '412-747-7777',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Wheeler Brothers',
    position: {
      lat: 39.996916,
      lng: -79.049587,
    },
    physicalAddress: '384 Drum Avenue P.O. Box 737 Ship To: 409 Drum Ave.',
    city: 'Somerset PA 15501, PA 15501',
    phone: '800-458-0161',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Susquehanna Motor Company, Inc.',
    position: {
      lat: 41.027425,
      lng: -76.866353,
    },
    physicalAddress: '370 Old Route 15',
    city: 'West Milton, PA 17886',
    phone: '570-568-6941',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: "Eddie's Truck Center Inc.",
    position: {
      lat: 44.082055,
      lng: -103.194966,
    },
    physicalAddress: '1002 E Omaha',
    city: 'Rapid City, SD 57701',
    phone: '605-348-4900',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Jim Hawk Truck Trailers of Sioux Falls, Inc.',
    position: {
      lat: 43.586945,
      lng: -96.708157,
    },
    physicalAddress: '1401 E. Benson Road',
    city: 'Sioux Falls, SD 57104',
    phone: '605-338-6365',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Knoxville',
    position: {
      lat: 35.963838,
      lng: -83.953792,
    },
    physicalAddress: '2607 Middlebrook Pike',
    city: 'Knoxville, TN 37921',
    phone: '865-693-1721',
    advantEDGE: '',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Knox Trailers',
    position: {
      lat: 35.963838,
      lng: -83.953792,
    },
    physicalAddress: '2607 Middlebrook Pike',
    city: 'Knoxville, TN 37921',
    phone: '865-523-1115',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Post Trailer Repair',
    position: {
      lat: 35.960973,
      lng: -83.946852,
    },
    physicalAddress: '1905 Sutherland Avenue',
    city: 'Knoxville, TN 37921',
    phone: '865-523-4067',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Memphis',
    position: {
      lat: 35.086706,
      lng: -90.053209,
    },
    physicalAddress: '290 W. Mallory Avenue',
    city: 'Memphis, TN 38109',
    phone: '901-774-5741',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Nashville',
    position: {
      lat: 36.1559,
      lng: -86.720443,
    },
    physicalAddress: '186 Spence Lane',
    city: 'Nashville, TN 37210',
    phone: '615-882-0001',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'TSR, LLC.',
    position: {
      lat: 36.155292,
      lng: -86.720717,
    },
    physicalAddress: '190 Spence Lane',
    city: 'Nashville, TN 37210',
    phone: '615-889-8430',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Amarillo Thermo King',
    position: {
      lat: 35.191046,
      lng: -101.778903,
    },
    physicalAddress: '2100 South Eastern Street',
    city: 'Amarillo, TX 79118-2018',
    phone: '806-376-5837',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'American Equipment and Trailer, Inc.',
    position: {
      lat: 35.218538,
      lng: -101.794521,
    },
    physicalAddress: '610 North Grand',
    city: 'Amarillo, TX 79107',
    phone: '806-383-8831',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Dallas',
    position: {
      lat: 32.6516655,
      lng: -96.7602031,
    },
    physicalAddress: '4115 Port Blvd.',
    city: 'Dallas, TX 75241',
    phone: '214-637-2425',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Border International',
    position: {
      lat: 31.708805,
      lng: -106.286504,
    },
    physicalAddress: '12283 Rojas Dr.',
    city: 'El Paso, TX 79936',
    phone: '915-858-4644',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Houston',
    position: {
      lat: 29.792712,
      lng: -95.254216,
    },
    physicalAddress: '10030 Wallisville Rd.',
    city: 'Houston, TX 77013',
    phone: '713-675-1384',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Amparts Sourcing, Inc (Laredo)',
    position: {
      lat: 27.597817,
      lng: -99.497348,
    },
    physicalAddress: '9117 San Mateo Drive, Ste. C',
    city: 'Laredo, TX 78045',
    phone: '956-727-3933',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Reefer Pro Plus',
    position: {
      lat: 32.452532,
      lng: -94.728087,
    },
    physicalAddress: '4460 S.E. Loop 281',
    city: 'Longview, TX 75602',
    phone: '903-236-5030',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Lubbock Truck Sales',
    position: {
      lat: 33.529259,
      lng: -101.816693,
    },
    physicalAddress: '1801 E. Slaton Hwy.',
    city: 'Lubbock, TX 79404',
    phone: '806-748-1529',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'West Texas Thermo King- Lubbock',
    position: {
      lat: 33.527915,
      lng: -101.811289,
    },
    physicalAddress: '2420 E Slaton Hwy',
    city: 'Lubbock, TX 79404',
    phone: '806-748-1529',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'West Texas Thermo King (Odessa)',
    position: {
      lat: 31.900267,
      lng: -102.23824,
    },
    physicalAddress: '8001 East I-20',
    city: 'Odessa, TX 79765',
    phone: '432-561-9792',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Southwest Wheel Co.',
    position: {
      lat: 29.435382,
      lng: -98.432291,
    },
    physicalAddress: '343 Gembler Road',
    city: 'San Antonio, TX 78219',
    phone: '210-226-0301',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Amparts Sourcing, Inc.',
    position: {
      lat: 29.439053,
      lng: -98.3864656,
    },
    physicalAddress: '5411 IH-10 East',
    city: 'San Antonio, TX 78219',
    phone: '210-758-5953',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'JTM Truck Trailer',
    position: {
      lat: 29.4482167,
      lng: -98.3463257,
    },
    physicalAddress: '7380 Interstate 10E.',
    city: 'San Antonio, TX 78219',
    phone: '210-572-7972',
    advantEDGE: 'yes',
    parts: '',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Great Dane of Utah, Inc.',
    position: {
      lat: 40.726053,
      lng: -111.913415,
    },
    physicalAddress: '770 West 2100 South',
    city: 'Salt Lake City, UT 84119',
    phone: '801-214-3433',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Great Dane Branch - Richmond',
    position: {
      lat: 37.486508,
      lng: -77.430914,
    },
    physicalAddress: '2702 Deepwater Terminal Rd.',
    city: 'Richmond, VA 23234',
    phone: '804-233-9875',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Western Trailer Repair',
    position: {
      lat: 47.553074,
      lng: -122.32342,
    },
    physicalAddress: '707 South Lucille Street',
    city: 'Seattle, WA 98108',
    phone: '206-762-7850',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Western Trailers',
    position: {
      lat: 47.663695,
      lng: -117.319928,
    },
    physicalAddress: '6214 E. Broadway',
    city: 'Spokane, WA 99212',
    phone: '509-789-1829',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Western Trailer Sales Co.',
    position: {
      lat: 47.240035,
      lng: -122.252147,
    },
    physicalAddress: '1827 132nd Ave E',
    city: 'Sumner, WA 98390',
    phone: '253-863-0902',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Western Trailer (Portland)',
    position: {
      lat: 45.640193,
      lng: -122.684399,
    },
    physicalAddress: '2631 Scott Ave',
    city: 'Vancouver, WA 98660',
    phone: '360-314-3025',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Trudell Trailer Sales of Green Bay, Inc.',
    position: {
      lat: 44.415372,
      lng: -88.128874,
    },
    physicalAddress: '2049 Creamery Road',
    city: 'De Pere, WI 54115',
    phone: '920-336-0601',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: 'Trudell Trailers of Milwaukee, Inc.',
    position: {
      lat: 42.867189,
      lng: -87.948955,
    },
    physicalAddress: '2435 Southbranch Blvd.',
    city: 'Oak Creek, WI 53154',
    phone: '414-761-9355',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
  {
    name: "Jack's Heavy Equipment (Casper)",
    position: {
      lat: 42.864763,
      lng: -106.34973,
    },
    physicalAddress: '1900 Opportunity Boulevard',
    city: 'Casper, WY 82601',
    phone: '307-237-8600',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: "Floyd's Truck Center (Cheyenne)",
    position: {
      lat: 41.121044,
      lng: -104.804332,
    },
    physicalAddress: '221 E 1st Street',
    city: 'Cheyenne, WY 82007',
    phone: '307-638-3911',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: "Jack's Heavy Equipment (Gillette)",
    position: {
      lat: 44.236837,
      lng: -105.462851,
    },
    physicalAddress: '6100 South Douglas Highway',
    city: 'Gillette, WY 82718',
    phone: '307-686-0608',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Rocky Mountain GD Gillette (S&P Brake Supply)',
    position: {
      lat: 44.278463,
      lng: -105.507359,
    },
    physicalAddress: '1604 Wagonhammer Lane',
    city: 'Gillette, WY 82718',
    phone: '307-363-4329',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: "Jack's Heavy Equipment (Rock Springs)",
    position: {
      lat: 41.549697,
      lng: -109.300174,
    },
    physicalAddress: '376 Blairtown Road',
    city: 'Rock Springs, WY 82901',
    phone: '307-382-4646',
    advantEDGE: '',
    parts: 'yes',
    sales: '',
    service: 'yes',
  },
  {
    name: 'Jim Hawk Trailers , Inc - Denver',
    position: {
      lat: 39.870122,
      lng: -104.904674,
    },
    physicalAddress: '9595 Brighton Road',
    city: 'Henderson, CO 80640',
    phone: '303-288-5812',
    advantEDGE: '',
    parts: '',
    sales: 'yes',
    service: '',
  },
  {
    name: 'Merritt Equipment Co. - Denver',
    position: {
      lat: 39.865669,
      lng: -104.907203,
    },
    physicalAddress: '9339 Brighton Road',
    city: 'Henderson, CO 80640',
    phone: '303-287-7527',
    advantEDGE: 'yes',
    parts: 'yes',
    sales: 'yes',
    service: 'yes',
  },
];

export const modalHeaderData = [
  {
    VIN: '1GRAA0626JW130105',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '123,456.7mi',
  },
  {
    VIN: '1GRAP0621JT121618',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0624JW130104',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0621JW130108',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: 'INSPIRE1101',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0622JW130103',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAP0629JD128357',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAP0629JT114724',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0627JK285269',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0627JK285268',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAP0622JT114726',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0623JW114007',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: 'VIN03',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAP0620JD128361',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: 'VIN02',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0628JW130106',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0623JW130109',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0621JW130111',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0627JK285275',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAP0624JT114727',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0627JK285274',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0627JK285273',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0627JK285272',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAA0627JK285271',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: '1GRAP0620JD128358',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: 'GREENBOARDTESTUNIT1',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
  {
    VIN: 'SPRINGRIDE07',
    trailerType: 'dryVan',
    trailerLength: '53`',
    trailerAxle: 'Tandem',
    trailerOdometer: '12,456.mi',
  },
];

export const specificationsData = [
  {
    vin: 12345678901234567,
    product: 'CCC-Chamption Composite Plate',
    mfgPlant: 'Terre Haute,IN',
    year: 2019,
    pckgOptions: 'High Base Rail',
    serialPlate: 'US Certification Plate',
    height: '13\'6"',
    length: '53\'0"',
    width: '102.36"',
    upperCoupler: '47"',
    kingpin: '36"',
    landingGear: '110.5" from King Pin',
    wedge: '1.00"',
    undercarriage: 'Tandem Axle,Slide Air-ride Suspension, 49" centers',
    misc: '',
  },
  {
    vin: 12345678901234567,
    product: 'CCC-Chamption Composite Plate',
    mfgPlant: 'Terre Haute,IN',
    year: 2019,
    pckgOptions: 'High Base Rail',
    serialPlate: 'US Certification Plate',
    height: '13\'6"',
    length: '53\'0"',
    width: '102.36"',
    upperCoupler: '47"',
    kingpin: '36"',
    landingGear: '110.5" from King Pin',
    wedge: '1.00"',
    undercarriage: 'Tandem Axle,Slide Air-ride Suspension, 49" centers',
    misc: '',
  },
];
export const recentInspections = [
  {
    VIN: '1GRAA0626JW130105',
    dateTime: '9/3/17 14:21 CST',
    tripType: 'Post-Trip',
    inspectionStatus: 'passed',
    faultInformation: null,
  },
  {
    VIN: '1GRAP0621JT121618',
    dateTime: '9/3/17 14:21 CST',
    tripType: 'Pre-Trip',
    inspectionStatus: 'failed',
    faultInformation: 'Failed: Landing Gear, Tandem Release, Frame, Lights & Reflectors ',
  },
];
export const customLocationsData = [
  {
    name: 'Inspire11',
    position: {
      lat: 51.108086,
      lng: -113.912011,
    },
  },
  {
    name: 'Amazon',
    position: {
      lat: 53.544073,
      lng: -113.591166,
    },
  },
  {
    name: 'Amazon 1',
    position: {
      lat: 39.870122,
      lng: -104.904674,
    },
  },
];
