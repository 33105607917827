export const tseSensorLocation = {
  FRONT_LEFT: {
    name: 'frontLeft',
    description: 'Roadside Front',
  },
  FRONT_RIGHT: {
    name: 'frontRight',
    description: 'Curbside Front',
  },
  MIDDLE_LEFT: {
    name: 'middleLeft',
    description: 'Roadside Rear',
  },
  MIDDLE_RIGHT: {
    name: 'middleRight',
    description: 'Curbside Rear',
  },
};
