import { SET_FEATURE_FLAGS } from '../constants';

const initialFeatureFlags = {
  MapRefreshing: false,
  FirmwareVersion: false,
  Hendrickson: false,
  ThermoKing: false,
  TseFaultDescription: false,
  OtaManagement: false,
  AbsFaultDetailReport: false,
  UltrasonicCargoSensor: false,
  FreightIframe: false,
  ReeferAlarmAlert: false,
  ReeferTempHistoryReport: false,
  LoginV2: true,
  CameraFullness: false,
  AssetGeofenceThresholdReport: false,
  TowAlert: false,
  VinTransferPortal: false,
  CheckFuse: false,
  AlertAssetStatus: false,
  DisplaySensorConnectivityFilter: false,
  DisplaySensors: false,
  DisplayBatteryStatusFilter: false,
  MaintenanceDashboard: false,
  SubscriptionManagement: false,
  Insight: false,
};

const initialState = {
  features: initialFeatureFlags,
};

const actionMappings = {
  [SET_FEATURE_FLAGS]: '_setFeatureFlags',
};

const setFeatureFlags = (state, features) => {
  const featureFlagNames = Object.keys(state);
  const featureFlagsState = {};
  for (const key of featureFlagNames) {
    Object.assign(featureFlagsState, { [key]: features.some(feature => feature.featureName === key) });
  }

  return featureFlagsState;
};

const reducer = {
  _setFeatureFlags(state, { featureFlags }) {
    return {
      ...state,
      features: setFeatureFlags(state.features, featureFlags),
    };
  },
};

const featureFlagReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default featureFlagReducer;
