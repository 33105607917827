import { VEHICLE_GROUPS_LOADING, VEHICLE_GROUPS_ERROR, VEHICLE_GROUPS_SUCCESS } from '../constants';
import { returnAllCustomerRelatedVehicles } from '../utils/vehicleGroupsSelectUtils';
import _uniqBy from 'lodash/uniqBy';

const initialState = {
  customerId: null,
  vehicleGroupsData: null,
  vehicleGroupsLoading: false,
  error: false,
  customerRelatedVehicles: [],
};

const actionMappings = {
  [VEHICLE_GROUPS_LOADING]: '_vehicleGroupsLoading',
  [VEHICLE_GROUPS_ERROR]: '_vehicleGroupsError',
  [VEHICLE_GROUPS_SUCCESS]: '_vehicleGroupsSuccess',
};

const reducer = {
  _vehicleGroupsLoading(state, { payload: { vehicleGroupsLoading } }) {
    return {
      ...state,
      vehicleGroupsLoading,
    };
  },
  _vehicleGroupsError(state) {
    return {
      ...state,
      error: true,
    };
  },
  _vehicleGroupsSuccess(state, { payload: { customerId, vehicleGroupsData } }) {
    const allCustomerVehicles = returnAllCustomerRelatedVehicles(vehicleGroupsData?.vehicleGroups ?? []);
    const uniqueCustomerRelatedVehicles = _uniqBy(allCustomerVehicles, 'id');
    return {
      ...state,
      customerId,
      vehicleGroupsData,
      error: false,
      customerRelatedVehicles: uniqueCustomerRelatedVehicles,
    };
  },
};

const vehicleGroupsReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default vehicleGroupsReducer;
