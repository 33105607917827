import React from 'react';
import { Link } from 'react-router-dom';
import './HeaderEmpty.css';
import { ReactComponent as MainLogo } from '../../assets/public/FleetPulse_Logo.svg';

const HeaderEmpty = () => (
  <div className="headerEmpty-headerEmpty">
    <div className="headerEmpty-brandContainer">
      <Link to="/">
        <MainLogo className="headerEmpty-imageIcon" />
      </Link>
    </div>
  </div>
);

export default HeaderEmpty;
