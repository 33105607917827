import navigationDrawerSensors from '../constants/enums/navigationDrawerSensors';

export const filterEnabledAlertPredicateTypes = (alertPredicateTypes, features) => {
  const disabledAlertTypeCandidates = getDisabledAlertPredicateTypesCandidates(alertPredicateTypes, features);

  return Object.values(alertPredicateTypes).filter(apt => disabledAlertTypeCandidates[apt.key] !== false);
};

export const getDisabledAlertPredicateTypesCandidates = (alertPredicateTypes, features) => {
  let alertPredicateEnabledFlags = {};

  alertPredicateEnabledFlags[alertPredicateTypes.REEFER_FUEL.key] = features.ThermoKing ?? false;
  alertPredicateEnabledFlags[alertPredicateTypes.REEFER_ALARM.key] = features.ReeferAlarmAlert ?? false;
  alertPredicateEnabledFlags[alertPredicateTypes.ABS_FAULT_DETAIL_REPORT.key] = features.AbsFaultDetailReport ?? false;
  alertPredicateEnabledFlags[alertPredicateTypes.REEFER_TEMP_HISTORY_REPORT.key] = features.ReeferTempHistoryReport ?? false;
  alertPredicateEnabledFlags[alertPredicateTypes.ASSET_GEOFENCE_THRESHOLD_REPORT.key] = features.AssetGeofenceThresholdReport ?? false;
  alertPredicateEnabledFlags[alertPredicateTypes.TOW_ALERT.key] = features.TowAlert ?? false;

  return alertPredicateEnabledFlags;
};

export const filterEnabledSensors = (sensors, features) => {
  const disabledSensorCandidates = getDisabledSensorCandidates(features);
  return sensors?.filter(it => disabledSensorCandidates[it?.sensorTypeName] !== false);
};

export const getDisabledSensorCandidates = features => {
  let sensorEnabledFlags = {};

  sensorEnabledFlags[navigationDrawerSensors.defaultDrawerSensorTypes.ULTRASONIC_CARGO.sensorTypeName] = features?.UltrasonicCargoSensor ?? false;
  sensorEnabledFlags[navigationDrawerSensors.defaultDrawerSensorTypes.WHEEL_END.sensorTypeName] = features?.Hendrickson ?? false;
  sensorEnabledFlags[navigationDrawerSensors.defaultDrawerSensorTypes.INDIVIDUAL_TIRE_PRESSURE.sensorTypeName] = features?.Hendrickson ?? false;
  sensorEnabledFlags[navigationDrawerSensors.defaultDrawerSensorTypes.REEFER_UNIT.sensorTypeName] = features?.ThermoKing ?? false;

  return sensorEnabledFlags;
};
