import React from 'react';
import { getDefaultCellValue } from '../../utils/absTroubleshootingUtils';
import { convertTimestampToTimeUnits, getFormattedTimestamp } from '../../utils/dateUtils';
import RepairInfoCustomComponent from '../../components/AbsTroubleshooting/components/RepairInfoCustomComponent';

export default {
  STATUS: {
    key: 'STATUS',
    displayName: 'Status',
    attributeName: 'status',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'status',
      valueFormatter: params => getDefaultCellValue(params),
      cellRenderer: 'faultStatusRenderer',
    },
    fieldOptions: {
      ACTIVE: {
        id: 1,
        name: 'Active',
        key: 'ACTIVE',
      },
      CLEARED: {
        id: 2,
        name: 'Cleared',
        key: 'CLEARED',
      },
    },
  },
  FAULT_DESCRIPTION: {
    key: 'FAULT_DESCRIPTION',
    displayName: 'Fault Description',
    attributeName: 'faultDescription',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'faultDescription',
      valueFormatter: params => getDefaultCellValue(params),
      maxWidth: 400,
    },
  },
  FAULT_START: {
    key: 'FAULT_START',
    displayName: 'Fault Start',
    attributeName: 'faultStart',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'faultStart',
      valueFormatter: params => {
        if (!params?.value) {
          return '-';
        }
        return getFormattedTimestamp(params?.value ?? '');
      },
    },
  },
  FAULT_END: {
    key: 'FAULT_END',
    displayName: 'Fault End',
    attributeName: 'faultEnd',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'faultEnd',
      valueFormatter: params => {
        if (!params?.value) {
          return '-';
        }
        return getFormattedTimestamp(params?.value ?? '');
      },
    },
  },
  SUSPECT_COMPONENT: {
    key: 'SUSPECT_COMPONENT',
    displayName: 'Suspect Component',
    attributeName: 'suspectComponent',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'suspectComponent',
      valueFormatter: params => getDefaultCellValue(params),
    },
  },
  CAUSE: {
    key: 'CAUSE',
    displayName: 'Cause',
    attributeName: 'cause',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'cause',
      valueFormatter: params => getDefaultCellValue(params),
      cellRenderer: params => {
        const faultStyle = {
          height: 'auto',
          whiteSpace: 'normal',
          lineHeight: '16px',
          textAlign: 'left',
          wordBreak: 'break-word',
        };
        return <div style={faultStyle}>{params.value}</div>;
      },
      maxWidth: 400,
      cellStyle: { whiteSpace: 'normal', textAlign: 'left', padding: '12px 0px' },
      autoHeight: true,
      wrapText: true,
    },
  },
  RESOLUTION: {
    key: 'RESOLUTION',
    displayName: 'Repair Information',
    attributeName: 'repairInformation',
    agGridProps: {
      flex: 3,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'repairInformation',
      valueFormatter: params => getDefaultCellValue(params),
      cellRenderer: RepairInfoCustomComponent,
      cellStyle: { display: 'flex', alignItems: 'center' },
      autoHeight: true,
      maxWidth: 400,
    },
  },
  DURATION: {
    key: 'DURATION',
    displayName: 'Duration',
    attributeName: 'durationSeconds',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'durationSeconds',
      valueFormatter: params => {
        if (!params?.value) {
          return '-';
        }
        return convertTimestampToTimeUnits(params?.value, 'seconds');
      },
    },
  },
  MAX_SPEED: {
    key: 'MAX_SPEED',
    displayName: 'Speed',
    attributeName: 'maxMph',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'maxMph',
      valueFormatter: params => {
        if (!params?.value) {
          return '-';
        }
        return Math.round(params?.value);
      },
    },
  },
  MILES_TRAVELED: {
    key: 'MILES_TRAVELED',
    displayName: 'Miles Traveled',
    attributeName: 'bestAvailableTotalMileage',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'bestAvailableTotalMileage',
      valueFormatter: params => {
        if (params.value === null) {
          return '-';
        }
        return params?.value;
      },
    },
  },
  FAULT_TYPE_ID: {
    key: 'FAULT_TYPE_ID',
    displayName: `Fault Type ID`,
    attributeName: 'faultTypeId',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'faultTypeId',
      valueFormatter: params => getDefaultCellValue(params),
    },
  },
  FMI: {
    key: 'FMI',
    displayName: 'FMI',
    attributeName: 'fmi',
    agGridProps: {
      flex: 1,
      sortable: true,
      suppressMenu: true,
      filter: false,
      resizable: true,
      field: 'fmi',
      valueFormatter: params => getDefaultCellValue(params),
    },
  },
};
