import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  RECEIVE_VEHICLE_DETAIL_DATA,
  TOGGLE_VEHICLE_DETAIL_DATA_LOADING,
  FETCH_VEHICLE_DETAIL_DATA_FAIL,
  GET_SENSOR_ORDER_DATA,
  TOGGLE_SENSOR_ORDER_LOADING,
  FETCH_SENSOR_ORDER_FAIL,
  SET_SENSOR_ORDER_DATA_SUCCESS,
  SET_SENSOR_ORDER_DATA_FAIL,
  TOGGLE_SET_SENSOR_ORDER_LOADING,
  HANDLE_SENSOR_TYPE_DRAG_END,
} from '../constants';
import { orderSensors } from '../utils/dragAndDropUtils';
import drawerSensorTypes from '../constants/enums/navigationDrawerSensors';
import { reorderSensors } from '../utils/dragAndDropUtils';
import BRAND_NAME from '../theme/brand/app-tenant';
import { filterEnabledSensors } from '../utils/featureFlagUtils';

const initialState = {
  vehicleDetailData: {},
  vehicleDetailDataLoading: false,
  error: false,
  activeNavDrawerSensorTypes: [],
  activeDrawerFooterSensorTypes:
    BRAND_NAME.shortName === 'tk' ? Object.values(drawerSensorTypes.drawerFooterSensorTypesNonGD) : Object.values(drawerSensorTypes.drawerFooterSensorTypesGD),
  defaultDeviceType: '',
  moreySensorOrder: [],
  xirgoSensorOrder: [],
  sensorOrderLoading: false,
  sensorOrderError: false,
  setSensorOrderLoading: false,
  fleetColumnOrder: [],
};

const actionMappings = {
  [RECEIVE_VEHICLE_DETAIL_DATA]: '_receiveVehicleDetailData',
  [TOGGLE_VEHICLE_DETAIL_DATA_LOADING]: '_toggleVehicleDetailDataLoading',
  [FETCH_VEHICLE_DETAIL_DATA_FAIL]: '_fetchVehicleDetailDataFail',
  [GET_SENSOR_ORDER_DATA]: '_getSensorOrderData',
  [TOGGLE_SENSOR_ORDER_LOADING]: '_toggleSensorOrderLoading',
  [FETCH_SENSOR_ORDER_FAIL]: '_fetchSensorOrderFail',
  [SET_SENSOR_ORDER_DATA_SUCCESS]: '_setSensorOrderDataSuccess',
  [SET_SENSOR_ORDER_DATA_FAIL]: '_setSensorOrderDataFail',
  [TOGGLE_SET_SENSOR_ORDER_LOADING]: '_toggleSetSensorOrderLoading',
  [HANDLE_SENSOR_TYPE_DRAG_END]: '_handleOnDragEnd',
};

const reducer = {
  _receiveVehicleDetailData(state, { payload: { vehicleDetailData } }) {
    const deviceType = _get(vehicleDetailData, 'deviceType', {});

    return {
      ...state,
      vehicleDetailData,
      defaultDeviceType: _get(deviceType, 'longName'),
      error: false,
    };
  },
  _toggleVehicleDetailDataLoading(state) {
    return {
      ...state,
      vehicleDetailDataLoading: !state.vehicleDetailDataLoading,
    };
  },
  _fetchVehicleDetailDataFail(state, { features }) {
    const activeNavDrawerSensorTypes = Object.values(drawerSensorTypes.defaultDrawerSensorTypes);
    const filteredSensors = filterEnabledSensors(activeNavDrawerSensorTypes, features);

    return {
      ...state,
      error: true,
      activeNavDrawerSensorTypes: filteredSensors,
    };
  },
  _getSensorOrderData(state, { sensorOrder, features }) {
    const moreySensorOrder = sensorOrder?.moreySensorOrder ?? [];
    const xirgoSensorOrder = sensorOrder?.xirgoSensorOrder ?? [];
    const fleetColumnOrder = sensorOrder?.fleetColumnOrder ?? [];

    const deviceType = _get(state.vehicleDetailData, 'deviceType.longName', '');
    const activeNavDrawerSensorTypes = Object.values(drawerSensorTypes.defaultDrawerSensorTypes);
    const filteredSensors = filterEnabledSensors(activeNavDrawerSensorTypes, features);

    if (!_isEmpty(moreySensorOrder) && deviceType === 'Morey') {
      const orderedSensorTypes = orderSensors(filteredSensors, moreySensorOrder, 'sensorTypeId');

      return {
        ...state,
        moreySensorOrder,
        activeNavDrawerSensorTypes: orderedSensorTypes,
        fleetColumnOrder,
      };
    }

    if (!_isEmpty(xirgoSensorOrder) && deviceType === 'Xirgo') {
      const orderedSensorTypes = orderSensors(filteredSensors, xirgoSensorOrder, 'sensorTypeId');

      return {
        ...state,
        xirgoSensorOrder,
        activeNavDrawerSensorTypes: orderedSensorTypes,
        fleetColumnOrder,
      };
    }

    return {
      ...state,
      activeNavDrawerSensorTypes: filteredSensors,
      moreySensorOrder,
      xirgoSensorOrder,
      fleetColumnOrder,
    };
  },
  _toggleSensorOrderLoading(state) {
    return {
      ...state,
      sensorOrderLoading: !state.sensorOrderLoading,
    };
  },
  _fetchSensorOrderFail(state) {
    return {
      ...state,
      error: true,
    };
  },
  _setSensorOrderDataSuccess(state, { sensorOrderTypeIds, reorderedSensors }) {
    return {
      ...state,
      moreySensorOrder: sensorOrderTypeIds,
      xirgoSensorOrder: sensorOrderTypeIds,
      activeNavDrawerSensorTypes: reorderedSensors,
      error: false,
    };
  },
  _setSensorOrderDataFail(state) {
    return {
      ...state,
      error: true,
    };
  },
  _toggleSetSensorOrderLoading(state) {
    return {
      ...state,
      setSensorOrderLoading: !state.setSensorOrderLoading,
    };
  },
  _handleOnDragEnd(state, { result, features }) {
    if (!_get(result, 'destination', '')) {
      return {
        ...state,
      };
    }
    const filteredSensors = filterEnabledSensors(state.activeNavDrawerSensorTypes, features);
    const reorderedSensors = reorderSensors(filteredSensors, result?.source?.index ?? '', result?.destination?.index ?? '');

    return {
      ...state,
      activeNavDrawerSensorTypes: reorderedSensors,
    };
  },
};

const vehicleDetailDataReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default vehicleDetailDataReducer;
