import amberLampIcon from '../../assets/public/AmberLamp-M.png';
import tisIcon from '../../assets/public/TIS-M.png';
import trailerBatteryIcon from '../../assets/public/Battery-M.png';
import sensorNotReporting from '../../assets/public/SensorNotReporting-M.png';

export const csvHeaders = [
  { label: 'VIN', key: 'vin' },
  { label: 'Unit ID', key: 'unitId' },
  { label: 'Vehicle Type ID', key: 'vehicleTypeId' },
  { label: 'Device Type ID', key: 'deviceTypeId' },
  { label: 'Installed Sensor Type IDs', key: 'installedSensorTypeIds' },
  { label: 'Customer ID', key: 'customerId' },
  { label: 'Serial Number', key: 'serialNumber' },
  { label: 'Installation Date', key: 'installationDate' },
  { label: 'Groups', key: 'groups' },
  { label: 'ABS Odometer', key: 'absOdometer' },
  { label: 'GPS Odometer', key: 'gpsOdometer' },
  { label: 'Tethered Status', key: 'tetheredStatus' },
  { label: 'Time Untethered Days', key: 'timeUntetheredDays' },
  { label: 'ABS Fault Severity Level', key: 'absFaultSeverityLevel' },
  { label: 'Geofence', key: 'geofence' },
  { label: 'GPS Heading', key: 'gpsHeading' },
  { label: 'Load Status', key: 'loadStatus' },
  { label: 'Cargo Weight on Bogie (lbs)', key: 'cargoWeightOnBogieLbs' },
  { label: 'Address', key: 'address' },
  { label: 'Speed', key: 'speed' },
  { label: 'Door Status', key: 'doorStatus' },
  { label: 'ABS Fault', key: 'absFault' },
  { label: 'Tire Inflation Status', key: 'tireInflationStatus' },
  { label: 'Light Fault', key: 'lightFault' },
  { label: 'Trailer Battery Voltage', key: 'trailerBatteryVoltage' },
  { label: 'Trailer Battery Status', key: 'trailerBatteryStatus' },
  { label: 'Gateway Battery Status', key: 'gatewayBatteryStatus' },
  { label: 'Backup Battery Voltage', key: 'backupBatteryVoltage' },
  { label: 'Trailer Health Status', key: 'trailerHealthStatus' },
  { label: 'Connectivity Status', key: 'connectivityStatus' },
  { label: 'Latitude', key: 'lat' },
  { label: 'Longitude', key: 'lng' },
  { label: 'Cargo Camera Fullness', key: 'cargoCameraFullness' },
  { label: 'TK Unit On/Off', key: 'tkUnitOnOrOff' },
  { label: 'TK Operating Mode', key: 'tkOperatingMode' },
  { label: 'TK Fuel Level', key: 'tkFuelLevel' },
  { label: 'TK Ambient (F)', key: 'tkAmbientF' },
  { label: 'TK Humidity', key: 'tkHumidity' },
  { label: 'Interior Temp 1 (F)', key: 'interiorTemp1F' },
  { label: 'Interior Temp 2 (F)', key: 'interiorTemp2F' },
  { label: 'Interior Temp 3 (F)', key: 'interiorTemp3F' },
  { label: 'Interior Temp 4 (F)', key: 'interiorTemp4F' },
  { label: 'Interior Temp 5 (F)', key: 'interiorTemp5F' },
  { label: 'Interior Temp 6 (F)', key: 'interiorTemp6F' },
  { label: 'Set Point 1 (F)', key: 'setPoint1F' },
  { label: 'Set Point 2 (F)', key: 'setPoint2F' },
  { label: 'Set Point 3 (F)', key: 'setPoint3F' },
  { label: 'Discharge Air 1 (F)', key: 'dischargeAir1F' },
  { label: 'Discharge Air 2 (F)', key: 'dischargeAir2F' },
  { label: 'Discharge Air 3 (F)', key: 'dischargeAir3F' },
  { label: 'Return Air 1 (F)', key: 'returnAir1F' },
  { label: 'Return Air 2 (F)', key: 'returnAir2F' },
  { label: 'Return Air 3 (F)', key: 'returnAir3F' },
  { label: 'TK Engine Hours', key: 'tkEngineHours' },
  { label: 'TK Electrical Hours', key: 'tkElectricalHours' },
  { label: 'TK Total Hours', key: 'tkTotalHours' },
  { label: 'TK Unit Battery Voltage', key: 'tkUnitBatteryVoltage' },
  { label: 'Main App Version', key: 'mainAppVersion' },
  { label: 'Last Report', key: 'lastReport' },
  { label: 'Go Product Type', key: 'goProductType' },
  { label: 'Base Product Type', key: 'baseProductType' },
  { label: 'Is Thermoking', key: 'isThermoking' },
  { label: 'Installed Sensor Type IDs List', key: 'installedSensorTypeIdsList' },
];

export const ALERT_TYPES = {
  CAUTION: { key: 'CAUTION', id: 'caution', name: 'Caution', checked: false },
  CRITICAL: { key: 'CRITICAL', id: 'critical', name: 'Critical', checked: false },
  NOT_REPORTING: { key: 'NOT_REPORTING', id: 'not_reporting', name: 'Not Reporting', checked: false },
  SNOOZED: { key: 'SNOOZED', id: 'snoozed', name: 'Snoozed', checked: false },
};

export const HEADER_ISSUES = {
  absIssues: { label: 'Amber Lamp', icon: amberLampIcon },
  tisIssues: { label: 'Tire Inflation System', icon: tisIcon },
  trailerBatteryIssues: { label: 'Trailer Battery', icon: trailerBatteryIcon },
  notReportingIssues: { label: 'Sensor Not Reporting', icon: sensorNotReporting },
};
