import * as moment from 'moment';
import {
  TOKEN_EXPIRATION_START,
  TOKEN_EXPIRATION_FAIL,
  TOKEN_EXPIRATION_RESULT,
  CONFIRM_USER_START,
  CONFIRM_USER_FAIL,
  CONFIRM_USER_RESULT,
} from '../constants';

const initialState = {
  loading: false,
  error: false,
  tokenFound: false,
  tokenExpired: false,
  showForm: false,
  confirmStatus: false,
};

const actionMappings = {
  [TOKEN_EXPIRATION_START]: '_tokenExpirationStart',
  [TOKEN_EXPIRATION_FAIL]: '_tokenExpirationFail',
  [TOKEN_EXPIRATION_RESULT]: '_tokenExpirationResult',
  [CONFIRM_USER_START]: '_confirmUserStart',
  [CONFIRM_USER_FAIL]: '_confirmUserFail',
  [CONFIRM_USER_RESULT]: '_confirmUserResult',
};

const reducer = {
  _tokenExpirationStart(state) {
    return {
      ...state,
      loading: true,
      error: false,
      tokenFound: false,
      tokenExpired: false,
      showForm: false,
      confirmStatus: false,
    };
  },
  _tokenExpirationFail(state) {
    return {
      ...state,
      loading: false,
      error: true,
    };
  },
  _tokenExpirationResult(state, { tokenExpiration }) {
    const tokenFound = Boolean(tokenExpiration);
    const tokenExpired = tokenFound && moment(tokenExpiration).isBefore(moment.now());
    return {
      ...state,
      loading: false,
      error: false,
      tokenFound,
      tokenExpired,
      showForm: tokenFound && !tokenExpired,
    };
  },
  _confirmUserStart(state) {
    return {
      ...state,
      loading: true,
      showForm: false,
    };
  },
  _confirmUserFail(state) {
    return {
      ...state,
      loading: false,
      error: true,
      confirmStatus: false,
    };
  },
  _confirmUserResult(state, { confirmUser }) {
    return {
      ...state,
      loading: false,
      error: false,
      confirmStatus: confirmUser,
    };
  },
};

const confirmUserReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default confirmUserReducer;
