import React from 'react';
import BulletIcon from 'mdi-react/CircleMediumIcon';

export default function RepairInfoCustomComponent(params) {
  const repairInfoArray = params?.value?.split('- ').filter(item => item.trim() !== '');
  const containerStyle = {
    display: 'flex',
    padding: '5px 0px',
  };
  const iconContainerStyle = {
    display: 'flex',
  };
  const iconStyle = { position: 'relative', top: '2px' };
  const faultStyle = {
    height: 'auto',
    whiteSpace: 'normal',
    lineHeight: '16px',
    textAlign: 'left',
    wordBreak: 'break-word',
  };
  return (
    repairInfoArray
      ?.filter(fault => fault !== '')
      ?.map((fault, index) => {
        return (
          <div key={`${fault}, ${index}`} style={containerStyle}>
            <div style={iconContainerStyle}>
              <BulletIcon size={13} style={iconStyle} />
            </div>
            <div style={faultStyle}>{fault}</div>
          </div>
        );
      }) ?? ''
  );
}
