import {
  SET_SEARCH_LOCATION,
  TOGGLE_NAVIGATION_SEARCH_LIST,
  TOGGLE_SHOW_MORE_TRAILER_LIST,
  TOGGLE_SHOW_MORE_GEOFENCE_LIST,
  TOGGLE_LOCATION_SEARCH,
  MAP_DEFAULT_CENTER_LAT,
  MAP_DEFAULT_CENTER_LNG,
  SET_MAP_ZOOM,
  MAP_INITIAL_ZOOM,
  RESET_SEARCH_LOCATION_COORDINATES,
} from '../constants';

const initialState = {
  showGeofenceList: false,
  isNavigationSearchActive: false,
  isShowMoreTrailerActive: true,
  isShowMoreGeofenceActive: true,
  isLocationSearchActive: false,
  userGeofences: null,
  geofenceFilter: '',
  filteredUserGeofences: [],
  latitude: MAP_DEFAULT_CENTER_LAT,
  longitude: MAP_DEFAULT_CENTER_LNG,
  zoom: MAP_INITIAL_ZOOM,
  locations: [],
};

const actionMappings = {
  // [SET_GEOFENCE_FILTER]: '_setGeofenceFilter',
  //   [TOGGLE_GEOFENCE_LIST]: '_toggleGeofenceList',
  [SET_SEARCH_LOCATION]: '_setSearchLocation',
  [RESET_SEARCH_LOCATION_COORDINATES]: '_resetSearchLocationCoordinates',
  [TOGGLE_LOCATION_SEARCH]: '_toggleLocationSearch',
  [TOGGLE_NAVIGATION_SEARCH_LIST]: '_toggleNavigationSearchList',
  [TOGGLE_SHOW_MORE_TRAILER_LIST]: '_toggleShowMoreTrailerList',
  [TOGGLE_SHOW_MORE_GEOFENCE_LIST]: '_toggleShowMoreGeofenceList',
  [SET_MAP_ZOOM]: '_setMapZoom',
};

const reducer = {
  // _setGeofenceFilter(state, { filter }) {
  //   return {
  //     ...state,
  //     geofenceFilter: filter,
  //     filteredUserGeofences: filterGeofencesBy(filter, state.userGeofences),
  //   };
  // },

  //   _toggleGeofenceList(state, { val }) {
  //     return {
  //       ...state,
  //       showGeofenceList: val,
  //     };
  //   },

  _setSearchLocation(state, { lat, lng, zoom, locations, currentMapZoom }) {
    if (!locations && currentMapZoom > 11) {
      return {
        ...state,
        latitude: lat,
        longitude: lng,
        zoom: currentMapZoom,
      };
    }
    return {
      ...state,
      latitude: lat,
      longitude: lng,
      zoom,
      locations,
    };
  },
  _resetSearchLocationCoordinates(state) {
    return {
      ...state,
      latitude: 0,
      longitude: 0,
    };
  },
  _toggleLocationSearch(state, { val }) {
    return {
      ...state,
      isLocationSearchActive: val,
    };
  },

  _toggleNavigationSearchList(state, { val }) {
    return {
      ...state,
      isNavigationSearchActive: val,
    };
  },

  _toggleShowMoreTrailerList(state, { val }) {
    return {
      ...state,
      isShowMoreTrailerActive: val,
    };
  },

  _toggleShowMoreGeofenceList(state, { val }) {
    return {
      ...state,
      isShowMoreGeofenceActive: val,
    };
  },
  _setMapZoom(state, { zoom }) {
    return {
      ...state,
      zoom,
    };
  },
};

const navigationSearch = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default navigationSearch;
