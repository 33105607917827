import commaNumber from 'comma-number';
import _isFinite from 'lodash/isFinite';

export const getGpsOdometerMilesValue = gpsMiles => {
  if (!_isFinite(gpsMiles)) return 'N/A';

  const formattedGpsMiles = gpsMiles.toFixed(1);
  return `${commaNumber(formattedGpsMiles)}mi`;
};

export const roundMileage = rawMileage => {
  try {
    return Number(rawMileage).toFixed(1);
  } catch (error) {
    return null;
  }
};

export const formatMileage = (rawMileage, defaultValue) => {
  let roundedMileage = roundMileage(rawMileage);
  const defaultVal = defaultValue ? defaultValue : 'No data';
  if (!rawMileage) {
    return defaultVal;
  }
  return roundedMileage ? roundedMileage + 'mi' : defaultVal;
};
