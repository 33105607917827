import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);
export default PublicRoute;
