/* eslint-disable */
import {
  RECEIVE_CLOSEST_GD_SERVICE_DATA,
  TOGGLE_CLOSEST_GD_SERVICE_LOADING,
  FETCH_CLOSEST_GD_SERVICE_FAIL,
  SEND_DIAGNOSTIC_TO_SERVICE_CENTER_BEGIN,
  SEND_DIAGNOSTIC_TO_SERVICE_CENTER_SUCCESS,
  SEND_DIAGNOSTIC_TO_SERVICE_CENTER_FAIL,
  SHOW_SEND_DIAGNOSTIC_TO_SERVICE_CENTER_CONFIRM,
  OPEN_EMAIL_SENT_ALERT_DIALOG,
  SEND_DIAGNOSTIC_TO_SERVICE_CENTER_CANCEL,
} from '../constants';

const initialState = {
  data: {},
  closestAftermarketLoading: false,
  diagnosticEmailSent: false,
  showSendDiagnosticEmailToServiceCenterConfirm: false,
  isSendingEmail: false,
  error: false,
  confirmAftermarketDealer: null,
  confirmVin: null,
  isEmailSentAlertDialogOpen: false,
};

const actionMappings = {
  [RECEIVE_CLOSEST_GD_SERVICE_DATA]: '_receiveData',
  [TOGGLE_CLOSEST_GD_SERVICE_LOADING]: '_toggleLoading',
  [FETCH_CLOSEST_GD_SERVICE_FAIL]: '_setFailureState',
  [OPEN_EMAIL_SENT_ALERT_DIALOG]: '_openEmailSentAlertDialog',
  [SEND_DIAGNOSTIC_TO_SERVICE_CENTER_BEGIN]: '_sendDiagnosticToServiceCenterBegin',
  [SEND_DIAGNOSTIC_TO_SERVICE_CENTER_SUCCESS]: '_sendDiagnosticToServiceCenterSuccess',
  [SEND_DIAGNOSTIC_TO_SERVICE_CENTER_FAIL]: '_sendDiagnosticToServiceCenterFail',
  [SHOW_SEND_DIAGNOSTIC_TO_SERVICE_CENTER_CONFIRM]: '_showSendDiagnosticToServiceCenterConfirm',
  [SEND_DIAGNOSTIC_TO_SERVICE_CENTER_CANCEL]: '_sendDiagnosticToServiceCenterCancel',
};

const reducer = {
  _receiveData(state, { payload: { closestAftermarketDealers } }) {
    return {
      ...state,
      data: closestAftermarketDealers,
      closestAftermarketLoading: false,
    };
  },
  _openEmailSentAlertDialog(state) {
    return {
      ...state,
      isEmailSentAlertDialogOpen: !state.isEmailSentAlertDialogOpen,
    };
  },

  _toggleLoading(state) {
    return {
      ...state,
      closestAftermarketLoading: true,
    };
  },

  _setFailureState(state) {
    return {
      ...state,
      error: true,
      closestAftermarketLoading: false,
    };
  },

  _sendDiagnosticToServiceCenterBegin(state, { payload }) {
    return {
      ...state,
      error: false,
      isSendingEmail: true,
      diagnosticEmailSent: false,
    };
  },

  _sendDiagnosticToServiceCenterSuccess(state, { payload }) {
    return {
      ...state,
      error: false,
      isSendingEmail: false,
      diagnosticEmailSent: true,
      showSendDiagnosticEmailToServiceCenterConfirm: false,
    };
  },

  _sendDiagnosticToServiceCenterFail(state) {
    return {
      ...state,
      error: true,
      isSendingEmail: false,
      diagnosticEmailSent: false,
    };
  },
  _showSendDiagnosticToServiceCenterConfirm(state, { payload }) {
    return {
      ...state,
      error: false,
      isSendingEmail: false,
      confirmAftermarketDealer: payload.afterMarketDealer,
      confirmVin: payload.vinNumber,
      showSendDiagnosticEmailToServiceCenterConfirm: true,
    };
  },
  _sendDiagnosticToServiceCenterCancel(state) {
    return {
      ...state,
      confirmAftermarketDealer: null,
      confirmVin: null,
      showSendDiagnosticEmailToServiceCenterConfirm: false,
    };
  },
};

const closestAftermarketReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default closestAftermarketReducer;
