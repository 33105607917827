import {
  isCustomerAdmin,
  isUserAdmin,
  isUserCustomer,
  isUserGoInstallerAllCustomer,
  isUserGoInstallerCustomer,
  isUserTechnician,
  isUserInstaller,
  isAllCustomerView,
} from '../security/authorization';

export const shouldHideFAQ = noVINsAssigned => {
  const customerWithNoVinsAssigned = isUserCustomer() && !isUserGoInstallerCustomer() && !noVINsAssigned;
  return (
    isUserAdmin() ||
    isUserTechnician() ||
    isAllCustomerView() ||
    isCustomerAdmin() ||
    isUserGoInstallerAllCustomer() ||
    isUserInstaller() ||
    customerWithNoVinsAssigned
  );
};

export const handleScrollbarHeight = itemsLength => {
  const APPROX_ITEM_HEIGHT = 192;
  return itemsLength * APPROX_ITEM_HEIGHT;
};
