import idx from 'idx';
import _isEmpty from 'lodash/isEmpty';

import {
  BEGIN_CREATE_NEW_GEOFENCE,
  CLOSE_GEOFENCE_MODAL,
  BEGIN_GEOFENCE_DRAWING,
  EXIT_GEOFENCE_DRAWING_MODE,
  SET_CURRENT_DRAWING_SHAPE,
  SET_DRAWING_MODE,
  SAVE_SHAPE_FROM_DRAWING_MODE,
  LOAD_USER_GEOFENCE_INTO_MODAL,
  UPDATE_GEOFENCE_MODAL_NAME,
  UPDATE_GEOFENCE_MODAL_DESCRIPTION,
  UPDATE_GEOFENCE_MODAL_IS_ACTIVE,
  SET_GEOFENCE_FILTER,
  TOGGLE_GEOFENCE_LIST,
  TOGLE_GEOFENCE_EDITOR,
  TOGLE_SECOND_GEOFENCE_EDITOR,
  SAVE_GEOFENCE,
  FETCH_USER_GEOFENCES_V2_BEGIN,
  FETCH_USER_GEOFENCES_V2_SUCCESS,
  FETCH_USER_GEOFENCES_V2_FAIL,
  CREATE_USER_GEOFENCE_BEGIN,
  CREATE_USER_GEOFENCE_FAIL,
  OPEN_DELETE_GEOFENCE_ALERT_DIALOG,
  OPEN_UPDATE_GEOFENCE_ALERT_DIALOG,
  OPEN_CREATE_GEOFENCE_ALERT_DIALOG,
  CREATE_USER_GEOFENCE_SUCCESS,
  UPDATE_USER_GEOFENCE_BEGIN,
  UPDATE_USER_GEOFENCE_SUCCESS,
  UPDATE_USER_GEOFENCE_FAIL,
  DELETE_USER_GEOFENCE_BEGIN,
  DELETE_USER_GEOFENCE_SUCCESS,
  DELETE_USER_GEOFENCE_FAIL,
} from '../constants';

const initialState = {
  isDeleteAlertDialogOpen: false,
  isUpdateAlertDialogOpen: false,
  isCreateAlertDialogOpen: false,
  isGeofenceEditorActive: false,
  isSecondGeofenceEditorActive: false,
  showGeofenceList: false,
  userGeofences: null,
  userGeofencesFiltered: null,
  error: null,
  isLoadingUserGeofences: false,
  isSavingGeofence: false,
  geofenceFilter: '',

  showGeofenceModal: false,
  showSecondGeofenceEditor: false,
  isInGeofenceDrawingMode: false,
  drawingMode: 'circle',
  currentDrawingShape: null,
  hasChangeBeenMadeInDrawingMode: false,

  geofenceModalUserGeofence: null,

  geofenceModalShape: null,
  geofenceModalName: '',
  geofenceModalDescription: '',
  geofenceModalIsActive: true,
  geofenceModalEnterEmails: [],
  geofenceDefaultEnterEmails: [],
  geofenceModalExitEmails: [],
  geofenceDefaultExitEmails: [],
  fieldsHaveChanged: false,

  filteredUserGeofences: [],
  userGeofencesV2: [],
  filteredUserGeofencesV2: [],
  userGeofencesV2Loading: false,
};

const actionMappings = {
  [TOGLE_GEOFENCE_EDITOR]: '_toggleGeofenceEditor',
  [TOGLE_SECOND_GEOFENCE_EDITOR]: '_toggleSecondGeofenceEditor',
  [BEGIN_CREATE_NEW_GEOFENCE]: '_beginCreateNewGeofence',
  [CLOSE_GEOFENCE_MODAL]: '_closeGeofenceModal',
  [BEGIN_GEOFENCE_DRAWING]: '_beginGeofenceDrawing',
  [EXIT_GEOFENCE_DRAWING_MODE]: '_exitGeofenceDrawingMode',
  [SET_CURRENT_DRAWING_SHAPE]: '_setCurrentDrawingShape',
  [SET_DRAWING_MODE]: '_setDrawingMode',
  [SAVE_SHAPE_FROM_DRAWING_MODE]: '_saveShapeFromDrawingMode',
  [LOAD_USER_GEOFENCE_INTO_MODAL]: '_loadUserGeofenceIntoModal',
  [UPDATE_GEOFENCE_MODAL_NAME]: '_updateGeofenceModalName',
  [UPDATE_GEOFENCE_MODAL_DESCRIPTION]: '_updateGeofenceModalDescription',
  [UPDATE_GEOFENCE_MODAL_IS_ACTIVE]: '_updateGeofenceModalisActive',
  [SET_GEOFENCE_FILTER]: '_setGeofenceFilter',
  [TOGGLE_GEOFENCE_LIST]: '_toggleGeofenceList',
  [SAVE_GEOFENCE]: '_saveGeofence',
  [FETCH_USER_GEOFENCES_V2_BEGIN]: '_fetchUserGeofencesV2Begin',
  [FETCH_USER_GEOFENCES_V2_SUCCESS]: '_fetchUserGeofencesV2Success',
  [FETCH_USER_GEOFENCES_V2_FAIL]: '_fetchUserGeofencesV2Fail',
  [CREATE_USER_GEOFENCE_BEGIN]: '_createUserGeofenceBegin',
  [CREATE_USER_GEOFENCE_SUCCESS]: '_createUserGeofenceSuccess',
  [CREATE_USER_GEOFENCE_FAIL]: '_createUserGeofenceFail',
  [OPEN_CREATE_GEOFENCE_ALERT_DIALOG]: '_openCreateAlertDialog',
  [OPEN_UPDATE_GEOFENCE_ALERT_DIALOG]: '_openUpdateAlertDialog',
  [OPEN_DELETE_GEOFENCE_ALERT_DIALOG]: '_openDeleteAlertDialog',
  [UPDATE_USER_GEOFENCE_BEGIN]: '_updateUserGeofenceBegin',
  [UPDATE_USER_GEOFENCE_SUCCESS]: '_updateUserGeofenceSuccess',
  [UPDATE_USER_GEOFENCE_FAIL]: '_updateUserGeofenceFail',
  [DELETE_USER_GEOFENCE_BEGIN]: '_deleteUserGeofenceBegin',
  [DELETE_USER_GEOFENCE_SUCCESS]: '_deleteUserGeofenceSuccess',
  [DELETE_USER_GEOFENCE_FAIL]: '_deleteUserGeofenceFail',
};

function compare(a, b) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
}

const filterGeofencesBy = (filter, geofences) => {
  const initialGeofences = _isEmpty(geofences) ? [] : geofences;
  if (filter === '') {
    return initialGeofences.sort(compare);
  }

  const filteredGeofences = [];
  for (let i = 0; i < initialGeofences.length; i += 1) {
    if (initialGeofences[i].name.toLowerCase().includes(filter.toLowerCase())) {
      filteredGeofences.push(geofences[i]);
    }
  }

  return filteredGeofences.sort(compare);
};

const reducer = {
  _toggleGeofenceEditor(state, { val }) {
    return {
      ...state,
      isGeofenceEditorActive: val,
    };
  },

  _toggleSecondGeofenceEditor(state) {
    return {
      ...state,
      isSecondGeofenceEditorActive: !state.isSecondGeofenceEditorActive,
      isGeofenceEditorActive: false,
    };
  },

  _beginCreateNewGeofence(state) {
    return {
      ...state,
      showGeofenceModal: true,
      showSecondGeofenceEditor: false,
      drawingMode: 'circle',
      currentDrawingShape: null,
      geofenceFilter: '',

      geofenceModalUserGeofence: null,
      geofenceModalName: '',
      geofenceModalDescription: '',
      geofenceModalIsActive: true,
      geofenceModalShape: null,

      geofenceModalEnterEmails: '',
      geofenceModalExitEmails: '',

      isInGeofenceDrawingMode: true,
      hasChangeBeenMadeInDrawingMode: false,
    };
  },

  _closeGeofenceModal(state) {
    return {
      ...state,
      showGeofenceModal: false,
      showSecondGeofenceEditor: false,
    };
  },

  _beginGeofenceDrawing(state) {
    return {
      ...state,
      isInGeofenceDrawingMode: true,
      showSecondGeofenceEditor: false,
      drawingMode: idx(state, _ => _.geofenceModalShape.type) || 'circle',
      currentDrawingShape: state.geofenceModalShape,
      hasChangeBeenMadeInDrawingMode: false,
    };
  },

  _exitGeofenceDrawingMode(state) {
    return {
      ...state,
      isInGeofenceDrawingMode: false,
      currentDrawingShape: null,
      showGeofenceModal: false,
      showSecondGeofenceEditor: false,
    };
  },

  _setCurrentDrawingShape(state, { shape }) {
    return {
      ...state,
      currentDrawingShape: shape,
      hasChangeBeenMadeInDrawingMode: true,
      fieldsHaveChanged: true,
    };
  },

  _setDrawingMode(state, { mode }) {
    return {
      ...state,
      currentDrawingShape: null,
      hasChangeBeenMadeInDrawingMode: false,
      drawingMode: mode,
    };
  },

  _saveShapeFromDrawingMode(state, { shape }) {
    return {
      ...state,
      isInGeofenceDrawingMode: true,
      currentDrawingShape: shape,
      geofenceModalShape: shape,
      showGeofenceModal: false,
      showSecondGeofenceEditor: true,
    };
  },

  _saveGeofence(state, { shape }) {
    return {
      ...state,
      isInGeofenceDrawingMode: false,
      currentDrawingShape: null,
      geofenceModalShape: shape,
      showGeofenceModal: false,
      showSecondGeofenceEditor: false,
      filteredUserGeofencesV2: [],
    };
  },

  _loadUserGeofenceIntoModal(state, { geofence }) {
    return {
      ...state,
      showGeofenceModal: false,
      showSecondGeofenceEditor: true,

      geofenceModalUserGeofence: geofence,
      geofenceModalShape: geofence.shape,
      geofenceModalName: geofence.name,
      geofenceModalDescription: geofence.description || '',
      geofenceModalIsActive: geofence.isActive,

      fieldsHaveChanged: false,
    };
  },

  _updateGeofenceModalName(state, { name }) {
    return {
      ...state,
      geofenceModalName: name,
      fieldsHaveChanged: true,
    };
  },

  _updateGeofenceModalDescription(state, { description }) {
    return {
      ...state,
      geofenceModalDescription: description,
      fieldsHaveChanged: true,
    };
  },

  _updateGeofenceModalisActive(state, { isActive }) {
    return {
      ...state,
      geofenceModalIsActive: isActive,
      fieldsHaveChanged: true,
    };
  },

  _setGeofenceFilter(state, { filter }) {
    return {
      ...state,
      geofenceFilter: filter,
      // filteredUserGeofences: filterGeofencesBy(filter, state.userGeofences),
      filteredUserGeofencesV2: filterGeofencesBy(filter, state.userGeofencesV2),
    };
  },

  _toggleGeofenceList(state, { val }) {
    return {
      ...state,
      showGeofenceList: val,
    };
  },

  _fetchUserGeofencesV2Begin(state) {
    return {
      ...state,
      userGeofencesV2Loading: true,
    };
  },
  _fetchUserGeofencesV2Success(state, { geofencesV2 }) {
    return {
      ...state,
      error: null,
      userGeofencesV2: geofencesV2,
      userGeofencesV2Loading: false,
      filteredUserGeofencesV2: filterGeofencesBy(state.geofenceFilter, geofencesV2),
    };
  },
  _fetchUserGeofencesV2Fail(state) {
    return {
      ...state,
      userGeofencesV2Loading: false,
      error: true,
    };
  },
  _createUserGeofenceBegin(state) {
    return {
      ...state,
      error: null,
      isSavingGeofence: true,
    };
  },
  _createUserGeofenceSuccess(state, { geofence }) {
    const updatedGeofences = state.userGeofencesV2 || [];
    updatedGeofences.push(geofence);
    return {
      ...state,
      showGeofenceModal: false,
      showSecondGeofenceEditor: false,
      error: null,
      isSavingGeofence: false,
      userGeofencesV2: updatedGeofences,
      filteredUserGeofencesV2: filterGeofencesBy(state.geofenceFilter, updatedGeofences),
    };
  },
  _createUserGeofenceFail(state, { error }) {
    return {
      ...state,
      error,
      isSavingGeofence: false,
    };
  },
  _openCreateAlertDialog(state) {
    return {
      ...state,
      isCreateAlertDialogOpen: !state.isCreateAlertDialogOpen,
    };
  },
  _openUpdateAlertDialog(state) {
    return {
      ...state,
      isUpdateAlertDialogOpen: !state.isUpdateAlertDialogOpen,
    };
  },
  _openDeleteAlertDialog(state) {
    return {
      ...state,
      isDeleteAlertDialogOpen: !state.isDeleteAlertDialogOpen,
    };
  },
  _updateUserGeofenceBegin(state) {
    return {
      ...state,
      error: null,
      isSavingGeofence: true,
    };
  },
  _updateUserGeofenceSuccess(state, { geofence }) {
    const updatedUserGeofences = [];
    (state.userGeofencesV2 || []).forEach(ug => {
      if (ug.id !== geofence.id) {
        updatedUserGeofences.push(ug);
      } else {
        updatedUserGeofences.push(geofence);
      }
    });
    return {
      ...state,
      showGeofenceModal: false,
      showSecondGeofenceEditor: false,
      error: null,
      isSavingGeofence: false,
      userGeofencesV2: updatedUserGeofences,
      filteredUserGeofencesV2: filterGeofencesBy(state.geofenceFilter, updatedUserGeofences),
    };
  },
  _updateUserGeofenceFail(state, { error }) {
    return {
      ...state,
      error,
      isSavingGeofence: false,
    };
  },
  _deleteUserGeofenceBegin(state) {
    return {
      ...state,
      error: null,
      isSavingGeofence: false,
    };
  },
  _deleteUserGeofenceSuccess(state, { deletedGeofenceId }) {
    const updatedGeofences = (state.userGeofencesV2 || []).filter(g => g.id !== deletedGeofenceId);
    return {
      ...state,
      showGeofenceModal: false,
      showSecondGeofenceEditor: false,
      error: null,
      isSavingGeofence: false,
      userGeofencesV2: updatedGeofences,
      filteredUserGeofencesV2: filterGeofencesBy(state.geofenceFilter, updatedGeofences),
    };
  },
  _deleteUserGeofenceFail(state, { error }) {
    return {
      ...state,
      error,
      isSavingGeofence: false,
    };
  },
};

const geofence = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default geofence;
