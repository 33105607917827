import {
  RECEIVE_PARTS_DATA,
  TOGGLE_PARTS_DATA_LOADING,
  FETCH_PARTS_DATA_FAIL,
  TOGGLE_PARTS_IMAGE_MODAL,
  RECEIVE_IMAGE_MODAL_DATA
} from '../constants';

const initialState = {
  vehiclePartsData: {},
  vehiclePartsDataLoading: false,
  error: false,
  partsImageModalToggle: false,
  imageModalData: {},
};

const actionMappings = {
  [RECEIVE_PARTS_DATA]: '_receivePartsData',
  [TOGGLE_PARTS_DATA_LOADING]: '_togglePartsDataLoading',
  [FETCH_PARTS_DATA_FAIL]: '_fetchPartsDataFail',
  [TOGGLE_PARTS_IMAGE_MODAL]: '_togglePartsImageModal',
  [RECEIVE_IMAGE_MODAL_DATA]: '_receiveImageModalData',
};

const reducer = {
  _receivePartsData(state, { payload: { vehiclePartsData } }) {
    return {
      ...state,
      vehiclePartsData: vehiclePartsData,
    };
  },
  _receiveImageModalData(state, { payload: { imageModalData } }) {
    return {
      ...state,
      imageModalData: imageModalData,
    };
  },
  _togglePartsImageModal(state) {
    return {
      ...state,
      partsImageModalToggle: !state.partsImageModalToggle,
    };
  },
  _togglePartsDataLoading(state, { payload: { partsDataLoading } }) {
    return {
      ...state,
      vehiclePartsDataLoading: partsDataLoading,
    };
  },
  _fetchPartsDataFail(state) {
    return {
      ...state,
      error: true,
    };
  },
};

const partsDataReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default partsDataReducer;
