export const tseFaultLevels = {
  CRITICAL: {
    id: 3,
    key: 'CRITICAL',
    name: 'Critical',
  },
  CAUTION: {
    id: 2,
    key: 'CAUTION',
    name: 'Caution',
  },
  LOW: {
    id: 1,
    key: 'LOW',
    name: 'Low',
  },
  GOOD: {
    id: 0,
    key: 'GOOD',
    name: 'Good',
  },
};

export const findTseFaultById = tseFaultId => Object.values(tseFaultLevels).find(it => it.id === tseFaultId);
