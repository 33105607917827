import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, IconButton, Dialog } from '@material-ui/core';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import {
  setEmailAddress,
  setSelectedUserTypes,
  setSelectedCustomers,
  setSelectedGOCustomers,
  setSelectedAdminCustomers,
  setDisplayCustomers,
  setDisplayGOCustomers,
  setDisplayAdminCustomers,
  createAccount,
  getUsersData,
} from '../../actions/userManagement';
import { emailValidation } from '../../utils/emailValidationUtil';
import { fleetpulseAuthFetch } from '../../utils/fetch';
import {isUserAdmin, isCustomerAdmin, isViewOnlyAdmin} from '../../security/authorization';
import { UserTypes } from '../../constants/enums/userTypes';
import './UserModal.css';
import { getSelectedGroupsFromTree, getVehicleGroupsTreeData } from '../../utils/vehicleGroupsSelectUtils';
import MultiSelectTreeView from '../MultiSelectTreeView/MultiSelectTreeView';
import { addUsersToVehicleGroupsByIdApi, deleteUsersFromVehicleGroupsByIdApi } from '../../api/vehicleGroupsApi';
import cms from '../../messages/index';
import { ALL_VINS_GROUP, NO_VINS_GROUP } from '../../constants';
import CloseOctagonIcon from 'mdi-react/CloseOctagonIcon';
import CloseIcon from '@material-ui/icons/Close';
import Button from '../Button/Button';
import Tab from '../Tab/Tab';
import Checkbox from '../Checkbox/Checkbox';
import Input from '../Input/Input';
import Select from '../Select/Select';

const UserModal = ({ closeCreateAccountDialog, createAccountDialogOpen, customer }) => {
  const dispatch = useDispatch();
  const {
    selectedUserInformation,
    selectedUserTypes,
    isEditMode,
    emailAddressError,
    isCreatingAccount,
    emailAddress,
    initialSelectedUserTypes,
    initialSelectedCustomers,
    selectedCustomers,
    initialSelectedGOCustomers,
    selectedGOCustomers,
    initialSelectedAdminCustomers,
    selectedAdminCustomers,
    customers,
    displayGOCustomers,
    displayCustomers,
    displayAdminCustomers,
    createAccountError,
  } = useSelector(state => state.userManagement);
  const groups = useSelector(state => state.vehicleGroups);
  const [userType, setUserType] = useState(true);
  const [vehicleGroups, setVehicleGroups] = useState([]);
  const [initialSelectedGroups, setInitialSelectedGroups] = useState([]);
  const [removeFromGroup, setRemoveFromGroup] = useState([]);
  const [addToGroup, setAddToGroup] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const allVinsGroupId = vehicleGroups?.find(group => group?.name === ALL_VINS_GROUP)?.id;

  const disableGroups = useCallback((updatedGroups, excludedGroup) => {
    return updatedGroups.map(group => {
      if (group.name === excludedGroup) {
        return { ...group, selected: true };
      }
      return { ...group, selected: false, children: disableGroups(group.children, excludedGroup) };
    });
  }, []);

  const enableGroups = useCallback(updatedGroups => {
    return updatedGroups.map(group => {
      if (group.name === ALL_VINS_GROUP || group.name === NO_VINS_GROUP) {
        return { ...group, selected: false };
      }

      return { ...group };
    });
  }, []);

  const allVinNoVinHandler = useCallback(
    (vehicleGroups, node) => {
      const [selectedGroups] = getSelectedGroupsFromTree(vehicleGroups);
      const allVinsSelected = node?.selected && node?.name === ALL_VINS_GROUP;
      const noVinsSelected = node?.selected && node?.name === NO_VINS_GROUP;

      if (allVinsSelected) {
        const updatedVehiceGroups = disableGroups(vehicleGroups, ALL_VINS_GROUP);
        const [selectedGroups] = getSelectedGroupsFromTree(updatedVehiceGroups);
        return { selectedGroups, updatedVehiceGroups };
      } else if (noVinsSelected) {
        const updatedVehiceGroups = disableGroups(vehicleGroups, NO_VINS_GROUP);
        const [selectedGroups] = getSelectedGroupsFromTree(updatedVehiceGroups);
        return { selectedGroups, updatedVehiceGroups };
      } else if (node && !allVinsSelected && !noVinsSelected) {
        const updatedVehiceGroups = enableGroups(vehicleGroups);
        const [selectedGroups] = getSelectedGroupsFromTree(updatedVehiceGroups);
        return { selectedGroups, updatedVehiceGroups };
      }
      return { selectedGroups, updatedVehiceGroups: vehicleGroups };
    },
    [enableGroups, disableGroups],
  );

  useEffect(() => {
    const vehicleGroups = getVehicleGroupsTreeData(
      groups?.vehicleGroupsData?.vehicleGroups,
      selectedUserInformation?.customers?.[0]?.vehicleGroups?.map(user => ({ id: user.id })),
    );
    const { selectedGroups, updatedVehiceGroups } = allVinNoVinHandler(vehicleGroups);
    setVehicleGroups(updatedVehiceGroups);
    setInitialSelectedGroups(selectedGroups);
  }, [groups, selectedUserInformation, allVinNoVinHandler]);

  const updateVehicleGroups = (editedVehicleGroups, node) => {
    const { selectedGroups, updatedVehiceGroups } = allVinNoVinHandler(editedVehicleGroups, node);
    setVehicleGroups(updatedVehiceGroups);
    let removeFromGroup = [];
    let addToGroup = [];
    const isExistingGroupSelected = group => !selectedGroups.find(selectedGroup => selectedGroup.id === group.id);
    const isNewGroupSelected = group => !initialSelectedGroups.find(initialGroup => initialGroup.id === group.id);
    initialSelectedGroups.forEach(group => {
      if (isExistingGroupSelected(group)) {
        removeFromGroup.push(group.id);
      }
    });
    selectedGroups.forEach(group => {
      if (isNewGroupSelected(group)) {
        addToGroup.push(group.id);
      }
    });
    const allVinsSelected = !selectedGroups.some(group => group.id === allVinsGroupId);
    if (allVinsSelected) {
      removeFromGroup.push(allVinsGroupId);
    }
    setRemoveFromGroup(removeFromGroup);
    setAddToGroup(addToGroup);
    const noGroupSelected = initialSelectedGroups.filter(({ value: initialID }) => !selectedGroups.some(({ value: selectedID }) => selectedID === initialID));
    _isEmpty(noGroupSelected) ? setErrorMsg(false) : setErrorMsg(true);
  };

  const onCustomerSelect = customers => {
    dispatch(setSelectedCustomers(customers));
  };

  const onCustomerGOSelect = customers => {
    dispatch(setSelectedGOCustomers(customers));
  };

  const onAdminCustomerSelect = customers => {
    dispatch(setSelectedAdminCustomers(customers));
  };

  const onUserTypeSelect = name => event => {
    if (name === UserTypes.CUSTOMER.userType) {
      dispatch(setDisplayCustomers(event.target.checked));
    } else if (name === UserTypes.CUSTOMER_ADMIN.userType) {
      dispatch(setDisplayAdminCustomers(event.target.checked));
    } else if (name === UserTypes.GO_INSTALLER.userType) {
      dispatch(setDisplayGOCustomers(event.target.checked));
    }
    let newUserTypes = [];
    if (event.target.checked) {
      newUserTypes = selectedUserTypes.concat({ name });
    } else {
      newUserTypes = selectedUserTypes.filter(item => item.name !== name);
    }
    dispatch(setSelectedUserTypes(newUserTypes));
  };

  const isUserTypeChecked = userType => {
    const userTypeExists = selectedUserTypes.filter(item => item.name === userType);
    return userTypeExists.length !== 0;
  };

  const onEmailAddressChange = event => {
    const convertedEmailAddress = event.target.value.toLowerCase();
    const emailAddressError = emailValidation(convertedEmailAddress) ? '' : 'Please enter a valid email address.';
    dispatch(setEmailAddress(convertedEmailAddress, emailAddressError));
  };

  const isFormValid = userNeedsSave => {
    return (isEditMode && !userNeedsSave) || emailAddressError !== '' || selectedUserTypes.length === 0 || isCreatingAccount || !emailAddress;
  };

  const isCreateButtonDisabled = () => {
    if (isEditMode || (!userType && !errorMsg && selectedUserInformation?.customers[0]?.id)) return false;
    const userNeedsSave = !(
      _isEqual(initialSelectedUserTypes, selectedUserTypes) &&
      _isEqual(initialSelectedCustomers, selectedCustomers) &&
      _isEqual(initialSelectedGOCustomers, selectedGOCustomers) &&
      _isEqual(initialSelectedAdminCustomers, selectedAdminCustomers)
    );

    return isFormValid(userNeedsSave);
  };

  const addUsersToVehicleGroups = (addToGroup, customerId, userIds) => {
    addUsersToVehicleGroupsByIdApi({ userIds, customerId, vehicleGroupIds: addToGroup })
      .catch(err => {
        throw Error('Error', err);
      })
      .then(() => {
        dispatch(getUsersData());
      });
  };

  const deleteUsersFromVehicleGroups = (removeFromGroup, customerId, userIds) => {
    deleteUsersFromVehicleGroupsByIdApi({ userIds, customerId, vehicleGroupIds: removeFromGroup })
      .catch(err => {
        throw Error('Error', err);
      })
      .then(() => {
        dispatch(getUsersData());
      });
  };

  const addAndRemoveUsersFromGroup = (addToGroup, removeFromGroup, customerId, userIds) => {
    deleteUsersFromVehicleGroupsByIdApi({ userIds, customerId, vehicleGroupIds: removeFromGroup })
      .then(() =>
        addUsersToVehicleGroupsByIdApi({ userIds, customerId, vehicleGroupIds: addToGroup }).catch(err => {
          throw Error('Error', err);
        }),
      )
      .then(() => {
        dispatch(getUsersData());
      })
      .catch(err => {
        throw Error('Error', err);
      });
  };

  const onClickCreate = async () => {
    setLoading(true);
    const userRoles = [];
    const customersLength = customers?.length;
    selectedUserTypes.forEach(item => {
      if (item.name === UserTypes.CUSTOMER.userType || item.name === UserTypes.STANDARD_USER.userType) {
        if (isUserAdmin()) {
          selectedCustomers.forEach(item => {
            userRoles.push(UserTypes.CUSTOMER.rolePattern + item.value);
          });
        } else if (customersLength === 1) {
          userRoles.push(UserTypes.CUSTOMER.rolePattern + customers[0].id);
        } else {
          customers.forEach(customer => {
            userRoles.push(UserTypes.CUSTOMER.rolePattern + customer?.id);
          });
        }
      } else if (item.name === UserTypes.GO_INSTALLER.userType) {
        if (customersLength === 1) {
          userRoles.push(UserTypes.GO_INSTALLER.typeName);
          userRoles.push(UserTypes.CUSTOMER.rolePattern + customers[0].id);
        } else {
          userRoles.push(UserTypes.GO_INSTALLER.typeName);
          selectedGOCustomers.forEach(item => {
            userRoles.push(UserTypes.CUSTOMER.rolePattern + item.value);
          });
        }
      } else if (item.name === UserTypes.CUSTOMER_ADMIN.userType || item.name === UserTypes.ACCOUNT_ADMINISTRATOR.userType) {
        if (customersLength === 1) {
          userRoles.push(UserTypes.CUSTOMER_ADMIN.rolePattern + customers[0].id);
        } else {
          selectedAdminCustomers.forEach(item => {
            userRoles.push(UserTypes.CUSTOMER_ADMIN.rolePattern + item.value);
          });
        }
      } else {
        for (let it of Object.values(UserTypes)) {
          if (it.userType === item.name) {
            userRoles.push(it.typeName);
          }
        }
      }
    });
    await dispatch(createAccount(fleetpulseAuthFetch, emailAddress, userRoles, isEditMode)).then(() => {
      dispatch(getUsersData());
    });

    const customerId = selectedUserInformation?.customers[0]?.id ?? null;
    const userIds = [selectedUserInformation?.id];

    if (_isEmpty(removeFromGroup) && _isEmpty(addToGroup) && initialSelectedGroups.some(group => group.id !== allVinsGroupId)) {
      deleteUsersFromVehicleGroups([allVinsGroupId], customerId, userIds);
    }

    if (!_isEmpty(removeFromGroup) || !_isEmpty(addToGroup)) {
      if (!_isEmpty(removeFromGroup) && _isEmpty(addToGroup)) {
        deleteUsersFromVehicleGroups(removeFromGroup, customerId, userIds);
      }

      if (!_isEmpty(addToGroup) && _isEmpty(removeFromGroup)) {
        addUsersToVehicleGroups(addToGroup, customerId, userIds);
      }
      if (!_isEmpty(removeFromGroup) && !_isEmpty(addToGroup)) {
        addAndRemoveUsersFromGroup(addToGroup, removeFromGroup, customerId, userIds);
      }
    }
    closeCreateAccountDialog();
  };

  const displayGoInstallerDropdown = (userType, customersLength) => {
    if (displayGOCustomers && userType === UserTypes.GO_INSTALLER.userType && customersLength > 1)
      return (
        <Select
          isDisabled={false}
          isClearable
          options={customersOptions()}
          value={selectedGOCustomers}
          onChange={onCustomerGOSelect}
          isMulti
          placeholder="Select..."
          chipSize="xsmall"
        />
      );
  };

  const displayCustomerDropdown = (userType, customersLength) => {
    if (displayCustomers && (userType === UserTypes.CUSTOMER.userType || userType === UserTypes.STANDARD_USER.userType) && customersLength > 1)
      return (
        <Select
          isDisabled={false}
          isClearable
          options={customersOptions()}
          value={selectedCustomers}
          onChange={onCustomerSelect}
          isMulti
          placeholder="Select..."
          chipSize="xsmall"
        />
      );
  };

  const displayCustomerAdminDropdown = (userType, customersLength) => {
    if (
      displayAdminCustomers &&
      (userType === UserTypes.CUSTOMER_ADMIN.userType || userType === UserTypes.ACCOUNT_ADMINISTRATOR.userType) &&
      customersLength > 1
    )
      return (
        <Select
          isDisabled={false}
          isClearable
          options={customersOptions()}
          value={selectedAdminCustomers}
          onChange={onAdminCustomerSelect}
          isMulti
          placeholder="Select..."
          chipSize="xsmall"
        />
      );
  };

  const customersOptions = () =>
    (customers ?? []).map(it => ({
      label: it.shortName,
      value: it.id,
    }));

  const displayGroups = () => {
    return !_isEmpty(customer) ? (
      <div className="userManagement-group-container">
        <MultiSelectTreeView data={vehicleGroups ?? []} onChange={(item, node) => updateVehicleGroups(item, node)} />
      </div>
    ) : (
      <div className="userManagement-error-container">
        <CloseOctagonIcon color="#C11414" size={20} />
        <div className="userManagement-error-message">
          <div className="userManagement-error-title">Select a Customer</div>
          <div className="userManagement-error-details">{cms.atLeastOneCustomerSelected}</div>
        </div>
      </div>
    );
  };

  const userTypesList = [];
  const customersLength = _size(customers);
  const buttonIconStyle = { width: '16px', height: '16px' };

  if (isUserAdmin() || isViewOnlyAdmin() ) {
    for (let item of Object.values(UserTypes)) {

      if (item.canBeCreatedBy?.includes(UserTypes.ADMINISTRATOR.typeName)) {
        userTypesList.push(item.userType);
      }
    }
  } else if (isCustomerAdmin()) {
    for (let item of Object.values(UserTypes)) {
      if (item.canBeCreatedBy?.includes(UserTypes.CUSTOMER_ADMIN.typeName)) {
        userTypesList.push(item.userType);
      }
    }
  }

  return (
    <Dialog open={createAccountDialogOpen} maxWidth="md">
      <div className="modal-container">
        <div className="modal-header">
          <div>{isEditMode ? 'Edit User Information' : 'Create New User'}</div>
          <IconButton onClick={() => closeCreateAccountDialog()}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <div className="userManagement-createUserBody">
            <div className="userManagement-enterEmail">
              {isEditMode ? (
                <>
                  <div className="userManagement-inputLabel">Email Address</div>
                  <p className="userManagement-userEmail">{emailAddress}</p>
                </>
              ) : (
                <Input label="Email Address" placeholder="Email Address" onChange={e => onEmailAddressChange(e)} />
              )}
            </div>
            {isEditMode && (
              <div className="userManagement-modalTab">
                <Tab tabLabel="User Type" onClick={() => setUserType(true)} isActive={userType} style={{ width: '100%' }} />
                <Tab tabLabel="Groups" onClick={() => setUserType(false)} isActive={!userType} style={{ width: '100%' }} />
              </div>
            )}
            {userType ? (
              <div className="userManagement-userTypeContainer">
                <div className="userManagement-userInformationTitle">User Type</div>
                <div className="userManagement-userTypeOptions">
                  {userTypesList.map(userType => {
                    return (
                      <div className="userManagement-checkbox" key={userType}>
                        <FormControlLabel
                          key={userType}
                          control={
                            <Checkbox
                              onChange={onUserTypeSelect(userType)}
                              checked={isUserTypeChecked(userType)}
                              color="primary"
                              inputProps={{ 'aria-label': userType }}
                              value={userType}
                              labelRight={userType}
                              size="small"
                            />
                          }
                        />
                        {displayGoInstallerDropdown(userType, customersLength)}
                        {displayCustomerDropdown(userType, customersLength)}
                        {displayCustomerAdminDropdown(userType, customersLength)}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="userManagement-userTypeContainer">
                {errorMsg && <div className="userManagement-errorMsg">{cms.atLeastOneUserAssigned}</div>}
                <div className="userManagement-userInformationTitle">Groups</div>
                {displayGroups()}
              </div>
            )}
            <p className="userManagement-errorMessage">{createAccountError}</p>
          </div>
        </div>
      </div>
      <div className="modal-button-container">
        <Button buttonText="Cancel" onClick={() => closeCreateAccountDialog()} rightIcon={<CloseIcon style={buttonIconStyle} />} size="medium" outlineButton />
        <Button buttonText={isEditMode ? 'Update' : 'Create'} onClick={onClickCreate} disabled={isCreateButtonDisabled()} size="medium" isLoading={loading} />
      </div>
    </Dialog>
  );
};

export default UserModal;
