import { FOO } from '../constants';

const initialState = {
  user: {},
};

const actionMappings = {
  [FOO]: '_receiveUserData',
};

const reducer = {
  _receiveUserData(state, { user }) {
    return { ...state, user };
  },
};

const userReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default userReducer;
