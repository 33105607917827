import { ALL_VINS_GROUP, NO_VINS_GROUP } from '../constants';
import _orderBy from 'lodash/orderBy';

export function getVehicleGroupsTreeData(vehicleGroups, selectedGroups, selectedTrailers, callback) {
  const sortedVehicleGroups = sortVehicleGroupsDataAsc(vehicleGroups);
  return sortedVehicleGroups
    ?.map(({ id, name, vehicles, vehicleGroups, indeterminate }) => {
      const isSelected = Boolean(selectedGroups?.find(it => it.id === id));
      const isIndeterminate = Boolean(selectedTrailers) && selectedGroups?.filter(it => it.id === id)?.length !== selectedTrailers?.length;
      const result =
        {
          id: id ?? name,
          name,
          children: getVehicleGroupsTreeData(vehicleGroups, selectedGroups, callback) ?? [],
          selected: isSelected,
          indeterminate: indeterminate ?? (isSelected && isIndeterminate),
          expanded: false,
          vehicles: vehicles ?? [],
        } ?? [];
      return callback ? callback(result) : result;
    })
    .filter(it => it);
}

export function getSelectedGroupsFromTree(groups, parentSelected = false) {
  const checked = [];
  const indeterminateSelection = [];
  const extendedSelection = [];
  const vins = [];

  (groups || []).forEach(({ id, name, selected, vehicles, children, indeterminate }) => {
    if (selected) {
      checked.push({ id, name });
    }
    if (selected || parentSelected) {
      vins.push(...vehicles.map(it => it.vin));
      extendedSelection.push({ id, name });
    }
    if (indeterminate) {
      indeterminateSelection.push({ id, name });
    }
    const [childrenChecked, childrenVins, childrenExtended, childrenIndeterminate] = getSelectedGroupsFromTree(children, parentSelected || selected);
    checked.push(...childrenChecked);
    vins.push(...childrenVins);
    extendedSelection.push(...childrenExtended);
    indeterminateSelection.push(...childrenIndeterminate);
  });
  return [checked, vins, extendedSelection, indeterminateSelection];
}

export const formatTrailersToSelect = values => {
  return (values ?? []).map(trailer => ({
    label: trailerVinDisplay(trailer?.vin ?? '', trailer?.unitId ?? '') ?? '',
    value: trailer?.vehicleId ?? '',
  }));
};

export const trailerVinDisplay = (vin, unitId) => (unitId && unitId !== '-' ? `${vin} (${unitId})` : vin);

export const filterNonDefaultGroups = (vehicleGroupsTree, userManagementActive) =>
  userManagementActive ? vehicleGroupsTree : vehicleGroupsTree?.filter(group => group.name !== ALL_VINS_GROUP && group.name !== NO_VINS_GROUP);

export const formatUsersToSelect = values => {
  return (values ?? []).map(trailer => ({
    label: trailer?.email ?? '',
    value: trailer?.id ?? '',
  }));
};

export const isDefaultGroup = group => [ALL_VINS_GROUP, NO_VINS_GROUP].includes(group.name);

export function returnAllGroups(groups) {
  const checked = [];
  (groups || []).forEach(({ id, name, selected, children }) => {
    checked.push({ id, name, selected });
    const [childrenChecked] = returnAllGroups(children);
    checked.push(...childrenChecked);
  });
  return [checked];
}

export const sortVehicleGroupsDataAsc = vehicleGroupsList =>
  _orderBy(vehicleGroupsList ?? [], [vehicleGroupsList => vehicleSortingCallback(vehicleGroupsList)], ['asc']);

/**Sort vehicles group names in ascending order with ALL_VINS ADN NO_VINS at the end of the list */
const vehicleSortingCallback = vehicleGroupsList =>
  vehicleGroupsList.name === ALL_VINS_GROUP || vehicleGroupsList.name === NO_VINS_GROUP ? null : vehicleGroupsList.name.toUpperCase();

export const returnAllCustomerRelatedVehicles = (groups, parentSelected = false) => {
  const vins = [];
  (groups || []).forEach(({ selected, vehicles, children }) => {
    vins.push(...vehicles);
    const [, childrenVins] = getSelectedGroupsFromTree(children, parentSelected || selected);
    vins.push(...childrenVins);
  });
  return vins;
};

export const mapGroupNames = groups => {
  if (groups?.length === 1) {
    return groups?.map(group => group?.name);
  }
  const last = groups?.pop();
  const groupText = groups?.map(group => group?.name).join(', ');
  return `${groupText} and ${last?.name ?? ''}`;
};
