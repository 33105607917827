import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _flow from 'lodash/flow';
import { getTimeFromNowInDays } from './dateUtils';
import { FAULT_LEVELS, ABS_FAULT_FILTERS, hasCriticalAbsSeverityLevel, hasCautionAbsSeverityLevel } from '../constants/enums/absFaults';
import sensorStatusTypes from '../constants/enums/sensorStatusTypes';
import absFaultDateRangeFilterOptions from '../constants/enums/absFaultDateRangeFilterOptions';
import absTroubleshootingLifecycleAttributes from '../constants/enums/absTroubleshootingLifecycleAttributes';

export const mapAbsDataToAbsActiveFaults = _flow(
  absTroubleshootingData => _get(absTroubleshootingData, 'sensorSamplesByVin.sensorSamples', []),
  activeSensorSamples => activeSensorSamples.filter(abs => _get(abs, 'sensorBoxMfgrFaultType.faultType')),
);

export const activeAbsFaultsBasedOnActiveFilter = (activeFilter, allActiveAbsFaults) => {
  if (activeFilter.label === ABS_FAULT_FILTERS.ALL.label) {
    return allActiveAbsFaults;
  }

  if (activeFilter.label === ABS_FAULT_FILTERS.ABS.label) {
    return getActiveAbsFaults(allActiveAbsFaults);
  }

  if (activeFilter.label === ABS_FAULT_FILTERS.COMPONENT.label) {
    return getActiveComponentFaults(allActiveAbsFaults);
  }

  return allActiveAbsFaults;
};

const getActiveAbsFaults = allActiveAbsFaults => allActiveAbsFaults.filter(absFault => _get(absFault, 'absFaultLevel.id', '') === FAULT_LEVELS.ABS.id);

const getActiveComponentFaults = allActiveAbsFaults =>
  allActiveAbsFaults.filter(absFault => _get(absFault, 'absFaultLevel.id', '') === FAULT_LEVELS.COMPONENT.id);

export const findAbsFaultLevelById = id => {
  if (!id || id === '') {
    return '';
  }

  if (FAULT_LEVELS.ABS.id === id) {
    return FAULT_LEVELS.ABS.faultDesc;
  }

  if (FAULT_LEVELS.COMPONENT.id === id) {
    return FAULT_LEVELS.COMPONENT.faultDesc;
  }

  return '';
};

export const isAbsFault = id => findAbsFaultLevelById(id, FAULT_LEVELS.ABS.id) === FAULT_LEVELS.ABS.faultDesc;

export const isComponentFault = id => findAbsFaultLevelById(id, FAULT_LEVELS.COMPONENT.id) === FAULT_LEVELS.COMPONENT.faultDesc;

export const getAbsTrailerStatus = (absFaultsArray, lastCommunicationAt) => {
  const isAbsFaultNotAvailable = absFaultsArray === null || absFaultsArray === undefined || (_isEmpty(absFaultsArray) && !lastCommunicationAt);
  const criticalSeverityLevelActive = absFaultsArray?.some(fault => hasCriticalAbsSeverityLevel(fault?.absSeverityLevel?.id));
  const cautionSeverityLevelActive = absFaultsArray?.some(fault => hasCautionAbsSeverityLevel(fault?.absSeverityLevel?.id));

  if (isAbsFaultNotAvailable) return sensorStatusTypes.NOT_AVAILABLE.display;

  if (criticalSeverityLevelActive) {
    return sensorStatusTypes.CRITICAL.display;
  } else if (cautionSeverityLevelActive) {
    return sensorStatusTypes.CAUTION.display;
  } else {
    return sensorStatusTypes.GOOD.display;
  }
};

export const showFaultCount = absFaultcount => {
  if (absFaultcount === 0) {
    return '';
  }

  if (absFaultcount === 1) {
    return `- ${absFaultcount} fault`;
  }

  return `- ${absFaultcount} faults`;
};

export const absFaultsTrailerCount = truck =>
  truck.abs?.samples?.filter(fault => fault.absFaultLevelId === FAULT_LEVELS.ABS.faultLevelId || fault.absFaultLevelId === FAULT_LEVELS.COMPONENT.faultLevelId)
    ?.length;

export const getDefaultCellValue = params => {
  if (params.value === '' || params.value === null) {
    return '-';
  }

  return params.value;
};

export const filterAbsLifecyclesBySelect = ({ fault, selectedDateRangeFilter, selectedFaultLevelFilter, showAllFaultsFilter }) =>
  filterAbsLifecyclesByDateRange(fault, selectedDateRangeFilter) &&
  filterAbsLifecyclesFaultLevel(fault, selectedFaultLevelFilter) &&
  filterAbsLifecyclesByStatus(fault, showAllFaultsFilter);

export const filterAbsLifecyclesByDateRange = (fault, selectedFilterOption) => {
  if (selectedFilterOption === absFaultDateRangeFilterOptions.ALL) {
    return true;
  }
  return getTimeFromNowInDays(fault?.faultStart) < selectedFilterOption?.days;
};

export const filterAbsLifecyclesFaultLevel = (fault, selectedFaultLevel) => {
  switch (selectedFaultLevel.label) {
    case ABS_FAULT_FILTERS.ABS.label:
      return fault.faultLevelId === FAULT_LEVELS.ABS.faultLevelId;
    case ABS_FAULT_FILTERS.COMPONENT.label:
      return fault.faultLevelId === FAULT_LEVELS.COMPONENT.faultLevelId;
    default:
      return true;
  }
};

export const filterAbsLifecyclesByStatus = (fault, showAllFaultsFilter) => {
  if (showAllFaultsFilter) {
    return true;
  }

  return fault?.status === absTroubleshootingLifecycleAttributes.STATUS.fieldOptions.ACTIVE.name;
};

export const findCurrentTrailerDetailsByVin = (allTrucks, selectedVin) => allTrucks?.find(trailer => trailer.vin === selectedVin) ?? null;
