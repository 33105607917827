import React from 'react';
import CogIcon from 'mdi-react/CogIcon';
import UserWelcomePanel from '../../UserWelcomePanel';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUserPanel } from '../../../actions/tracking';
import { navigateToLogin } from '../../../utils/navigationUtils';
import '../styles/Header.css';

function UserPanelButton() {
  const dispatch = useDispatch();
  const { isAuthenticated, userEmail } = useSelector(state => state.login);
  const { showUserPanel } = useSelector(state => state.tracking);

  return (
    <div className="header-user-profile">
      <div role="button" tabIndex={0} className="header-companyButton">
        {isAuthenticated ? (
          <>
            <div className="header-user-info">
              <div className="header-userWelcome" role="button" tabIndex={0} onClick={() => dispatch(toggleUserPanel())}>
                {userEmail}
              </div>
            </div>
            <button data-cy="userIcon" className="header-userIcon" onClick={() => dispatch(toggleUserPanel())}>
              <CogIcon size={27} />
            </button>
            {showUserPanel && <UserWelcomePanel />}
          </>
        ) : (
          <>
            <button className="header-userIcon" onClick={navigateToLogin}>
              <CogIcon size={27} />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default UserPanelButton;
