const accessControlGroups = {
  adminsAndCustomers: ['ADMINS', 'CUSTOMER', 'CUSTOMER_ADMIN', 'ALL_CUSTOMER_VIEW'],
};

export const roleBasedAccessControl = {
  alerts: accessControlGroups['adminsAndCustomers'],
  insights: accessControlGroups['adminsAndCustomers'],
  myLocations: accessControlGroups['adminsAndCustomers'],
  myGeofences: accessControlGroups['adminsAndCustomers'],
  healthWidget: accessControlGroups['adminsAndCustomers'],
  trailerList: accessControlGroups['adminsAndCustomers'],
  vinSelector: accessControlGroups['adminsAndCustomers'],
  unitIdSelector: accessControlGroups['adminsAndCustomers'],
  fleet: accessControlGroups['adminsAndCustomers'],
  parts: accessControlGroups['adminsAndCustomers'],
  freight: accessControlGroups['adminsAndCustomers'],
};
