import PropTypes from 'prop-types';
import userManagementAttributes from '../../../constants/enums/userManagementAttributes';

function UserMngmntCustomFloatingFilter({ columnName, columnIdentifier, columnFilteringOptions }) {
  function FloatingFilter() {}
  FloatingFilter.prototype.init = function(params) {
    this.eGui = document.createElement('select');
    const allDropdownOption = 'All';
    const categories = [allDropdownOption, ...columnFilteringOptions];
    const options = categories.map(cat => `<option value="${cat}">${cat}</option>`);
    this.eGui.innerHTML = options;
    this.eGui.id = columnIdentifier;
    this.eGui.style.maxWidth = '100%';
    this.dropdownInput = this.eGui.querySelector('select');

    function onSelectChange(value) {
      if (value === allDropdownOption) {
        params.parentFilterInstance(function(instance) {
          instance.onFloatingFilterChanged(null, null);
        });
        return;
      }

      if (columnName === userManagementAttributes.ENABLED.attributeName || columnName === userManagementAttributes.TYPE.attributeName) {
        params.parentFilterInstance(function(instance) {
          instance.onFloatingFilterChanged('regexp', value);
        });
      } else {
        params.parentFilterInstance(function(instance) {
          instance.onFloatingFilterChanged('equals', value);
        });
      }
    }
    this.eGui.addEventListener('change', () => onSelectChange(this.eGui.value));
  };

  FloatingFilter.prototype.onParentModelChanged = function(parentModel) {
    if (!parentModel || !document.getElementById(columnIdentifier)) {
      return;
    } else {
      document.getElementById(columnIdentifier).value = this.eGui.options[this.eGui.selectedIndex].value;
    }
  };
  FloatingFilter.prototype.getGui = function() {
    return this.eGui;
  };
  return FloatingFilter;
}

UserMngmntCustomFloatingFilter.propTypes = {
  columnName: PropTypes.string,
  columnIdentifier: PropTypes.string,
  columnFilteringOptions: PropTypes.arrayOf(PropTypes.string),
};
UserMngmntCustomFloatingFilter.defaultProps = {
  columnName: '',
  columnIdentifier: '',
  columnFilteringOptions: [],
};

export default UserMngmntCustomFloatingFilter;
