export const sendVerificationEmailApi = async (fetch, userId) => {
  const query = `
    mutation {
      sendVerificationEmail(
        userId: "${userId}",
      )
    }
  `;
  
  return fetch('/auth/graphql', {
    method: 'POST',
    body: JSON.stringify({ query }),
  });
};
  