import React from 'react';
import { Dialog } from '@material-ui/core';
import { CheckCircle, Warning, Block } from '@material-ui/icons';
import './AlertDialog.css';
import Button from '../Button/Button';

function AlertDialog({ open, onConfirm, title = '', content = '', warningIcon = false, errorIcon = false }) {
  const ErrorIconStyles = { color: 'red', width: '40px', height: '40px' };
  const WarningIconStyles = { color: '#FFA500', width: '40px', height: '40px' };
  const CheckIconStyles = { color: '#66BC6A', width: '40px', height: '40px' };
  const handleIconDisplay = () => {
    if (errorIcon) {
      return <Block style={ErrorIconStyles} />;
    }
    if (warningIcon) {
      return <Warning style={WarningIconStyles} />;
    }
    return <CheckCircle style={CheckIconStyles} />;
  };

  return (
    <Dialog open={open} maxWidth="md">
      <div className="alert-dialog">
        {handleIconDisplay()}
        <div className="title">{title}</div>
        <div className="content">{content}</div>
        <div className="actions">
          <Button buttonText="OK" onClick={onConfirm} size="medium" />
        </div>
      </div>
    </Dialog>
  );
}

export default AlertDialog;
