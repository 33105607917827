import { resetUserPassword } from '../services/authService';

// eslint-disable-next-line no-unused-vars
import { API_URL } from '../config/url';

export const getConfirmationCodeRequest = async (fetch, username) => {
  return fetch('/auth/v1/request-password-reset', {
    method: 'POST',
    body: JSON.stringify(username),
  });
};

export const changeUserPassword = async ({ username, verificationCode, password }) => {
  const response = await resetUserPassword({ username, verificationCode, password });
  return response;
};

export const sendLoginCredentials = async (username, password) => {
  return fetch(`${API_URL}/auth/v1/login`, {
    headers: {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
  }).then(x => x.json());
};
