import _get from 'lodash/get';

import { emailValidation } from '../utils/emailValidationUtil';
import { checkPasswordValidation } from '../utils/checkPasswordValidationUtil';

import {
  SET_CREATE_ACCOUNT_EMAIL,
  SET_CREATE_ACCOUNT_PASSWORD,
  SET_CREATE_ACCOUNT_CONFIRM_PASSWORD,
  TOGGLE_CREATE_ACCOUNT_PASSWORDS,
  TRIGGER_CREATE_ACCOUNT_STATUS_PAGE,
  TOGGLE_CREATE_ACCOUNT_LOADING,
  CREATE_ACCOUNT_FAIL,
  CREATE_ACCOUNT_SUCCESS,
  RESET_CREATE_ACCOUNT_FIELDS,
} from '../constants';
import { } from '../constants';

const initialState = {
  email: '',
  password: '',
  confirmPassword: '',
  showPassword: false,
  isEmailValid: false,
  passwordCriterias: {
    passwordMismatch: false,
    passwordRequiresAtLeastOneLowercase: true,
    passwordTooShort: true,
    passwordPatternNoMatch: true,
    passwordRequiresAtLeastOneNumber: true,
    formValid: false,
  },
  createAccountStatusPage: false,
  createAccountStatus: false,

  error: null,
  loading: false,
};

const actionMappings = {
  [SET_CREATE_ACCOUNT_EMAIL]: '_setCreateAccountEmail',
  [SET_CREATE_ACCOUNT_PASSWORD]: '_setCreateAccountPassword',
  [SET_CREATE_ACCOUNT_CONFIRM_PASSWORD]: '_setCreateAccountConfirmPassword',
  [TOGGLE_CREATE_ACCOUNT_PASSWORDS]: '_toggleCreateAccountPasswords',
  [TRIGGER_CREATE_ACCOUNT_STATUS_PAGE]: '_triggerCreateAccountStatusPage',
  [TOGGLE_CREATE_ACCOUNT_LOADING]: '_toggleCreateAccountLoading',
  [CREATE_ACCOUNT_FAIL]: '_createAccountFail',
  [CREATE_ACCOUNT_SUCCESS]: '_createAccountSuccess',
  [RESET_CREATE_ACCOUNT_FIELDS]: '_resetCreateAccountFields',
};

const reducer = {
  _setCreateAccountEmail(state, { email }) {
    const convertedEmail = email.toLowerCase();
    const password = _get(state, 'password', '');
    const confirmPassword = _get(state, 'confirmPassword', '');

    return {
      ...state,
      email: convertedEmail,
      isEmailValid: emailValidation(email),
      passwordCriterias: checkPasswordValidation(
        password,
        confirmPassword,
        convertedEmail,
      ),
    };
  },
  _setCreateAccountPassword(state, { password }) {
    const confirmPassword = _get(state, 'confirmPassword', '');

    return {
      ...state,
      password,
      passwordCriterias: checkPasswordValidation(
        password,
        confirmPassword,
      ),
    };
  },
  _setCreateAccountConfirmPassword(state, { confirmPassword }) {
    const password = _get(state, 'password', '');
    return {
      ...state,
      confirmPassword,
      passwordCriterias: checkPasswordValidation(
        password,
        confirmPassword,
      ),
    };
  },
  _toggleCreateAccountPasswords(state) {
    return {
      ...state,
      showPassword: !state.showPassword,
    };
  },
  _triggerCreateAccountStatusPage(state) {
    return {
      ...state,
      createAccountStatusPage: !state.createAccountStatusPage,
    };
  },
  _toggleCreateAccountLoading(state, { loading }) {
    return {
      ...state,
      loading,
    };
  },
  _createAccountFail(state, { error }) {
    return {
      ...state,
      createAccountStatus: false,
      error,
    };
  },
  _createAccountSuccess(state) {
    return {
      ...state,
      createAccountStatus: true,
      error: null,
    };
  },
  _resetCreateAccountFields(state) {
    return {
      ...state,
      email: '',
      password: '',
      confirmPassword: '',
    };
  },
};

const createAccountReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default createAccountReducer;
