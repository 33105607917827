// eslint-disable-next-line no-unused-vars
import jwt from 'jsonwebtoken';
import {
  CLEAR_LOGIN_DATA,
  FETCH_LOGIN_FAIL,
  RECEIVE_USER_DATA,
  TOGGLE_LOGIN_LOADING,
  SET_IS_USER_AUTHENTICATED,
  SET_LOGIN_FAIL_MESSAGE,
  RECEIVE_ME_DATA,
  TOGGLE_ME_DATA_LOADING,
  FETCH_ME_DATA_FAIL,
  SET_PARTIAL_USER_DATA,
  SET_TERMS_OF_USE_DIALOG_STATUS,
  SET_CANCEL_TERMS_OF_USE_ACCEPTANCE_STATUS,
  SET_PASSWORD_EXPIRATION,
  CONTINUE_LOGIN,
} from '../constants';

const initialState = {
  userData: {},
  error: false,
  errorMessage: '',
  isLoading: false,
  hasInitCheckedForAuthentication: false,
  isAuthenticated: false,
  userEmail: '',
  customerId: null,
  meData: null,
  meDataLoading: false,
  meDataError: null,
  termsOfServiceUrl: '',
  termsOfUseDialogActive: false,
  termsOfUseAcceptanceCancelled: false,
  passwordExpirationDays: null,
  continueLogin: false,
};

const actionMappings = {
  [RECEIVE_USER_DATA]: '_receiveLoginData',
  [CLEAR_LOGIN_DATA]: '_clearLoginData',
  [TOGGLE_LOGIN_LOADING]: '_toggleLoginData',
  [FETCH_LOGIN_FAIL]: '_setFailureState',
  [SET_IS_USER_AUTHENTICATED]: '_setIsUserAuthenticated',
  [SET_LOGIN_FAIL_MESSAGE]: '_setLoginFailMessage',
  [RECEIVE_ME_DATA]: '_receiveMeData',
  [TOGGLE_ME_DATA_LOADING]: '_toggleMeDataLoading',
  [FETCH_ME_DATA_FAIL]: '_fetchMeDataFail',
  [SET_PARTIAL_USER_DATA]: '_setPartialUserData',
  [SET_TERMS_OF_USE_DIALOG_STATUS]: '_setTermsOfUseDialogStatus',
  [SET_CANCEL_TERMS_OF_USE_ACCEPTANCE_STATUS]: '_setCancelTermsOfUseAcceptanceStatus',
  [SET_PASSWORD_EXPIRATION]: '_setPasswordExpiration',
  [CONTINUE_LOGIN]: '_continueLogin',
};

function parseGroupsToCustomerID(groups) {
  let customerId = -1;
  const regex = /CUSTOMER_(\d+)/gm;
  const regexAdmin = /CUSTOMER_ADMIN_(\d+)/gm;
  groups.forEach(customer => {
    const customerRegex = regex.exec(customer);
    const customerAdminRegex = regexAdmin.exec(customer);
    if (customerRegex?.length >= 2) {
      customerId = parseInt(customerRegex[1], 10);
    }
    if (customerAdminRegex?.length >= 2) {
      customerId = parseInt(customerAdminRegex[1], 10);
    }
  });
  return customerId;
}

const reducer = {
  _receiveLoginData(state, { payload: { userData } }) {
    const token = jwt.decode(userData.idToken);
    const customerId = parseGroupsToCustomerID(token['cognito:groups']);
    window.localStorage.setItem('customerId', customerId);
    return {
      ...state,
      userData,
      customerId,
      isAuthenticated: true,
    };
  },

  _clearLoginData(state) {
    return {
      ...state,
      userData: {},
      isAuthenticated: false,
    };
  },

  _toggleLoginData(state) {
    return {
      ...state,
      isLoading: !state.isLoading,
    };
  },

  _setFailureState(state) {
    return {
      ...state,
      error: true,
      isAuthenticated: false,
    };
  },
  _setIsUserAuthenticated(state, { isLoggedIn, userEmail }) {
    return {
      ...state,
      isAuthenticated: isLoggedIn,
      userEmail,
      hasInitCheckedForAuthentication: true,
    };
  },
  _setLoginFailMessage(state, { errorMessage }) {
    return {
      ...state,
      errorMessage,
    };
  },
  _receiveMeData(state, { data }) {
    return {
      ...state,
      meData: data,
      meDataLoading: false,
    };
  },
  _toggleMeDataLoading(state) {
    return {
      ...state,
      meDataLoading: !state.meDataLoading,
    };
  },
  _fetchMeDataFail(state) {
    return {
      ...state,
      meDataError: true,
      meDataLoading: false,
    };
  },
  _setPartialUserData(state, { data }) {
    return {
      ...state,
      userData: {
        email: data?.email ?? '',
        username: data?.email ?? '',
      },
      termsOfServiceUrl: data?.termsOfServiceUrl ?? '',
      errorMessage: data?.errorMessage ?? '',
    };
  },
  _setTermsOfUseDialogStatus(state, { dialogStatus }) {
    return {
      ...state,
      termsOfUseDialogActive: dialogStatus,
    };
  },
  _setCancelTermsOfUseAcceptanceStatus(state, { status }) {
    return {
      ...state,
      termsOfUseAcceptanceCancelled: status,
    };
  },
  _setPasswordExpiration(state, { passwordExpirationDays }) {
    return {
      ...state,
      passwordExpirationDays,
    };
  },
  _continueLogin(state, { continueLogin }) {
    return {
      ...state,
      continueLogin,
    };
  },
};

const loginReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default loginReducer;
