import { MOREY_SCRIPT_UPLOAD_ERROR, MOREY_SCRIPT_UPLOAD_SUCCESS } from '../constants';

const initialState = {
  scriptUploadResponse: null,
  scriptUploadError: false,
  errorMessage: null,
};

const actionMappings = {
  [MOREY_SCRIPT_UPLOAD_ERROR]: '_moreyScriptUploadError',
  [MOREY_SCRIPT_UPLOAD_SUCCESS]: '_moreyScriptUploadSuccess',
};

const reducer = {
  _moreyScriptUploadError(state, { payload: { errorMessage } }) {
    return {
      ...state,
      scriptUploadError: true,
      errorMessage: errorMessage,
    };
  },
  _moreyScriptUploadSuccess(state, { payload: { scriptUploadResponse } }) {
    return {
      ...state,
      scriptUploadResponse: scriptUploadResponse,
      scriptUploadError: false,
    };
  },
};

const moreyOtaScriptUpload = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default moreyOtaScriptUpload;
