export const encloseEachInDoubleQuotesAndCommaDelimit = arr => arr.map(e => `"${e}"`).join(',');

export const encloseInSquareBrackets = text => `[${text}]`;

export const graphqlFormatArray = arr => encloseInSquareBrackets(encloseEachInDoubleQuotesAndCommaDelimit(arr));

export const arrayToStringFormating = arr => {
  if (!Array.isArray(arr) || !arr?.length) {
    return '';
  }
  return arr?.length > 1 ? arr?.join(', ') : arr?.join();
};
