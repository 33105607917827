/* @flow */
import buildUrl from 'build-url';
import { formatUserGeolocationHeader } from '../utils/getCurrentUserLocationUtil';

type Fetch = (url: string, options: ?any) => Promise<any>;

type Options = {
  baseUrl: string,
  cookie?: string,
  getToken?: () => string,
};

/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */
function createFetch(fetch: Fetch, { baseUrl, cookie, getToken }: Options) {
  const targetRoot = baseUrl;
  const whitelistedAuthorizationHeaderBaseUrls = ['', '/'];
  if (typeof window !== 'undefined' && window.App && window.App.fleetpulseApiUrl) {
    whitelistedAuthorizationHeaderBaseUrls.push(window.App.fleetpulseApiUrl);
  }
  const defaults = {
    method: 'POST', // handy with GraphQL backends
    mode: baseUrl ? 'cors' : 'same-origin',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Origin': baseUrl,
    },
  };

  return (url: string, options: any) => {
    let targetUrl = buildUrl(targetRoot, {
      path: url ? url : '',
    });
    targetUrl = targetUrl.replace('/undefined/', '/');
    return fetch(targetUrl, {
      ...defaults,
      ...options,
      headers: {
        ...defaults.headers,
        ...(options && options.headers),
        // Always exclude the Authorization header for untrusted URLs
        Authorization: getToken(),
        ...formatUserGeolocationHeader(),
        Cookie: document.cookie,
      },
    });
  };
}

export default createFetch;
