import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';
import { WHEEL_END_STATUS, TIRE_PRESSURE_STATUS_A, TIRE_PRESSURE_STATUS_B } from '../constants/enums/hendricksonStatus';
import sensorStatusTypes from '../constants/enums/sensorStatusTypes';

export const getHendricksonSensorStatus = (sensorEnum, status) => {
  return _find(sensorEnum, item => item.status === status) ?? sensorStatusTypes.NOT_AVAILABLE.display;
};

export const mapWheelEndSensorStatus = item => {
  const wheelEndSensorStatus = getHendricksonSensorStatus(WHEEL_END_STATUS, item?.wheelEndTemperatureThresholdDetection);
  const isWheelEndStatusCritical = wheelEndSensorStatus?.sensorStatus?.display === sensorStatusTypes.CRITICAL.display;
  if (isWheelEndStatusCritical) {
    return `Critical - ${wheelEndSensorStatus?.display}` ?? sensorStatusTypes.NOT_AVAILABLE.display;
  }
  return wheelEndSensorStatus?.sensorStatus?.display ?? sensorStatusTypes.NOT_AVAILABLE.display;
};

export const mapTirePressureSensorStatus = item => {
  const tirePressureSensorStatusA = getHendricksonSensorStatus(TIRE_PRESSURE_STATUS_A, item?.portATireLeakThresholdDetectionStatus);
  const tirePressureSensorStatusB = getHendricksonSensorStatus(TIRE_PRESSURE_STATUS_B, item?.portBTirePressureThresholdDetectionStatus);
  const isTirePressureStatusGood =
    tirePressureSensorStatusA?.sensorStatus?.display === sensorStatusTypes.GOOD.display ||
    tirePressureSensorStatusB?.sensorStatus?.display === sensorStatusTypes.GOOD.display;
  if (isTirePressureStatusGood) {
    return sensorStatusTypes.GOOD.display;
  }
  if (tirePressureSensorStatusA === sensorStatusTypes.NOT_AVAILABLE.display || tirePressureSensorStatusB === sensorStatusTypes.NOT_AVAILABLE.display)
    return sensorStatusTypes.NOT_AVAILABLE.display;
  return `${tirePressureSensorStatusA?.display} - ${tirePressureSensorStatusB?.display}`;
};

export const convertArrayToObject = array => _keyBy(array, 'wheelLocation');

export const getIndividualTirePressureText = value => {
  if (!value) {
    return sensorStatusTypes.NOT_AVAILABLE.display;
  }

  return `${Number(value)?.toFixed(1)}psi`;
};

export const getTemperature = wheelEnd => {
  return wheelEnd.wheelEndTemperatureFahrenheit ? wheelEnd.wheelEndTemperatureFahrenheit : wheelEnd.wheelEndTemperature;
};

export const getWheelEndText = value => {
  if (!value) {
    return sensorStatusTypes.NOT_AVAILABLE.display;
  }
  return `${value}°F`;
};
