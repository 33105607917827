import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/airbnb.css';

export default forwardRef((props, ref) => {
  const [date, setDate] = useState(null);
  const [picker, setPicker] = useState(null);
  const refFlatPickr = useRef(null);
  const refInput = useRef(null);

  const onDateChanged = useCallback(
    selectedDates => {
      setDate(selectedDates[0]);
      props.onDateChanged();
    },
    [setDate, props],
  );

  useEffect(() => {
    setPicker(
      flatpickr(refFlatPickr.current, {
        onChange: onDateChanged,
        dateFormat: 'm/d/Y',
        wrap: true,
        maxDate: new Date(),
        allowInput: true,
      }),
    );
  }, [onDateChanged]);

  useEffect(() => {
    if (picker) {
      return picker?.calendarContainer?.classList?.add('ag-custom-component-popup');
    }
  }, [picker]);

  useEffect(() => {
    if (picker) {
      picker.setDate(date);
    }
  }, [date, picker]);

  useImperativeHandle(ref, () => ({
    getDate() {
      return date;
    },

    setDate(date) {
      setDate(date);
    },

    setInputPlaceholder(placeholder) {
      if (refInput.current) {
        refInput.current.setAttribute('placeholder', placeholder);
      }
    },

    setInputAriaLabel(label) {
      if (refInput.current) {
        refInput.current.setAttribute('aria-label', label);
      }
    },
  }));

  return (
    <div className="ag-input-wrapper custom-date-filter" role="presentation" ref={refFlatPickr}>
      <input type="text" ref={refInput} data-input style={{ width: '100%' }} />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="input-button" title="clear" data-clear>
        <i className="fa fa-times"></i>
      </a>
    </div>
  );
});
