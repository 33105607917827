import { filterAbsLifecyclesBySelect } from '../utils/absTroubleshootingUtils';
import {
  CHANGE_ABS_FAULT_TYPE_FILTER,
  FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_BEGIN,
  FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_FAIL,
  FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_SUCCESS,
  SET_ABS_FAULT_DATE_RANGE_FILTER,
  SET_ABS_FAULT_STATUS_FILTER,
} from '../constants';
import absFaultDateRangeFilterOptions from '../constants/enums/absFaultDateRangeFilterOptions';
import { ABS_FAULT_FILTERS } from '../constants/enums/absFaults';
import absTroubleshootingLifecycleAttributes from '../constants/enums/absTroubleshootingLifecycleAttributes';

const initialState = {
  absTroubleshootingData: {},
  absDataLoading: false,
  error: false,
  activeAbsFaultTypeFilter: ABS_FAULT_FILTERS.ALL,
  absTroubleshootingLifecycles: [],
  filteredAbsTroubleshootingLifecycles: [],
  activeAbsFaultDateRangeFilter: absFaultDateRangeFilterOptions.ALL,
  absFaultStatusFilterActive: true,
};

const actionMappings = {
  [CHANGE_ABS_FAULT_TYPE_FILTER]: '_changeAbsFaultTypeFilter',
  [FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_BEGIN]: '_fetchAbsTroubleshootingLifecyclesBegin',
  [FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_FAIL]: '_fetchAbsTroubleshootingLifecyclesFail',
  [FETCH_ABS_TROUBLESHOOTING_LIFECYCLES_SUCCESS]: '_fetchAbsTroubleshootingLifecyclesSuccess',
  [SET_ABS_FAULT_DATE_RANGE_FILTER]: '_setAbsFaultDateRangeFilter',
  [SET_ABS_FAULT_STATUS_FILTER]: '_setAbsFaultStatusFilter',
};

const reducer = {
  _changeAbsFaultTypeFilter(state, { activeFilter }) {
    const filteredAbsTroubleshootingLifecycles = state.absTroubleshootingLifecycles?.filter(fault =>
      filterAbsLifecyclesBySelect({
        fault,
        selectedDateRangeFilter: state.activeAbsFaultDateRangeFilter,
        selectedFaultLevelFilter: activeFilter,
        showAllFaultsFilter: state.absFaultStatusFilterActive,
      }),
    );
    return {
      ...state,
      activeAbsFaultTypeFilter: activeFilter,
      filteredAbsTroubleshootingLifecycles,
    };
  },
  _fetchAbsTroubleshootingLifecyclesBegin(state) {
    return {
      ...state,
      absDataLoading: true,
      error: false,
    };
  },
  _fetchAbsTroubleshootingLifecyclesFail(state) {
    return {
      ...state,
      absDataLoading: false,
      error: true,
    };
  },
  _fetchAbsTroubleshootingLifecyclesSuccess(state, { absTroubleshootingLifecycles }) {
    const allTroubleshootingFaultLifecycles =
      absTroubleshootingLifecycles?.map(fault => ({
        ...fault,
        status: fault?.faultEnd
          ? absTroubleshootingLifecycleAttributes.STATUS.fieldOptions.CLEARED.name
          : absTroubleshootingLifecycleAttributes.STATUS.fieldOptions.ACTIVE.name,
      })) ?? [];
    return {
      ...state,
      absTroubleshootingLifecycles: allTroubleshootingFaultLifecycles,
      filteredAbsTroubleshootingLifecycles: allTroubleshootingFaultLifecycles,
      absDataLoading: false,
      error: false,
    };
  },
  _setAbsFaultDateRangeFilter(state, { selectedFilterOption }) {
    const filteredAbsTroubleshootingLifecycles = state.absTroubleshootingLifecycles?.filter(fault =>
      filterAbsLifecyclesBySelect({
        fault,
        selectedDateRangeFilter: selectedFilterOption,
        selectedFaultLevelFilter: state.activeAbsFaultTypeFilter,
        showAllFaultsFilter: state.absFaultStatusFilterActive,
      }),
    );

    return {
      ...state,
      activeAbsFaultDateRangeFilter: selectedFilterOption,
      filteredAbsTroubleshootingLifecycles,
    };
  },
  _setAbsFaultStatusFilter(state, { value }) {
    const filteredAbsTroubleshootingLifecycles = state.absTroubleshootingLifecycles?.filter(fault =>
      filterAbsLifecyclesBySelect({
        fault,
        selectedDateRangeFilter: state.activeAbsFaultDateRangeFilter,
        selectedFaultLevelFilter: state.activeAbsFaultTypeFilter,
        showAllFaultsFilter: value,
      }),
    );
    return {
      ...state,
      absFaultStatusFilterActive: value,
      filteredAbsTroubleshootingLifecycles,
    };
  },
};

const absDataReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default absDataReducer;
