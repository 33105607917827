import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomersData } from '../../../actions/customers';
import CustomerSelect from '../../DashboardFiltersExpanded/CustomerSelect';
import Input from '../../Input/Input';
import searchBarIcon from '../../../assets/public/search-bar.png';

function GroupFilter({ onCustomerChange, searchText, setSearchText, showSearchInput, userManagementActive }) {
  const dispatch = useDispatch();
  const { customersData, customersLoading } = useSelector(state => state.customers);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const changeCustomer = useCallback(
    customer => {
      setSelectedCustomer(customer);
      onCustomerChange(customer);
    },
    [onCustomerChange],
  );

  useEffect(() => {
    if (customersData?.length === 1) {
      return changeCustomer(customersData[0]);
    }
  }, [customersData, changeCustomer]);

  useEffect(() => {
    fetchCustomersData()(dispatch);
  }, [dispatch]);

  return (
    <div className="group-filter" style={{ display: 'flex', zIndex: 500 }}>
      {customersData?.length > 1 && (
        <div style={{ width: '300px' }}>
          <CustomerSelect clearable customer={selectedCustomer} setCustomer={changeCustomer} userManagementActive={userManagementActive} />
        </div>
      )}

      {showSearchInput && !customersLoading && (
        <Input leftIcon={searchBarIcon} value={searchText} onChange={e => setSearchText(e.target.value)} placeholder="Search Group Name" />
      )}
    </div>
  );
}

export default GroupFilter;
